import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import BookingView from '@/components/frontend/BookingView.vue'
import { checkAuth } from '@/utils/route-middelware'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/bbq-donut-boat-booking',
    },
    {
        path: '/bbq-donut-boat-booking',
        name: 'booking-overview',
        component: BookingView,
    },
    {
        path: '/bbq-donut-boat-booking/order-confirmation',
        name: 'order-confirmation',
        component: () => import('@/components/frontend/booking-process/OrderConfirmationDetails.vue'),
    },
    {
        path: '/bbq-donut-boat-booking/shopping-cart',
        name: 'shopping-card',
        component: () => import('@/components/frontend/booking-process/ShoppingCart.vue'),
    },
    {
        path: '/login',
        name: 'admin-login-overview',
        component: () => import('../views/auth/LoginOverview.vue'),
    },
    {
        path: '/admin/overview',
        name: 'admin-overview',
        component: () => import('../views/backend/AdminOverview.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/admin/orders',
        name: 'admin-orders',
        component: () => import('../views/backend/AdminOrderOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin', 'employee'],
        },
    },
    {
        path: '/admin/scheduler',
        name: 'admin-schedule',
        component: () => import('../views/backend/AdminSchedulerOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin', 'employee'],
        },
    },
    {
        path: '/admin/development',
        name: 'admin-development',
        component: () => import('../views/backend/AdminDevelopmentOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
    },
    {
        path: '/admin/settings',
        name: 'admin-settings',
        component: () => import('../views/backend/AdminSettingsOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
    },
    {
        path: '/admin/arrangements',
        name: 'admin-arrangements',
        component: () => import('../views/backend/AdminArrangementsOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
    },
    {
        path: '/admin/drinks',
        name: 'admin-drinks',
        component: () => import('../views/backend/AdminDrinksOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
    },
    {
        path: '/admin/payments',
        name: 'admin-payments',
        component: () => import('../views/backend/AdminPaymentOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
    },
    {
        path: '/admin/dishes',
        name: 'admin-dishes',
        component: () => import('../views/backend/AdminDishesOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
    },
    {
        path: '/admin/boats',
        name: 'admin-boats',
        component: () => import('../views/backend/AdminBoatOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
    },
    {
        path: '/admin/coupons',
        name: 'admin-coupons',
        component: () => import('../views/backend/AdminCouponOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
    },
    {
        path: '/admin/timeslots',
        name: 'admin-timeslots',
        component: () => import('../views/backend/AdminTimeslotOverview.vue'),
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        children: [
            {
                path: '',
                name: 'redirect',
                redirect: { name: 'GeneralTimeSlots' },
            },
            {
                path: 'general',
                name: 'GeneralTimeSlots',
                component: () => import('../components/backend/PartialViews/TimeSlots.vue'),
            },
            {
                path: 'custom',
                name: 'CustomTimeSlots',
                component: () => import('../components/backend/PartialViews/CustomTimeSlots.vue'),
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        const isAuthenticated = await checkAuth(to, from, next)

        if (isAuthenticated) {
            const user = store.state.user
            if (user) {
                const userRole = user.role || ''
                const requiredRoles = to.meta.roles || []

                if (requiredRoles.length === 0 || requiredRoles.includes(userRole)) {
                    next()
                } else {
                    next('/unauthorized')
                }
            } else {
                next('/login')
            }
        } else {
            next('/login')
        }
    } else {
        next()
    }
})

export default router
