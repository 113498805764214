<template>
    <div class="object-selector">
        <div class="object-selector-header">
            <div class="col"></div>
            <div class="col">
                <div v-if="selectedDrinks.length != amountOfDrinks && amountOfDrinks - selectedDrinks.length > 0">
                    <span class="candal-regular text">
                        {{
                            $t(
                                this.amountOfDrinks - this.selectedDrinks.length != 1
                                    ? 'DRINK_SELECTOR_AMOUNT_OF_DRINKS_LEFT'
                                    : 'DRINK_SELECTOR_AMOUNT_OF_DRINK_LEFT',
                                { amount: this.amountOfDrinks - this.selectedDrinks.length },
                            )
                        }}
                    </span>
                </div>
                <div v-else-if="selectedDrinks.length != amountOfDrinks && amountOfDrinks - selectedDrinks.length < 0">
                    <span class="candal-regular"> {{ $t('PAYMENT_DETAILS_TO_MUCH_DRINKS') }} </span>
                </div>
                <div v-else>
                    <span class="candal-regular"> {{ $t('DRINK_SELECTOR_NO_MORE_DRINKS') }} </span>
                </div>
            </div>
            <div class="col">
                <div class="shopping-cart-wrapper" @click="toggleCart">
                    <span class="material-symbols-outlined pointer"> shopping_cart </span>
                    <div id="drink-indicator" class="cart-indicator">
                        <span class="sub-text"> {{ this.selectedDrinks.length }} </span>
                    </div>
                    <Transition enter-active-class="fade-in" leave-active-class="fade-out" name="custom-classes">
                        <div v-if="openDrinkCart" class="shopping-cart-content" @click="handleClickOutside">
                            <div class="shopping-cart-header">
                                <span class="candal-regular text"> {{ $t('SHOPPING_CART_DRINKS') }}</span>
                            </div>
                            <div class="shopping-cart-body">
                                <div class="cart-category-separator">
                                    <span class="text f-bold"> {{ $t('SHOPPING_CART_DRINKS') }} </span>
                                </div>
                                <div v-if="selectedDrinks.length === 0">
                                    <span class="text"> {{ $t('SHOPPING_CART_NO_DRINKS_SELECTED') }} </span>
                                </div>
                                <div v-else :style="{ width: '100%' }">
                                    <table :style="{ width: '100%' }">
                                        <tr
                                            v-for="(obj, index) in currentDrinkOverview()"
                                            :key="index"
                                            class="shopping-cart-item"
                                        >
                                            <td :style="{ width: '60%' }">{{ obj.drink }}</td>
                                            <td :style="{ width: '15%' }">{{ `${obj.amount}x` }}</td>
                                            <td :style="{ width: '25%', textAlign: 'right' }">
                                                {{ formattedPrice(0) }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="shopping-cart-footer">
                                <span class="candal-regular text">
                                    {{ `${$t('SHOPPING_CART_TOTAL')} ${formattedPrice(totalCosts)}` }}</span
                                >
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
        <div class="object-list-wrapper">
            <div v-if="loading" class="spinner-wrapper">
                <loading-spinner />
            </div>
            <div v-else class="object-list fade-in">
                <div v-for="drink in drinkList" :key="drink.id" class="object-card grow shadow">
                    <div v-if="drink.is_popular" class="ribbon ribbon-top-left">
                        <span> {{ $t('POPULAR') }} </span>
                    </div>
                    <div class="object-image-wrapper">
                        <div class="object-image">
                            <img :src="drink.media_library.original_url" alt="Image" />
                        </div>
                    </div>
                    <div class="object-header">
                        <span style="text-align: center"> {{ `${drink.name}` }}</span>
                        <span style="color: #f2682c; font-weight: bold">
                            {{ `${drink.quantity} ${drink.unit}` }}
                        </span>
                        <span> {{ `${drink.alcohol_percentage ? drink.alcohol_percentage + '%' : ''}` }}</span>
                    </div>
                    <div class="object-footer">
                        <div class="object-buttons">
                            <div
                                class="count-button"
                                @click="
                                    (event) => {
                                        removeDrink(drink)
                                        animateButton(event)
                                    }
                                "
                            >
                                <span class="material-symbols-outlined symbol-size pointer"> remove </span>
                            </div>
                            <div class="object-counter">
                                <span class="text"> {{ countDrink(drink.id) }}</span>
                            </div>
                            <div
                                class="count-button"
                                @click="
                                    (event) => {
                                        addDrink(drink)
                                        animateButton(event)
                                    }
                                "
                            >
                                <span class="material-symbols-outlined symbol-size pointer"> add </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="object-footer-wrapper">
                    <div class="object-selector-footer">
                        <div id="prev_drink_button" class="nav-button-control pointer text" @click="prevStep">
                            <span class="candal-regular"> {{ $t('BUTTON_BACK') }}</span>
                        </div>
                        <div id="next_drink_button" class="nav-button-control pointer text" @click="nextStep">
                            <span class="candal-regular">
                                {{ $t('BUTTON_NEXT') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
//* Components */
import { defineComponent } from 'vue'
//* Packages */
import http from '../../../utils/http-common'
//* Models */
import IDrink from '../../../interfaces/IDrink'
import { toast } from 'vue3-toastify'
import LoadingSpinner from '@/components/partials/custom-fields/LoadingSpinner.vue'

export default defineComponent({
    name: 'DrinkSelector',
    components: { LoadingSpinner },
    data() {
        return {
            loading: true as boolean,
            drinks: null as unknown as number,
            drinkList: [] as IDrink[],
            selectedDrinks: [] as IDrink[],
            openDrinkCart: false as boolean,
            totalCosts: 0 as number,
        }
    },
    methods: {
        getDrinks() {
            http.get(`non_extra_drinks`, {
                params: {
                    is_active: true,
                },
            })
                .then((res) => {
                    this.drinkList = res.data
                    this.loading = false
                })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                })
        },
        addDrink(drink: IDrink) {
            if (this.selectedDrinks.length < this.drinks) {
                this.selectedDrinks.push(drink)
                this.$emit('onSelectedDrinkChange', this.selectedDrinks)
                this.animateIndicator()
            } else {
                toast(this.$t('DRINK_SELECTOR_NO_MORE_DRINKS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000,
                })
            }
        },
        removeDrink(drink: IDrink) {
            const indexToRemove = this.selectedDrinks.findIndex((item) => item.id === drink.id)

            if (indexToRemove !== -1) {
                this.selectedDrinks.splice(indexToRemove, 1)
                this.$emit('onSelectedDrinkChange', this.selectedDrinks)
                this.animateIndicator()
            }
        },
        animateButton(event: Event): void {
            const button = event.currentTarget as HTMLElement
            button.classList.remove('grow-shrink')
            void button.offsetWidth
            button.classList.add('grow-shrink')
        },
        currentDrinkOverview(): { drink: string; amount: number; price: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedDrinks.forEach((drink: { id: number }) => {
                const drinkId = drink.id

                if (ids[drinkId]) {
                    ids[drinkId]++
                } else {
                    ids[drinkId] = 1
                }
            })

            const result = Object.keys(ids).map((drinkId) => {
                const count = ids[drinkId]
                const drink = this.selectedDrinks.find((arr) => arr.id === parseInt(drinkId))
                const drinkName = drink?.name || 'Unknown'
                const drinkPrice = drink?.price || 0
                return { drink: drinkName, amount: count, price: drinkPrice }
            })

            return result
        },
        animateIndicator(): void {
            const indicator = document.querySelector('#drink-indicator') as HTMLElement

            if (indicator) {
                indicator.classList.remove('grow-shrink')
                void indicator.offsetWidth
                indicator.classList.add('grow-shrink')
            }
        },
        countDrink(id: number): number {
            return this.selectedDrinks.filter((drink) => drink.id === id).length
        },
        nextStep() {
            if (!this.amountOfDrinks) {
                return
            }
            if (
                this.selectedDrinks.length != this.amountOfDrinks &&
                this.amountOfDrinks - this.selectedDrinks.length > 0
            ) {
                const difference = this.amountOfDrinks - this.selectedDrinks.length
                toast(
                    this.$t(
                        difference !== 1
                            ? 'ERROR_ARRANGEMENT_SELECTOR_ALL_DRINKS'
                            : 'ERROR_ARRANGEMENT_SELECTOR_ALL_DRINK',
                        { amount: difference },
                    ),
                    {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    },
                )

                return
            }
            if (
                this.selectedDrinks.length != this.amountOfDrinks &&
                this.amountOfDrinks - this.selectedDrinks.length < 0
            ) {
                toast(this.$t('PAYMENT_DETAILS_TO_MUCH_DRINKS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000,
                })

                return
            }
            this.openDrinkCart = false
            this.$emit('setDrinks', this.selectedDrinks)
        },
        handleClickOutside(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.openDrinkCart = false
            }
        },
        prevStep(): void {
            this.openDrinkCart = false
            this.$emit('prevStep')
        },
        toggleCart(): void {
            this.openDrinkCart = !this.openDrinkCart
        },
        formattedPrice(price: number): string {
            let symbols = ['€', '$']

            const item = localStorage.getItem('currency_symbol')
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price.toFixed(2)}`
                } else {
                    return `${price.toFixed(2)} ${item}`
                }
            }
            return `${price}`
        },
    },
    props: {
        amountOfDrinks: Number,
    },
    watch: {
        amountOfDrinks: {
            handler(drinks) {
                if (drinks) {
                    this.drinks = drinks
                    this.getDrinks()
                }
            },
        },
    },
})
</script>

<style scoped>
.object-card {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    background-color: white;
    min-width: 200px;
    max-width: 200px;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
</style>
