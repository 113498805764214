<template>
    <div v-if="loaded" class="language-picker">
        <div
            :class="showOptions ? 'active' : ''"
            :style="{
                borderColor: showOptions ? 'var(--primary-admin-highlight)' : '',
                borderWidth: showOptions ? '2px' : '',
            }"
            class="language-dropdown"
            @click="toggleDropdown"
        >
            <div class="selected-option f-14">
                <div class="flag-container">
                    <img
                        :alt="selectedOption.language"
                        :src="require(`@/assets/flags/flag_${selectedOption.code}.png`)"
                    />
                </div>
            </div>
            <div class="center admin-title">
                <span
                    :class="{ rotate: showOptions, 'reverse-rotate': !showOptions }"
                    class="material-symbols-outlined symbol-size"
                >
                    expand_more</span
                >
            </div>
            <opacity-fade-in-component>
                <div v-if="showOptions" class="options shadow">
                    <div v-for="(option, index) in options" :key="index" class="option" @click="selectOption(option)">
                        <div class="option-wrapper">
                            <div class="flag-container">
                                <img :alt="option.language" :src="require(`@/assets/flags/flag_${option.code}.png`)" />
                            </div>
                            <span> {{ $t(option.language) }} </span>
                        </div>
                    </div>
                </div>
            </opacity-fade-in-component>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OpacityFadeInComponent from '@/components/Transitions/OpacityFadeInComponent.vue'

interface ILanguageOption {
    language: string
    code: string
}

export default defineComponent({
    name: 'LanguageDropdown',
    components: { OpacityFadeInComponent },
    data() {
        return {
            loaded: false as boolean,
            selectedOption: {} as ILanguageOption,
            showOptions: false as boolean,
            options: [
                {
                    language: 'LANGUAGE_ENGLISH',
                    code: 'en',
                },

                {
                    language: 'LANGUAGE_DUTCH',
                    code: 'nl',
                },
            ] as ILanguageOption[],
        }
    },
    methods: {
        toggleDropdown() {
            this.showOptions = !this.showOptions
        },
        selectOption(option: ILanguageOption) {
            this.selectedOption = option
            this.changeLanguage(option.code)
        },
        handleClickOutside(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.showOptions = false
            }
        },
        changeLanguage(code: string) {
            const i18n = this.$i18n
            i18n.locale = code
            localStorage.setItem('locale', code)
        },
    },
    mounted(): void {
        const lang = localStorage.getItem('locale')
        if (lang && lang !== this.$i18n.locale) {
            this.changeLanguage(lang)
        }
        const currentLocale = this.$i18n.locale
        const matchedOption = this.options.find((option) => option.code === currentLocale)
        if (matchedOption) {
            this.selectedOption = matchedOption
        } else {
            this.selectedOption = this.options[0]
        }
        document.addEventListener('click', this.handleClickOutside)

        this.loaded = true
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    },
})
</script>

<style scoped>
.flag-container {
    width: 25px;
    height: 25px;
    overflow: hidden;
}

.flag-container img {
    width: 100%;
    height: auto;
    display: block;
}

.language-dropdown:hover {
    border: 1px solid var(--primary-admin-highlight);
}

.language-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 4px 8px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    user-select: none;
    min-width: 100px;
}

.language-picker {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.options {
    width: 200%;
    left: unset;
    right: 0;
}

.option-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
