<template>
    <div class="object-selector" style="position: relative">
        <opacity-fade-in-component>
            <arrangement-card-zoom-modal
                v-show="showDetails"
                :arrangement="selectedArrangement"
                @close="toggleDetails"
            />
        </opacity-fade-in-component>
        <div class="object-selector-header">
            <div class="col"></div>
            <div class="col">
                <div v-if="selectedArrangements.length != size && size - selectedArrangements.length > 0">
                    <span class="candal-regular">
                        {{
                            $t(
                                this.size - this.selectedArrangements.length != 1
                                    ? 'ARRANGEMENT_SELECTOR_AMOUNT_OF_ARRANGEMENTS_LEFT'
                                    : 'ARRANGEMENT_SELECTOR_AMOUNT_OF_ARRANGEMENT_LEFT',
                                { amount: this.size - this.selectedArrangements.length },
                            )
                        }}
                    </span>
                </div>
                <div v-else-if="selectedArrangements.length != size && size - selectedArrangements.length < 0">
                    <span class="candal-regular"> {{ $t('PAYMENT_DETAILS_TO_MUCH_ARRANGEMENTS') }} </span>
                </div>
                <div v-else>
                    <span class="candal-regular"> {{ $t('ARRANGEMENT_SELECTOR_NO_MORE_ARRANGEMENT') }} </span>
                </div>
            </div>
            <div class="col">
                <div class="shopping-cart-wrapper" @click="toggleCart">
                    <span class="material-symbols-outlined pointer"> shopping_cart </span>
                    <div id="arrangement-indicator" class="cart-indicator">
                        <span class="sub-text"> {{ this.selectedArrangements.length }} </span>
                    </div>
                    <Transition enter-active-class="fade-in" leave-active-class="fade-out">
                        <div v-if="openArrangementCart" class="shopping-cart-content">
                            <div class="shopping-cart-header">
                                <span class="candal-regular text"> {{ $t('SHOPPING_CART_ARRANGEMENTS') }}</span>
                            </div>
                            <div class="shopping-cart-body">
                                <div class="cart-category-separator">
                                    <span class="text f-bold"> {{ $t('SHOPPING_CART_ARRANGEMENTS') }} </span>
                                </div>
                                <div v-if="selectedArrangements.length === 0">
                                    <span class="text"> {{ $t('SHOPPING_CART_NO_ARRANGEMENTS_SELECTED') }} </span>
                                </div>
                                <div v-else :style="{ width: '100%' }">
                                    <table :style="{ width: '100%' }">
                                        <tr
                                            v-for="(obj, index) in currentArrangementOverview()"
                                            :key="index"
                                            class="shopping-cart-item"
                                        >
                                            <td :style="{ width: '60%' }">{{ obj.arrangement }}</td>
                                            <td :style="{ width: '15%' }">{{ `${obj.amount}x` }}</td>
                                            <td :style="{ width: '25%', textAlign: 'right' }">
                                                {{ `${formattedPrice(obj.price)}` }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="shopping-cart-footer">
                                <span class="candal-regular text">
                                    {{ `${$t('SHOPPING_CART_TOTAL')} ${formattedPrice(totalCosts)}` }}</span
                                >
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
        <div class="object-list-wrapper">
            <div class="object-list fade-in">
                <div
                    v-for="arrangement in arrangementList"
                    :key="arrangement.id"
                    :class="[checkOrderBefore(arrangement) ? 'order-before' : '']"
                    class="object-card grow shadow"
                >
                    <div v-if="arrangement.is_popular" class="ribbon ribbon-top-left">
                        <span> {{ $t('POPULAR') }} </span>
                    </div>
                    <div
                        :class="[checkOrderBefore(arrangement) ? 'card-disabled' : '']"
                        class="object-wrapper"
                        style="display: flex; flex-direction: column; height: 100%; position: relative"
                    >
                        <div
                            class="object-info pointer"
                            style="position: absolute; right: 0"
                            @click="setArrangement(arrangement)"
                        >
                            <span class="material-symbols-outlined">info</span>
                        </div>
                        <div class="object-image-wrapper">
                            <div class="object-image">
                                <img :src="arrangement.media_library.original_url" alt="Image" />
                            </div>
                        </div>

                        <div class="object-header">
                            <span> {{ `${arrangement.title}` }}</span>
                            <span style="color: #f2682c; font-weight: bold">
                                {{ `${formattedPrice(arrangement.price)} p.p.` }}
                            </span>
                        </div>
                        <div class="object-dishes">
                            <div
                                v-for="category in orderedCategories(groupedDishes(arrangement))"
                                :key="category"
                                class="dish-item"
                            >
                                <span class="dish-header candal-regular">
                                    {{ category ? $t(`ARRANGEMENT_SELECTOR_${category.toUpperCase()}`) : '' }}
                                </span>
                                <div
                                    v-for="dish in groupedDishes(arrangement)[category]"
                                    :key="dish.id"
                                    class="dish-name dish-name-size"
                                >
                                    {{ `${dish.name}` }}
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; margin-top: auto">
                            <div class="object-drinks">
                                <span class="object-drink-size bold"
                                    >{{
                                        arrangement.drink_amount !== 1
                                            ? $t('ARRANGEMENT_SELECTOR_DRINKS', { amount: arrangement.drink_amount })
                                            : $t('ARRANGEMENT_SELECTOR_DRINK')
                                    }}
                                </span>
                            </div>
                            <div class="object-footer">
                                <div class="object-buttons">
                                    <div
                                        class="count-button"
                                        @click="
                                            (event) => {
                                                removeArrangement(arrangement)
                                                animateButton(event)
                                            }
                                        "
                                    >
                                        <span class="material-symbols-outlined symbol-size pointer"> remove </span>
                                    </div>
                                    <div class="object-counter">
                                        <span class="text"> {{ countArrangement(arrangement.id) }}</span>
                                    </div>
                                    <div
                                        class="count-button"
                                        @click="
                                            (event) => {
                                                addArrangement(arrangement)
                                                animateButton(event)
                                            }
                                        "
                                    >
                                        <span class="material-symbols-outlined symbol-size pointer"> add </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkOrderBefore(arrangement)" class="overlay-wrapper">
                        <div class="overlay">
                            <span>
                                {{ $t('ARRANGEMENT_SELECTOR_ORDER_BEFORE', { time: arrangement.order_before_time }) }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="object-footer-wrapper">
                    <div class="object-selector-footer">
                        <div id="prev_arrangement_button" class="nav-button-control pointer text" @click="prevStep">
                            <span class="candal-regular"> {{ $t('BUTTON_BACK') }}</span>
                        </div>
                        <div id="next_arrangement_button" class="nav-button-control pointer text" @click="nextStep">
                            <span class="candal-regular">
                                {{ $t('BUTTON_NEXT') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
//* Components */
import { defineComponent } from 'vue'
//* Packages */
import http from '../../../utils/http-common'
//* Models */
import IArrangement from '../../../interfaces/IArrangement'
import IDish from '../../../interfaces/IDish'
import { toast } from 'vue3-toastify'
import dayjs, { Dayjs } from 'dayjs'
import OpacityFadeInComponent from '@/components/Transitions/OpacityFadeInComponent.vue'
import ArrangementCardZoomModal from '@/components/modals/view/ArrangementCardZoomModal.vue'

export default defineComponent({
    name: 'ArrangementSelector',
    components: { ArrangementCardZoomModal, OpacityFadeInComponent },
    data() {
        return {
            size: null as unknown as number,
            arrangementList: [] as IArrangement[],
            selectedArrangements: [] as IArrangement[],
            openArrangementCart: false as boolean,
            totalCosts: 0 as number,
            isDirty: false as boolean,
            selectedDate: null as string | null,
            showDetails: false as boolean,
            selectedArrangement: {} as IArrangement,
        }
    },
    methods: {
        getArrangements() {
            http.get(`arrangements`, { params: { pagination: false } })
                .then((res) => {
                    this.arrangementList = res.data
                })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                })
        },
        setArrangement(arrangement: IArrangement): void {
            this.toggleDetails()

            if (arrangement) {
                this.selectedArrangement = arrangement
            }
        },
        toggleDetails(): void {
            this.showDetails = !this.showDetails
        },
        addArrangement(arrangement: IArrangement) {
            if (this.checkOrderBefore(arrangement)) {
                return
            }
            if (this.selectedArrangements.length < this.size) {
                this.selectedArrangements.push(arrangement)
                this.animateIndicator()
                this.calculateTotalCost(arrangement.price, 'increment')
                if (this.isDirty) {
                    this.$emit('arrangementChange', this.selectedArrangements)
                }
            } else {
                toast(this.$t('ARRANGEMENT_SELECTOR_NO_MORE_ARRANGEMENT'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000,
                })
            }
        },
        removeArrangement(arrangement: IArrangement) {
            const indexToRemove = this.selectedArrangements.findIndex((item) => item.id === arrangement.id)

            if (indexToRemove !== -1) {
                this.selectedArrangements.splice(indexToRemove, 1)
                this.animateIndicator()
                this.calculateTotalCost(arrangement.price, 'subtract')
                if (this.isDirty) {
                    this.$emit('arrangementChange', this.selectedArrangements)
                }
            }
        },
        countArrangement(id: number): number {
            return this.selectedArrangements.filter((arrangement) => arrangement.id === id).length
        },
        animateIndicator(): void {
            const indicator = document.querySelector('#arrangement-indicator') as HTMLElement

            if (indicator) {
                indicator.classList.remove('grow-shrink')
                void indicator.offsetWidth
                indicator.classList.add('grow-shrink')
            }
        },
        nextStep() {
            if (this.selectedArrangements.length != this.size && this.size - this.selectedArrangements.length > 0) {
                const difference = this.size - this.selectedArrangements.length
                toast(
                    this.$t(
                        difference !== 1
                            ? 'ERROR_ARRANGEMENT_SELECTOR_ALL_ARRANGEMENTS'
                            : 'ERROR_ARRANGEMENT_SELECTOR_ALL_ARRANGEMENT',
                        { amount: difference },
                    ),
                    {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    },
                )

                return
            }
            if (this.selectedArrangements.length != this.size && this.size - this.selectedArrangements.length < 0) {
                toast(this.$t('PAYMENT_DETAILS_TO_MUCH_ARRANGEMENTS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000,
                })

                return
            }

            this.openArrangementCart = false
            this.$emit('setArrangements', this.selectedArrangements)
            this.isDirty = true
        },
        currentArrangementOverview(): { arrangement: string; amount: number; price: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedArrangements.forEach((arrangement: { id: number }) => {
                const arrangementId = arrangement.id

                if (ids[arrangementId]) {
                    ids[arrangementId]++
                } else {
                    ids[arrangementId] = 1
                }
            })

            const result = Object.keys(ids).map((arrangementId) => {
                const count = ids[arrangementId]
                const arrangement = this.selectedArrangements.find((arr) => arr.id === parseInt(arrangementId))
                const arrangementName = arrangement?.title || 'Unknown'
                const arrangementPrice = arrangement?.price || 0
                return { arrangement: arrangementName, amount: count, price: arrangementPrice }
            })

            return result
        },
        orderedCategories(groupedDishes: Record<string, IDish[]>): string[] {
            const predefinedOrder = ['Starters', 'Salads', 'Meat', 'Sauces']
            const groupedCategories = Object.keys(groupedDishes)

            return groupedCategories.sort((a, b) => {
                const indexA = predefinedOrder.indexOf(a)
                const indexB = predefinedOrder.indexOf(b)

                return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
            })
        },
        prevStep(): void {
            this.openArrangementCart = false
            this.$emit('prevStep')
        },
        toggleCart(): void {
            this.openArrangementCart = !this.openArrangementCart
        },
        calculateTotalCost(price: number, option: string): void {
            if (option === 'increment') {
                this.totalCosts += Number(price)
            } else {
                this.totalCosts -= Number(price)
            }
        },
        checkOrderBefore(arrangement: IArrangement): boolean {
            if (!this.selectedDate || !arrangement.order_before_time) {
                return false
            }

            const currentDate: Dayjs = dayjs() // Get the current date and time
            const currentDateString: string = currentDate.format('YYYY-MM-DD')
            const reservationDate: Dayjs = dayjs(this.selectedDate) // Convert selected date to dayjs object
            const reservationDateString: string = reservationDate.format('YYYY-MM-DD')

            if (reservationDateString === currentDateString) {
                const orderBeforeTime: Dayjs = this.parseTime(arrangement.order_before_time)
                // Compare times using dayjs
                return currentDate.isAfter(orderBeforeTime)
            }

            return false
        },
        animateButton(event: Event): void {
            const button = event.currentTarget as HTMLElement
            button.classList.remove('grow-shrink')
            void button.offsetWidth
            button.classList.add('grow-shrink')
        },
        parseTime(time: string): Dayjs {
            const [hours, minutes] = time.split(':').map(Number)
            return dayjs().set('hour', hours).set('minute', minutes).set('second', 0).set('millisecond', 0)
        },
        formattedPrice(price: number): string {
            let symbols = ['€', '$']

            const item = localStorage.getItem('currency_symbol')
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price}`
                } else {
                    return `${price} ${item}`
                }
            }
            return `${price}`
        },
    },
    props: {
        guestAmount: Number,
        date: {
            type: String,
        },
    },
    computed: {
        groupedDishes(): (arrangement: IArrangement) => Record<string, IDish[]> {
            return (arrangement) => {
                const grouped: Record<string, IDish[]> = {}
                arrangement.dishes.forEach((dish) => {
                    if (!grouped[dish.category]) {
                        grouped[dish.category] = []
                    }
                    grouped[dish.category].push(dish)
                })
                return grouped
            }
        },
    },
    watch: {
        guestAmount: {
            handler(size) {
                this.size = size
                this.getArrangements()
            },
        },
        date: {
            handler(date: string) {
                if (date) {
                    this.selectedDate = date
                }
            },
        },
    },
    mounted() {
        //do
    },
})
</script>

<style scoped>
.object-card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 20px;
    flex-basis: calc(20%);
    background-color: white;
    min-width: 225px;
    max-width: 250px;
}

.dish-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.dish-header {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    font-size: 14px;
}

.dish-name {
    color: var(--primary-admin-text);
}

.dish-name-size {
    font-size: 12px;
}

.object-drink-size {
    font-size: 14px;
}

.col {
    display: flex;
    height: 100%;
}

.object-info {
    color: var(--primary-admin-text);

    span {
        font-size: 34px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .object-card {
        min-width: unset !important;
        margin: 6px !important;
        flex-basis: calc(43%) !important;
    }

    .object-info {
        right: -8px !important;
        top: -8px !important;

        span {
            font-size: 24px !important;
            right: -10px !important;
        }
    }
}
</style>
