import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "arrangement-card-modal-wrapper" }
const _hoisted_2 = {
  class: "arrangement-card-modal shadow",
  style: {"margin-top":"10px"}
}
const _hoisted_3 = {
  key: 0,
  class: "spinner-wrapper",
  style: {"display":"flex","justify-content":"center","width":"100%"}
}
const _hoisted_4 = {
  key: 1,
  class: "ribbon ribbon-top-left",
  style: {"z-index":"10"}
}
const _hoisted_5 = { class: "object-image-wrapper" }
const _hoisted_6 = { class: "object-image" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "object-header" }
const _hoisted_9 = { style: {"color":"#f2682c","font-weight":"bold"} }
const _hoisted_10 = {
  class: "object-dishes",
  style: {"height":"100%","overflow-y":"auto"}
}
const _hoisted_11 = { class: "dish-header candal-regular" }
const _hoisted_12 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_13 = { class: "object-drinks" }
const _hoisted_14 = { class: "object-drink-size bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_loading_spinner)
          ]))
        : _createCommentVNode("", true),
      (_ctx.selectedArrangement.is_popular)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('POPULAR')), 1)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass([[_ctx.checkOrderBefore(_ctx.selectedArrangement) ? 'order-before' : ''], "object-card"])
          }, [
            _createElementVNode("div", {
              class: "pointer",
              style: {"position":"absolute","right":"-6px","top":"-16px","color":"var(--primary-admin-text)"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "material-symbols-outlined" }, "close", -1)
            ])),
            _createElementVNode("div", {
              class: _normalizeClass([[_ctx.checkOrderBefore(_ctx.selectedArrangement) ? 'card-disabled' : ''], "object-wrapper"]),
              style: {"display":"flex","flex-direction":"column","height":"100%","position":"relative"}
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: _ctx.selectedArrangement.media_library.original_url,
                    alt: "Image"
                  }, null, 8, _hoisted_7)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, _toDisplayString(`${_ctx.selectedArrangement.title}`), 1),
                _createElementVNode("span", _hoisted_9, _toDisplayString(`${_ctx.formattedPrice(_ctx.selectedArrangement.price)} p.p.`), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedCategories(_ctx.groupedDishes(_ctx.selectedArrangement)), (category) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: category,
                    class: "dish-item"
                  }, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(category ? _ctx.$t(`ARRANGEMENT_SELECTOR_${category.toUpperCase()}`) : ''), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedDishes(_ctx.selectedArrangement)[category], (dish) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: dish.id,
                        class: "dish-name dish-name-size"
                      }, [
                        _createElementVNode("span", null, _toDisplayString(`${dish.name}`), 1),
                        _createElementVNode("span", null, _toDisplayString(` (${dish.quantity} ${dish.unit})`), 1)
                      ]))
                    }), 128))
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.selectedArrangement.drink_amount !== 1
                                        ? _ctx.$t('ARRANGEMENT_SELECTOR_DRINKS', {
                                              amount: _ctx.selectedArrangement.drink_amount,
                                          })
                                        : _ctx.$t('ARRANGEMENT_SELECTOR_DRINK')), 1)
                ])
              ])
            ], 2)
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}