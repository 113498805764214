import http from '@/utils/http-common'
import { NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router'
import store from '@/store'

export async function checkAuth(
    to: RouteLocationNormalizedLoaded,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) {
    try {
        const response = await http.get('/user')
        if (response.data) {
            store.commit('setUser', response.data)
            return true
        }
    } catch (error) {
        console.error('Error checking authentication:', error)
        next('/login')
    }
    return false
}
