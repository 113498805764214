<template>
    <div class="booking-details fade-in">
        <div class="form-wrapper">
            <div class="form-title">
                {{ $t('BOOKING_DETAILS_FORM_TITLE') }}
            </div>
            <div class="form-item">
                <div>
                    <label class="form-item-title" for="email"> {{ $t('BOOKING_DETAILS_FORM_EMAIL_TITLE') }}</label>
                    <label class="required"> {{ '*' }} </label>
                </div>
                <input
                    v-model="customer.email"
                    type="text"
                    id="email"
                    :placeholder="$t('BOOKING_DETAILS_FORM_EMAIL_PLACEHOLDER')"
                    @blur="validateCustomerFields('email')"
                    required
                />
                <div class="form-error" v-if="errors.email">
                    <label class="required"> {{ errors.email }} </label>
                </div>
                <div v-else class="form-error"></div>
            </div>
            <div class="form-item">
                <div>
                    <label class="form-item-title" for="phone"> {{ $t('BOOKING_DETAILS_FORM_PHONE_TITLE') }}</label>
                    <label class="required"> {{ '*' }} </label>
                </div>
                <vue-tel-input
                    v-model="customer.phone_number"
                    defaultCountry="NL"
                    :input-options="{ showDialCode: true, enableSearch: true }"
                    :search-options="{ enabled: true }"
                    @blur="validateCustomerFields('phone_number')"
                    @input="handlePhoneNumberChange"
                    ref="phoneInput"
                />
                <div class="form-error" v-if="errors.phone_number">
                    <label class="required"> {{ errors.phone_number }} </label>
                </div>
                <div v-else class="form-error"></div>
            </div>
            <div>
                <label class="form-item-title" for="name"> {{ $t('BOOKING_DETAILS_FORM_BOAT_TITLE') }}</label>
                <label class="required"> {{ '*' }} </label>
            </div>
            <boat-dropdown
                :boats="boats"
                :onlyDropDown="true"
                @selected="selectedOption"
                :zIndex="2"
            />
            <div class="form-error" v-if="errors.boat">
                <label class="required"> {{ errors.boat }} </label>
            </div>
            <div v-else class="form-error"></div>
            <div>
                <label class="form-item-title" for="name"> {{ $t('BOOKING_DETAILS_FORM_GUESTS_TITLE') }}</label>
                <label class="required"> {{ '*' }} </label>
            </div>
            <capacity-dropdown
                :category="selectedCategory"
                :onlyDropDown="true"
                @selected="selectedCapacity"
                :zIndex="1"
            />
            <div class="form-error" v-if="errors.capacity">
                <label class="required"> {{ errors.capacity }} </label>
            </div>
            <div v-else class="form-error"></div>
            <div class="button-control">
                <div
                    @click="nextStep"
                    class="nav-button-control pointer"
                    :class="{
              disabled:
                guests === 0,
            }"
                >
                    <span> {{ $t('BUTTON_NEXT') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

//* Components /
import BoatDropdown from '@/components/partials/custom-fields/BoatDropdown.vue';
import CapacityDropdown from '@/components/partials/custom-fields/CapacityDropdown.vue';
import { VueTelInput } from 'vue-tel-input'

//* Packages /
import { defineComponent } from 'vue'
import 'vue-tel-input/vue-tel-input.css';

//* Interfaces /
import IReservation from '@/interfaces/IReservation';
import IBoat from '@/interfaces/IBoat';
import http from '@/utils/http-common';
import ICustomer from '@/interfaces/ICustomer';
import { toast } from 'vue3-toastify';
import { parsePhoneNumberFromString, CountryCode} from 'libphonenumber-js';

export default defineComponent({
    name: "BookingDetails",
    components: {
        BoatDropdown, CapacityDropdown, VueTelInput
    },
    data() {
        return {
            customer: {} as ICustomer,
            reservation: {} as IReservation,
            boats: [] as IBoat[],
            selectedCategory: null as unknown as string,
            guests: 0 as number,
            errors: {} as Record<string, string>,
            selectedCountry: 'NL' as CountryCode | undefined,
            isDirty: false as boolean,
            details: null as null | boolean,
        }
    },
    methods: {
        getBoats(): void {
            http.get(`boats`, { params: { pagination: false } }).then((res) => {
                this.boats = res.data;
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                });
        },
        selectedOption(category: string) {
            this.selectedCategory = category;
            this.guests = 0;
            this.validateDropdownFields('boat');
            if(this.isDirty) {
                this.$emit('setCategory', this.selectedCategory)
                this.$emit('setCapacity', this.guests)
            }
        },
        selectedCapacity(guests: number) {
            this.guests = guests;
            this.validateDropdownFields('capacity')
            if(this.isDirty) {
                this.$emit('setCapacity', this.guests)
            }
        },
        formValid(): boolean {
            return !Object.keys(this.errors).length;
        },
        nextStep(): void {
            this.validateAll();
            if(!this.formValid()) {
                toast(this.$t('ERROR_MISSING_REQUIRED_FIELDS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })
                return;
            }
            if(!this.customer.id) {
                http.post(`customers`, this.customer).then((res) => {
                    if(res.data.id) {
                        localStorage.setItem('token', res.data.token);
                        this.customer = res.data
                        this.isDirty = true;
                        this.$emit('setDetails', this.customer, this.selectedCategory, this.guests);
                    }
                })
                    .catch(() => {
                        toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                            type: 'error',
                            position: 'top-right',
                            dangerouslyHTMLString: true,
                            autoClose: 3000
                        })
                    })
            }
            else {
                this.$emit('setDetails', this.customer, this.selectedCategory, this.guests);
            }
        },
        handlePhoneNumberChange(country: CountryCode) {
            this.selectedCountry = country;
        },
        validateCustomerFields(field: keyof ICustomer): void {
            const validators: Partial<Record<keyof ICustomer, () => string | null>> = {
                email: this.validateEmail,
                phone_number: this.validatePhone,
            };

            if(validators[field]) {
                const errorMessage = validators[field]?.();

                if (errorMessage) {
                    this.errors[field] = errorMessage;
                } else {
                    delete this.errors[field];
                }
            }
        },
        validateDropdownFields(field: string): void {
            const validators: Record<string, () => string | null> = {
                boat: this.validateBoat,
                capacity: this.validateCapacity
            }

            if(validators[field]) {
                const errorMessage = validators[field]?.();

                if (errorMessage) {
                    this.errors[field] = errorMessage;
                } else {
                    delete this.errors[field];
                }
            }
        },
        validateEmail(): string | null {
            if (!this.customer.email) {
                return this.$t('VALIDATION_EMAIL_REQUIRED');
            }
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this.customer.email)) {
                return this.$t('VALIDATION_EMAIL_INVALID');
            }
            return null;
        },
        validatePhone(): string | null {
            if (!this.customer.phone_number) {
                return this.$t('VALIDATION_PHONE_REQUIRED');
            }

            const phoneNumber = parsePhoneNumberFromString(this.customer.phone_number);
            const isValid = phoneNumber && phoneNumber.isValid();
            if(!isValid) {
               return this.$t('VALIDATION_PHONE_INVALID');
            }

            return null;
        },
        validateBoat(): string | null {
            if (!this.selectedCategory) {
                return this.$t('VALIDATION_BOAT_REQUIRED');
            }
            return null;
        },
        validateCapacity(): string | null {
            if (!this.guests || this.guests === 0) {
                return this.$t('VALIDATION_CAPACITY_REQUIRED');
            }
            return null;
        },
        validateAll(): void {
            this.validateCustomerFields('name');
            this.validateCustomerFields('surname');
            this.validateCustomerFields('email');
            this.validateCustomerFields('phone_number');
            this.validateDropdownFields('boat');
            this.validateDropdownFields('capacity')
        }
    },
    props: {
        requireDetails: String,
    },

    watch: {
        requireDetails: {
            handler(require: string) {
                if (require) {
                    this.details = require === '1'
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.getBoats();
    },
})
</script>

<style scoped>
    input {
        border-radius: 25px;
        border: 1px solid #e4e4e4;
    }

    .booking-details {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .form-item-group {
        display: flex;
        width: 100%;
    }

    .form-item-title {
        color: #9f9f9f;
    }

    .form-item {
        width: 100%;
    }

    .form-title {
        margin: 10px 0;
        font-size: 20px;
    }

    .button-control {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin-top: auto;
    }

    .nav-button-control {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #5b9a42;
        border: 1px solid #457331;
        border-radius: 8px;
        color: white;
        padding: 8px;
        width: 25%;
        transition: background-color ease-in 0.2s;
    }

    .nav-button-control:hover {
        background-color: #467733;
        border: 1px solid #345624;
    }

    .vue-tel-input {
        border-radius: 25px !important;
        border: 1px solid #e4e4e4;
        min-height: 33px;
        max-height: 33px;
    }

    .vue-tel-input:hover {
        border: 1px solid var(--primary-admin-highlight);
    }

    .vue-tel-input:focus-within {
        box-shadow: unset;
        outline: 1px solid var(--primary-admin-highlight);
    }

    :deep(.vue-tel-input) input {
        border-radius: 25px;
    }

   :deep(.vue-tel-input) .vti__dropdown {
        border-radius: 25px;
    }

   :deep(.vti__dropdown-list.below) {
       z-index: 10;
   }

 :deep(.vue-tel-input) .vti__dropdown-item strong {
     font-weight: 400; /* Set font-weight to normal */
 }

</style>