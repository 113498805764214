import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "date-pick-details fade-in" }
const _hoisted_2 = { class: "date-picker-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "front-form-item"
}
const _hoisted_4 = {
  class: "form-item-title",
  for: "name"
}
const _hoisted_5 = {
  class: "f-select",
  style: {"display":"flex"}
}
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 0,
  class: "form-error"
}
const _hoisted_8 = { class: "required" }
const _hoisted_9 = {
  key: 1,
  class: "front-form-item"
}
const _hoisted_10 = { class: "form-item" }
const _hoisted_11 = {
  class: "form-item-title",
  for: "phone"
}
const _hoisted_12 = {
  key: 0,
  class: "form-error"
}
const _hoisted_13 = { class: "required" }
const _hoisted_14 = {
  key: 1,
  class: "form-error"
}
const _hoisted_15 = { class: "date-picker fade-in" }
const _hoisted_16 = ["id"]
const _hoisted_17 = { colspan: "7" }
const _hoisted_18 = { class: "picker-header" }
const _hoisted_19 = { class: "month-title" }
const _hoisted_20 = { scope: "col" }
const _hoisted_21 = { class: "table-header text" }
const _hoisted_22 = { scope: "col" }
const _hoisted_23 = { class: "table-header text" }
const _hoisted_24 = { scope: "col" }
const _hoisted_25 = { class: "table-header text" }
const _hoisted_26 = { scope: "col" }
const _hoisted_27 = { class: "table-header text" }
const _hoisted_28 = { scope: "col" }
const _hoisted_29 = { class: "table-header text" }
const _hoisted_30 = { scope: "col" }
const _hoisted_31 = { class: "table-header text" }
const _hoisted_32 = { scope: "col" }
const _hoisted_33 = { class: "table-header text" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { ref: "bookingCard" }
const _hoisted_36 = { colspan: "7" }
const _hoisted_37 = {
  key: 0,
  class: "date-picker-footer"
}
const _hoisted_38 = { class: "candal-regular" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!
  const _component_boat_booking_card = _resolveComponent("boat-booking-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.additionalFields)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_GUESTS_TITLE')), 1),
              _cache[7] || (_cache[7] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.guests) = $event)),
                style: {"width":"100%","border":"1px solid #e4e4e4","background-color":"white","border-radius":"25px"},
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.getBoatCategory && _ctx.getBoatCategory(...args)))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: option,
                    value: option
                  }, _toDisplayString(_ctx.$t('CAPACITY_DROPDOWN_SELECTED', { capacity: option })), 9, _hoisted_6))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.guests]
              ]),
              _cache[8] || (_cache[8] = _createElementVNode("span", { class: "material-symbols-outlined caret" }, "expand_more", -1))
            ]),
            (_ctx.errors.capacity)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.errors.capacity), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.additionalFields)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_PHONE_TITLE')), 1),
                _cache[9] || (_cache[9] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
              ]),
              _createVNode(_component_vue_tel_input, {
                ref: "phoneInput",
                modelValue: _ctx.customer.phone_number,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone_number) = $event)),
                "input-options": { showDialCode: true, enableSearch: true },
                "search-options": { enabled: true },
                defaultCountry: "NL",
                onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateCustomerFields('phone_number')))
              }, null, 8, ["modelValue"]),
              (_ctx.errors.phone_number)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.errors.phone_number), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_14))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("table", {
          id: _ctx.asAdmin ? 'date-picker-admin' : 'date-picker',
          class: "date-table"
        }, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", {
                    class: "pointer",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.previousMonth && _ctx.previousMonth(...args)))
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("span", { class: "material-symbols-outlined month-nav" }, " arrow_left_alt ", -1)
                  ])),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(`${_ctx.currentMonth ? _ctx.$t(_ctx.currentMonth.toUpperCase()) : ''} ${_ctx.currentYear}`), 1),
                  _createElementVNode("div", {
                    class: "pointer",
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.nextMonth && _ctx.nextMonth(...args)))
                  }, _cache[11] || (_cache[11] = [
                    _createElementVNode("span", { class: "material-symbols-outlined month-nav" }, " arrow_right_alt ", -1)
                  ]))
                ])
              ])
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('MONDAY_ABBREVIATION')), 1)
              ]),
              _createElementVNode("th", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('TUESDAY_ABBREVIATION')), 1)
              ]),
              _createElementVNode("th", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('WEDNESDAY_ABBREVIATION')), 1)
              ]),
              _createElementVNode("th", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('THURSDAY_ABBREVIATION')), 1)
              ]),
              _createElementVNode("th", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('FRIDAY_ABBREVIATION')), 1)
              ]),
              _createElementVNode("th", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('SATURDAY_ABBREVIATION')), 1)
              ]),
              _createElementVNode("th", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('SUNDAY_ABBREVIATION')), 1)
              ])
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendar, (week, weekIndex) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: week.id
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week.days, (day) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: day.id
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass([{
                                        disabled: !day.selectable || !day.isBookAble,
                                        'selected-day': day.day === _ctx.selectedDay && day.selectable,
                                    }, "day"]),
                      onClick: ($event: any) => (_ctx.handleClickDay(day, weekIndex, day.day))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass([{
                                            disabled: !day.selectable,
                                            selected: day.day === _ctx.selectedDay && day.selectable,
                                        }, "day-wrapper"])
                      }, [
                        _createElementVNode("span", null, _toDisplayString(`${day.day}`), 1)
                      ], 2)
                    ], 10, _hoisted_34)
                  ]))
                }), 128))
              ]))
            }), 128)),
            _withDirectives(_createElementVNode("tr", _hoisted_35, [
              _createElementVNode("td", _hoisted_36, [
                _createVNode(_component_boat_booking_card, {
                  bookable: _ctx.selectedCategory && _ctx.isPhoneNumberValid,
                  calendarDay: _ctx.selectedDayObj,
                  category: _ctx.selectedCategory,
                  "current-slot": _ctx.selectedId ? _ctx.currentSlot : null,
                  currentDate: _ctx.currentSelectedDate,
                  onHandleReservation: _ctx.handleReservation
                }, null, 8, ["bookable", "calendarDay", "category", "current-slot", "currentDate", "onHandleReservation"])
              ])
            ], 512), [
              [_vShow, _ctx.showBooking]
            ])
          ])
        ], 8, _hoisted_16),
        (!_ctx.hideButtons)
          ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
              _createElementVNode("div", {
                id: "next_date_button",
                class: "nav-button-control pointer text",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.nextStep && _ctx.nextStep(...args)))
              }, [
                _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.$t('BUTTON_NEXT')), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}