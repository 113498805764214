<template>
    <div class="date-pick-details fade-in">
        <div class="date-picker-wrapper">
            <div v-if="additionalFields" class="front-form-item">
                <div>
                    <label class="form-item-title" for="name"> {{ $t('BOOKING_DETAILS_FORM_GUESTS_TITLE') }}</label>
                    <label class="required"> {{ '*' }} </label>
                </div>
                <div class="f-select" style="display: flex">
                    <select
                        v-model="guests"
                        style="width: 100%; border: 1px solid #e4e4e4; background-color: white; border-radius: 25px"
                        @change="getBoatCategory"
                    >
                        <option v-for="option in options" :key="option" :value="option">
                            {{ $t('CAPACITY_DROPDOWN_SELECTED', { capacity: option }) }}
                        </option>
                    </select>
                    <span class="material-symbols-outlined caret">expand_more</span>
                </div>
                <div v-if="errors.capacity" class="form-error">
                    <label class="required"> {{ errors.capacity }} </label>
                </div>
            </div>
            <div v-if="additionalFields" class="front-form-item">
                <div class="form-item">
                    <div>
                        <label class="form-item-title" for="phone"> {{ $t('BOOKING_DETAILS_FORM_PHONE_TITLE') }}</label>
                        <label class="required"> {{ '*' }} </label>
                    </div>
                    <vue-tel-input
                        ref="phoneInput"
                        v-model="customer.phone_number"
                        :input-options="{ showDialCode: true, enableSearch: true }"
                        :search-options="{ enabled: true }"
                        defaultCountry="NL"
                        @blur="validateCustomerFields('phone_number')"
                    />
                    <div v-if="errors.phone_number" class="form-error">
                        <label class="required"> {{ errors.phone_number }} </label>
                    </div>
                    <div v-else class="form-error"></div>
                </div>
            </div>
            <div class="date-picker fade-in">
                <table :id="asAdmin ? 'date-picker-admin' : 'date-picker'" class="date-table">
                    <thead>
                        <tr>
                            <th colspan="7">
                                <div class="picker-header">
                                    <div class="pointer" @click="previousMonth">
                                        <span class="material-symbols-outlined month-nav"> arrow_left_alt </span>
                                    </div>
                                    <span class="month-title">
                                        {{ `${currentMonth ? $t(currentMonth.toUpperCase()) : ''} ${currentYear}` }}
                                    </span>
                                    <div class="pointer" @click="nextMonth">
                                        <span class="material-symbols-outlined month-nav"> arrow_right_alt </span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th scope="col">
                                <div class="table-header text">{{ $t('MONDAY_ABBREVIATION') }}</div>
                            </th>
                            <th scope="col">
                                <div class="table-header text">{{ $t('TUESDAY_ABBREVIATION') }}</div>
                            </th>
                            <th scope="col">
                                <div class="table-header text">{{ $t('WEDNESDAY_ABBREVIATION') }}</div>
                            </th>
                            <th scope="col">
                                <div class="table-header text">{{ $t('THURSDAY_ABBREVIATION') }}</div>
                            </th>
                            <th scope="col">
                                <div class="table-header text">{{ $t('FRIDAY_ABBREVIATION') }}</div>
                            </th>
                            <th scope="col">
                                <div class="table-header text">{{ $t('SATURDAY_ABBREVIATION') }}</div>
                            </th>
                            <th scope="col">
                                <div class="table-header text">{{ $t('SUNDAY_ABBREVIATION') }}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(week, weekIndex) in calendar" :key="week.id">
                            <td v-for="day in week.days" :key="day.id">
                                <div
                                    :class="{
                                        disabled: !day.selectable || !day.isBookAble,
                                        'selected-day': day.day === selectedDay && day.selectable,
                                    }"
                                    class="day"
                                    @click="handleClickDay(day, weekIndex, day.day)"
                                >
                                    <div
                                        :class="{
                                            disabled: !day.selectable,
                                            selected: day.day === selectedDay && day.selectable,
                                        }"
                                        class="day-wrapper"
                                    >
                                        <span>{{ `${day.day}` }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-show="showBooking" ref="bookingCard">
                            <td colspan="7">
                                <boat-booking-card
                                    :bookable="selectedCategory && isPhoneNumberValid"
                                    :calendarDay="selectedDayObj"
                                    :category="selectedCategory"
                                    :current-slot="selectedId ? currentSlot : null"
                                    :currentDate="currentSelectedDate"
                                    @handleReservation="handleReservation"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="!hideButtons" class="date-picker-footer">
                    <div id="next_date_button" class="nav-button-control pointer text" @click="nextStep">
                        <span class="candal-regular">
                            {{ $t('BUTTON_NEXT') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/** Components */
import { defineComponent } from 'vue'
import BoatBookingCard from './BoatBookingCard.vue'

/** Packages */
import dayjs, { Dayjs } from 'dayjs'
import http from '@/utils/http-common'

/** Models */
import ICalendarWeek from '../../../interfaces/ICalendarWeek'
import ICalendarDay from '../../../interfaces/ICalendarDay'
import ITimeSlot from '../../../interfaces/ITimeSlot'
import ICustomTimeSlot from '@/interfaces/ICustomTimeSlot'
import { toast } from 'vue3-toastify'
import ICustomer from '@/interfaces/ICustomer'
import { VueTelInput } from 'vue-tel-input'
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'
import ISetting from '@/interfaces/ISetting'

export default defineComponent({
    name: 'CustomDatePicker',
    props: {
        isActive: Boolean,
        id: Number,
        hideButtons: Boolean,
        admin: Boolean,
        additionalFields: Boolean,
        currentPhoneNumber: String,
        currentCategory: String,
        currentSelectedDate: String,
        selectedId: String,
        currentSlot: {
            type: Object as () => ITimeSlot,
            default: () => ({}),
        },
        currentGuests: {
            type: Number,
        },
    },
    components: {
        VueTelInput,
        BoatBookingCard,
    },
    data() {
        return {
            calendar: [] as ICalendarWeek[],
            customTimeSlots: [] as ICustomTimeSlot[],
            selectedDay: null as unknown as number,
            selectedDayObj: null as unknown as ICalendarDay,
            pickDate: false as boolean,
            selectedDate: null as unknown as string,
            currentDate: null as unknown as string,
            currentMonth: null as unknown as string,
            currentYear: null as unknown as string,
            showBooking: false as boolean,
            dayIndex: 0 as number,
            selectedTimeSlot: null as unknown as ITimeSlot,
            asAdmin: false as boolean,
            customer: {} as ICustomer,
            errors: {} as Record<string, string>,
            selectedCountry: 'NL' as CountryCode | undefined,
            selectedCategory: null as unknown as string,
            isDirty: false as boolean,
            guests: 0 as number,
            options: [] as Array<number>,
            priceSettings: [] as ISetting[],
            isPhoneNumberValid: false as boolean,
            currentSelectedId: null as unknown as string,
        }
    },
    methods: {
        toggle(): void {
            this.pickDate = !this.pickDate
        },
        handleClickDay(day: ICalendarDay, weekIndex: number, dayIndex: number): void {
            if (!day.selectable || !day.isBookAble) {
                return
            }

            if (this.dayIndex != dayIndex) {
                this.showBooking = true
            } else {
                this.showBooking = !this.showBooking
            }

            this.dayIndex = dayIndex

            const bookingCard = this.$refs.bookingCard as HTMLTableRowElement

            if (bookingCard && bookingCard.parentNode) {
                bookingCard.parentNode.removeChild(bookingCard)
            }

            const currentRow: Element = this.$el.querySelectorAll('tbody tr')[weekIndex]
            currentRow.parentNode?.insertBefore(bookingCard, currentRow.nextSibling)

            this.setSelectedDay(day, weekIndex, dayIndex)

            this.selectedDate = dayjs(this.currentDate)
                .format('YYYY-MM-DD')
                .replace(/[0-9]{2}$/, this.selectedDay.toString())
        },
        async GenerateCalendar(): Promise<void> {
            this.customTimeSlots = await this.checkCustomTimeSlots()

            let firstDayIndex = dayjs(this.currentDate).date(1).day() - 1
            if (firstDayIndex === -1) {
                firstDayIndex = 6
            }

            let dayIdentifier = 0

            let dayIndex: Dayjs = dayjs(this.currentDate)
                .subtract(1, 'month')
                .date(dayjs(this.currentDate).subtract(1, 'month').daysInMonth() - firstDayIndex + 1)

            const calendar: ICalendarWeek[] = []

            let weekIndex = 0
            let week: ICalendarWeek = { id: weekIndex, days: [] }

            for (let i = 0; i < 43; i++) {
                let isSelectable = true
                let isCustomTimeSlot = false
                let isBookAble = this.isBookAble(dayIndex, this.customTimeSlots)
                if (dayIndex.isBefore(dayjs())) {
                    isSelectable = false
                }
                if (i < firstDayIndex || i > dayjs(this.currentDate).daysInMonth() + firstDayIndex - 1) {
                    isSelectable = false
                }

                const today = dayjs()
                if (dayIndex.isSame(today, 'day')) {
                    isSelectable = true
                }

                if (week.days.length === 7) {
                    calendar.push(week)
                    weekIndex++
                    week = { id: weekIndex, days: [] }
                }
                if (isSelectable) {
                    isCustomTimeSlot = this.dayFoundInCustomTimeSlot(dayIndex, this.customTimeSlots)
                }

                week.days.push({
                    id: dayIdentifier,
                    day: dayIndex.date(),
                    dayOfWeek: dayIndex.format('dddd'),
                    date: dayIndex.format('YYYY-MM-DD'),
                    selectable: isSelectable,
                    selected: false,
                    customTimeSlot: isCustomTimeSlot,
                    isBookAble: isBookAble,
                })
                dayIndex = dayIndex.add(1, 'day')
                dayIdentifier += 1
            }

            this.calendar = calendar
        },
        async checkCustomTimeSlots(): Promise<ICustomTimeSlot[]> {
            const startDate = dayjs(this.currentDate).startOf('month').format('YYYY-MM-DD')
            const endDate = dayjs(this.currentDate).endOf('month').format('YYYY-MM-DD')

            try {
                const res = await http.get(`custom_time_slot/dates`, {
                    params: {
                        start_date: startDate,
                        end_date: endDate,
                    },
                })
                return res.data.customTimeSlots
            } catch (error) {
                toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000,
                })

                return []
            }
        },
        formattedPrice(index: number): string {
            const setting = this.priceSettings.find(
                (setting: ISetting) => setting.key === 'PERSON_PRICE_SETTINGS_' + index,
            )
            if (setting && isNaN(parseFloat(String(setting.value)))) {
                return '0.00'
            }

            let price = parseFloat(String(setting?.value))
            let symbols = ['€', '$']

            const item = localStorage.getItem('currency_symbol')
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price?.toFixed(2)}`
                } else {
                    return `${price?.toFixed(2)} ${item}`
                }
            }
            return `${price}`
        },
        getPrices(): void {
            http.get(`person-price-settings`).then((res) => {
                this.priceSettings = res.data
            })
        },
        dayFoundInCustomTimeSlot(dayIndex: dayjs.Dayjs, customTimeSlots: ICustomTimeSlot[]): boolean {
            for (const customTimeSlot of customTimeSlots) {
                const start = dayjs(customTimeSlot.start_date)
                const end = dayjs(customTimeSlot.end_date)
                if (dayIndex.date() >= start.date() && dayIndex.date() <= end.date()) {
                    return true
                }
            }
            return false
        },
        isBookAble(dayIndex: dayjs.Dayjs, customTimeSlots: ICustomTimeSlot[]): boolean {
            let bookable = 0
            for (const customTimeSlot of customTimeSlots) {
                const start = dayjs(customTimeSlot.start_date)
                const end = dayjs(customTimeSlot.end_date)
                if (dayIndex.date() >= start.date() && dayIndex.date() <= end.date()) {
                    bookable = Number(customTimeSlot.disable_appointments)
                }
            }
            return bookable !== 1
        },

        validateDropdownFields(field: string): void {
            const validators: Record<string, () => string | null> = {
                capacity: this.validateCapacity,
            }

            if (validators[field]) {
                const errorMessage = validators[field]?.()

                if (errorMessage) {
                    this.errors[field] = errorMessage
                } else {
                    delete this.errors[field]
                }
            }
        },

        validateCapacity(): string | null {
            if (!this.guests || this.guests === 0) {
                return this.$t('VALIDATION_CAPACITY_REQUIRED')
            }
            return null
        },

        selectedCapacity(guests: number) {
            this.guests = guests
            this.validateDropdownFields('capacity')
            if (this.isDirty) {
                this.$emit('setCapacity', this.guests)
            }
        },

        nextMonth(): void {
            if (this.showBooking) {
                this.showBooking = false
            }
            this.currentDate = dayjs(this.currentDate).add(1, 'month').toString()
            this.setDateVariables()
            this.GenerateCalendar()
            this.selectedDay = 0
        },
        previousMonth() {
            if (this.showBooking) {
                this.showBooking = false
            }
            this.currentDate = dayjs(this.currentDate).subtract(1, 'month').toString()
            this.setDateVariables()
            this.GenerateCalendar()
            this.selectedDay = 0
        },
        validateCustomerFields(field: keyof ICustomer): void {
            const validators: Partial<Record<keyof ICustomer, () => string | null>> = {
                phone_number: this.validatePhone,
            }

            if (validators[field]) {
                const errorMessage = validators[field]?.()

                if (errorMessage) {
                    this.errors[field] = errorMessage
                } else {
                    delete this.errors[field]
                }
            }
        },

        validatePhone(): string | null {
            this.isPhoneNumberValid = false

            if (!this.customer.phone_number) {
                return this.$t('VALIDATION_PHONE_REQUIRED')
            }

            const phoneNumber = parsePhoneNumberFromString(this.customer.phone_number)
            const isValid = phoneNumber && phoneNumber.isValid()
            if (!isValid) {
                return this.$t('VALIDATION_PHONE_INVALID')
            }

            this.isPhoneNumberValid = true
            return null
        },

        setDateVariables() {
            this.currentMonth = dayjs(this.currentDate).format('MMMM')
            this.currentYear = dayjs(this.currentDate).format('YYYY')
        },
        setSelectedDay(day: ICalendarDay, weekIndex: number, dayNumber: number) {
            if (day.selectable && this.selectedDay != day.day) {
                this.selectedDay = day.day
                this.selectedDayObj = day
            } else {
                let day = this.calendar[weekIndex].days.find((day) => day.day === dayNumber)
                if (day) {
                    day.selected = false
                }
                this.selectedDay = 0
            }
        },
        getBoatCategory(): void {
            http.get(`get-boat/${this.guests}`).then((res) => {
                if (res.data.category !== this.selectedCategory) {
                    this.selectedDate = ''
                }

                this.selectedCategory = res.data.category
            })
        },
        getCapacity(): void {
            let category = 'XL'

            http.get(`get_boat_capacity/${category}`)
                .then((res) => {
                    for (let i = 2; i < res.data.capacity + 1; i++) {
                        this.options.push(i)
                    }
                })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                })
        },
        emitDate() {
            this.selectedDate = dayjs(this.currentDate)
                .format('YYYY-MM-DD')
                .replace(/[0-9]{2}$/, this.selectedDay.toString())
            this.$emit('setDate', dayjs(this.selectedDate).format('YYYY-MM-DD'))
            this.toggle()
        },
        handleReservation(slot: ITimeSlot) {
            this.selectedTimeSlot = slot
            this.showBooking = false
            if (!this.customer.id) {
                http.post(`customers`, this.customer)
                    .then((res) => {
                        if (res.data.id) {
                            localStorage.setItem('token', res.data.token)
                            this.customer = res.data
                            this.isDirty = true
                            this.$emit(
                                'startReservation',
                                slot,
                                this.selectedDate,
                                this.customer,
                                this.selectedCategory,
                                this.guests,
                            )
                        }
                    })
                    .catch(() => {
                        toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                            type: 'error',
                            position: 'top-right',
                            dangerouslyHTMLString: true,
                            autoClose: 3000,
                        })

                        return
                    })
            } else {
                this.$emit(
                    'startReservation',
                    slot,
                    this.selectedDate,
                    this.customer,
                    this.selectedCategory,
                    this.guests,
                )
            }
        },
        nextStep(): void {
            if (!this.hideButtons) {
                if (!(this.isPhoneNumberValid && this.selectedCategory)) {
                    toast(this.$t('ERROR_MISSING_REQUIRED_FIELDS'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })

                    return
                }
            }
            if (!this.selectedDate || !this.selectedTimeSlot) {
                toast(this.$t('ERROR_FRONTEND_DATE_PICKER_NO_DATE_SELECTED'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000,
                })
                return
            }
            this.$emit(
                'startReservation',
                this.selectedTimeSlot,
                this.selectedDate,
                this.customer,
                this.selectedCategory,
                this.guests,
            )
        },
        prevStep(): void {
            this.$emit('prevStep')
        },
    },
    watch: {
        selectedId: {
            handler: function (val) {
                this.currentSelectedId = val
            },
            immediate: true,
        },
        currentPhoneNumber: {
            handler: function (val) {
                this.customer.phone_number = val
            },
            immediate: true,
        },
        currentSlot: {
            handler: function (val) {
                this.selectedTimeSlot = val
            },
            immediate: true,
        },
        currentCategory: {
            handler: function (val) {
                this.selectedCategory = val
            },
            immediate: true,
        },
        currentGuests: {
            handler: function (val) {
                this.guests = val
            },
            immediate: true,
        },
        currentSelectedDate: {
            handler: function (val) {
                this.selectedDate = val
            },
            immediate: true,
        },
    },
    mounted() {
        this.showBooking = false
        this.currentDate = dayjs().toString()

        this.getCapacity()
        this.getPrices()
        this.setDateVariables()
        this.GenerateCalendar()

        if (!this.hideButtons && !this.currentGuests) {
            this.guests = 2
            this.getBoatCategory()
        }
    },
})
</script>

<style scoped>
.date-pick-details {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.date-picker {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
}

.date-picker-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 500px;
}

.date-table {
    width: 100%;
    border-spacing: 0;
    padding: 10px;
}

.date-table thead th {
    font-weight: normal;
    font-size: 16px;
}

tr:hover {
    background-color: unset !important;
}

.table-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    color: white !important;
    background-color: black;
}

.picker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 10px;
    background-color: black;
    color: white;
    margin-bottom: 10px;
}

.month-title {
    font-size: 16px;
    margin: 0 10px;
}

.picker-header > .pointer {
    display: flex;
    align-items: center;
}

.day {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    border-bottom: 1px solid #dddddd;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.3s ease;
    color: #8c8c9b;
    font-size: 14px;
}

.day-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    min-width: 40px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.day:hover {
    background-color: #fafafa;
}

.day:hover .day-wrapper {
    color: black;
    background-color: #5b9a42;
}

.disabled {
    color: #cccdd1;
    cursor: not-allowed;
    background-color: #fafafa;
}

.disabled:hover {
    background-color: #fafafa !important;
}

.disabled:hover .day-wrapper {
    color: unset;
    background-color: unset;
}

.selected {
    color: black;
    background-color: #5b9a42;
}

.selected-day {
    background-color: #dddddd !important;
}

.selected-day:hover {
    background-color: #dddddd !important;
}

.date-picker-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
}

.nav-button-control {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5b9a42;
    border: 1px solid #457331;
    border-radius: 8px;
    color: white;
    padding: 8px;
    width: 25%;
    transition: background-color ease-in 0.2s;
}

.nav-button-control:hover {
    background-color: #467733;
    border: 1px solid #345624;
}

.month-nav {
    font-size: 34px;
}

.front-form-item {
    margin: 0 10px;
}

.form-item-title {
    color: #9f9f9f;
}

.form-item {
    width: 100%;
}

.form-title {
    margin: 10px 0;
    font-size: 20px;
}

input {
    border-radius: 25px;
    border: 1px solid #e4e4e4;
}

.vue-tel-input {
    border-radius: 25px !important;
    border: 1px solid #e4e4e4;
    min-height: 33px;
    max-height: 33px;
}

.vue-tel-input:hover {
    border: 1px solid var(--primary-admin-highlight);
}

.vue-tel-input:focus-within {
    box-shadow: unset;
    outline: 1px solid var(--primary-admin-highlight);
}

:deep(.vue-tel-input) input {
    border-radius: 25px;
}

:deep(.vue-tel-input) .vti__dropdown {
    border-radius: 25px;
}

:deep(.vti__dropdown-list.below) {
    z-index: 10;
}

:deep(.vue-tel-input) .vti__dropdown-item strong {
    font-weight: 400; /* Set font-weight to normal */
}
</style>
