<template>
    <div class="app">
        <router-view />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import http from '@/utils/http-common'

export default defineComponent({
    name: 'App',
    components: {},
    mounted(): void {
        http.get('settings/key/REGION_SETTINGS').then((res) => {
            localStorage.setItem('region', res.data.value)
        })

        http.get('settings/key/CURRENCY_SYMBOL_SETTINGS').then((res) => {
            localStorage.setItem('currency_symbol', res.data.value)
        })
    },
})
</script>

<style>
@import url('@/css/global.css');
@import url('@/css/font.css');
@import url('@/css/color.css');
@import url('@/css/animations.css');
@import url('@/css/modal.css');
@import url('@/css/scroll-bar.css');
@import url('@/css/dropdown.css');
@import url('@/css/tables.css');
@import url('@/css/media_queries.css');
@import url('@/css/shopping_cart.css');
@import url('@/css/forms.css');
@import url('@/css/mobile.css');

.app {
    height: 100dvh;
    width: 100vw;
}
</style>
