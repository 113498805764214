import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "object-selector" }
const _hoisted_2 = { class: "object-selector-header" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "candal-regular text" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "candal-regular" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "candal-regular" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = {
  id: "drink-indicator",
  class: "cart-indicator"
}
const _hoisted_12 = { class: "sub-text" }
const _hoisted_13 = { class: "shopping-cart-header" }
const _hoisted_14 = { class: "candal-regular text" }
const _hoisted_15 = { class: "shopping-cart-body" }
const _hoisted_16 = { class: "cart-category-separator" }
const _hoisted_17 = { class: "text f-bold" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "text" }
const _hoisted_20 = {
  key: 1,
  style: { width: '100%' }
}
const _hoisted_21 = { style: { width: '100%' } }
const _hoisted_22 = { style: { width: '60%' } }
const _hoisted_23 = { style: { width: '15%' } }
const _hoisted_24 = { style: { width: '25%', textAlign: 'right' } }
const _hoisted_25 = { class: "shopping-cart-footer" }
const _hoisted_26 = { class: "candal-regular text" }
const _hoisted_27 = { class: "object-list-wrapper" }
const _hoisted_28 = {
  key: 0,
  class: "spinner-wrapper"
}
const _hoisted_29 = {
  key: 1,
  class: "object-list fade-in"
}
const _hoisted_30 = {
  key: 0,
  class: "ribbon ribbon-top-left"
}
const _hoisted_31 = { class: "object-image-wrapper" }
const _hoisted_32 = { class: "object-image" }
const _hoisted_33 = ["src"]
const _hoisted_34 = { class: "object-header" }
const _hoisted_35 = { style: {"text-align":"center"} }
const _hoisted_36 = { style: {"color":"#f2682c","font-weight":"bold"} }
const _hoisted_37 = { class: "object-footer" }
const _hoisted_38 = { class: "object-buttons" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { class: "object-counter" }
const _hoisted_41 = { class: "text" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "object-footer-wrapper" }
const _hoisted_44 = { class: "object-selector-footer" }
const _hoisted_45 = { class: "candal-regular" }
const _hoisted_46 = { class: "candal-regular" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.selectedDrinks.length != _ctx.amountOfDrinks && _ctx.amountOfDrinks - _ctx.selectedDrinks.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(
                                this.amountOfDrinks - this.selectedDrinks.length != 1
                                    ? 'DRINK_SELECTOR_AMOUNT_OF_DRINKS_LEFT'
                                    : 'DRINK_SELECTOR_AMOUNT_OF_DRINK_LEFT',
                                { amount: this.amountOfDrinks - this.selectedDrinks.length },
                            )), 1)
            ]))
          : (_ctx.selectedDrinks.length != _ctx.amountOfDrinks && _ctx.amountOfDrinks - _ctx.selectedDrinks.length < 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_TO_MUCH_DRINKS')), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('DRINK_SELECTOR_NO_MORE_DRINKS')), 1)
              ]))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          class: "shopping-cart-wrapper",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleCart && _ctx.toggleCart(...args)))
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "material-symbols-outlined pointer" }, " shopping_cart ", -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(this.selectedDrinks.length), 1)
          ]),
          _createVNode(_Transition, {
            "enter-active-class": "fade-in",
            "leave-active-class": "fade-out",
            name: "custom-classes"
          }, {
            default: _withCtx(() => [
              (_ctx.openDrinkCart)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "shopping-cart-content",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickOutside && _ctx.handleClickOutside(...args)))
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('SHOPPING_CART_DRINKS')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('SHOPPING_CART_DRINKS')), 1)
                      ]),
                      (_ctx.selectedDrinks.length === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('SHOPPING_CART_NO_DRINKS_SELECTED')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                            _createElementVNode("table", _hoisted_21, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentDrinkOverview(), (obj, index) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: index,
                                  class: "shopping-cart-item"
                                }, [
                                  _createElementVNode("td", _hoisted_22, _toDisplayString(obj.drink), 1),
                                  _createElementVNode("td", _hoisted_23, _toDisplayString(`${obj.amount}x`), 1),
                                  _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.formattedPrice(0)), 1)
                                ]))
                              }), 128))
                            ])
                          ]))
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("span", _hoisted_26, _toDisplayString(`${_ctx.$t('SHOPPING_CART_TOTAL')} ${_ctx.formattedPrice(_ctx.totalCosts)}`), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_27, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createVNode(_component_loading_spinner)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_29, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drinkList, (drink) => {
              return (_openBlock(), _createElementBlock("div", {
                key: drink.id,
                class: "object-card grow shadow"
              }, [
                (drink.is_popular)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('POPULAR')), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("img", {
                      src: drink.media_library.original_url,
                      alt: "Image"
                    }, null, 8, _hoisted_33)
                  ])
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("span", _hoisted_35, _toDisplayString(`${drink.name}`), 1),
                  _createElementVNode("span", _hoisted_36, _toDisplayString(`${drink.quantity} ${drink.unit}`), 1),
                  _createElementVNode("span", null, _toDisplayString(`${drink.alcohol_percentage ? drink.alcohol_percentage + '%' : ''}`), 1)
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, [
                    _createElementVNode("div", {
                      class: "count-button",
                      onClick: 
                                    (event) => {
                                        _ctx.removeDrink(drink)
                                        _ctx.animateButton(event)
                                    }
                                
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " remove ", -1)
                    ]), 8, _hoisted_39),
                    _createElementVNode("div", _hoisted_40, [
                      _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.countDrink(drink.id)), 1)
                    ]),
                    _createElementVNode("div", {
                      class: "count-button",
                      onClick: 
                                    (event) => {
                                        _ctx.addDrink(drink)
                                        _ctx.animateButton(event)
                                    }
                                
                    }, _cache[7] || (_cache[7] = [
                      _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " add ", -1)
                    ]), 8, _hoisted_42)
                  ])
                ])
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("div", {
                  id: "prev_drink_button",
                  class: "nav-button-control pointer text",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.prevStep && _ctx.prevStep(...args)))
                }, [
                  _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t('BUTTON_BACK')), 1)
                ]),
                _createElementVNode("div", {
                  id: "next_drink_button",
                  class: "nav-button-control pointer text",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextStep && _ctx.nextStep(...args)))
                }, [
                  _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.$t('BUTTON_NEXT')), 1)
                ])
              ])
            ])
          ]))
    ])
  ]))
}