import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "booking-details fade-in" }
const _hoisted_2 = { class: "form-wrapper" }
const _hoisted_3 = { class: "form-title" }
const _hoisted_4 = { class: "form-item" }
const _hoisted_5 = {
  class: "form-item-title",
  for: "email"
}
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "form-error"
}
const _hoisted_8 = { class: "required" }
const _hoisted_9 = {
  key: 1,
  class: "form-error"
}
const _hoisted_10 = { class: "form-item" }
const _hoisted_11 = {
  class: "form-item-title",
  for: "phone"
}
const _hoisted_12 = {
  key: 0,
  class: "form-error"
}
const _hoisted_13 = { class: "required" }
const _hoisted_14 = {
  key: 1,
  class: "form-error"
}
const _hoisted_15 = {
  class: "form-item-title",
  for: "name"
}
const _hoisted_16 = {
  key: 0,
  class: "form-error"
}
const _hoisted_17 = { class: "required" }
const _hoisted_18 = {
  key: 1,
  class: "form-error"
}
const _hoisted_19 = {
  class: "form-item-title",
  for: "name"
}
const _hoisted_20 = {
  key: 2,
  class: "form-error"
}
const _hoisted_21 = { class: "required" }
const _hoisted_22 = {
  key: 3,
  class: "form-error"
}
const _hoisted_23 = { class: "button-control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!
  const _component_boat_dropdown = _resolveComponent("boat-dropdown")!
  const _component_capacity_dropdown = _resolveComponent("capacity-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_TITLE')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_EMAIL_TITLE')), 1),
          _cache[5] || (_cache[5] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
        ]),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.email) = $event)),
          type: "text",
          id: "email",
          placeholder: _ctx.$t('BOOKING_DETAILS_FORM_EMAIL_PLACEHOLDER'),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateCustomerFields('email'))),
          required: ""
        }, null, 40, _hoisted_6), [
          [_vModelText, _ctx.customer.email]
        ]),
        (_ctx.errors.email)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.errors.email), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_PHONE_TITLE')), 1),
          _cache[6] || (_cache[6] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
        ]),
        _createVNode(_component_vue_tel_input, {
          modelValue: _ctx.customer.phone_number,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone_number) = $event)),
          defaultCountry: "NL",
          "input-options": { showDialCode: true, enableSearch: true },
          "search-options": { enabled: true },
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateCustomerFields('phone_number'))),
          onInput: _ctx.handlePhoneNumberChange,
          ref: "phoneInput"
        }, null, 8, ["modelValue", "onInput"]),
        (_ctx.errors.phone_number)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.errors.phone_number), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_14))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_BOAT_TITLE')), 1),
        _cache[7] || (_cache[7] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
      ]),
      _createVNode(_component_boat_dropdown, {
        boats: _ctx.boats,
        onlyDropDown: true,
        onSelected: _ctx.selectedOption,
        zIndex: 2
      }, null, 8, ["boats", "onSelected"]),
      (_ctx.errors.boat)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.errors.boat), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_18)),
      _createElementVNode("div", null, [
        _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_GUESTS_TITLE')), 1),
        _cache[8] || (_cache[8] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
      ]),
      _createVNode(_component_capacity_dropdown, {
        category: _ctx.selectedCategory,
        onlyDropDown: true,
        onSelected: _ctx.selectedCapacity,
        zIndex: 1
      }, null, 8, ["category", "onSelected"]),
      (_ctx.errors.capacity)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.errors.capacity), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_22)),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", {
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.nextStep && _ctx.nextStep(...args))),
          class: _normalizeClass(["nav-button-control pointer", {
              disabled:
                _ctx.guests === 0,
            }])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('BUTTON_NEXT')), 1)
        ], 2)
      ])
    ])
  ]))
}