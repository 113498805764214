<template>
    <div class="booking-view">
        <div class="header shadow">
            <div class="header-wrapper">
                <div class="col">
                    <div>
                        <a :href="redirectUrl">
                            <span style="color: var(--primary-admin-text)" class="material-symbols-outlined pointer">keyboard_backspace</span>
                        </a>
                    </div>
                </div>
                <div class="col-center">
                    <div class="bbq-image">
                        <img v-if="region" :src="require(`@/assets/images/bbq-${region?.toLowerCase()}.png`)" alt="bbq-betuwe"/>
                        <img v-else src="@/assets/images/bbq-betuwe.png" alt="bbq-betuwe"/>
                    </div>
                </div>
                <div class="col">
                    <language-dropdown/>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="progress-bar-wrapper" style="z-index: 2">
                <progress-bar
                    :stepIndex="currentStep"
                    @setStep="setStep"
                />
            </div>
            <div class="content-wrapper fade-in">
                <div style="width: 100%; overflow-y: auto; scrollbar-gutter: stable both-edges" v-show="currentStep === 0">
                    <frontend-date-picker
                        @startReservation="startReservation"
                        @nextStep="nextStep"
                        @prevStep="prevStep"
                        :category="selectedBoatCategory"
                        :additional-fields="true"
                    />
                </div>
                <div style="width: 100%;" v-show="currentStep === 1">
                    <arrangement-selector
                        @setArrangements="setArrangements"
                        @prevStep="prevStep"
                        @arrangementChange="arrangementChange"
                        :guest-amount="guests"
                        :date="selectedDate"
                    />
                </div>
                <div style="width: 100%;" v-show="currentStep === 2">
                    <drink-selector
                        @setDrinks="setDrinks"
                        @prevStep="prevStep"
                        :amount-of-drinks="amountOfDrinks"
                    />
                </div>
                <div style="width: 100%;" v-show="currentStep === 3">
                    <extra-selector
                        :date="selectedDate"
                        @setExtras="setExtras"
                        @prevStep="prevStep"
                    />
                </div>
                <div :style="{display: 'flex', justifyContent: 'center', width: '100%'}" class="fade-in" v-show="currentStep === 4">
                    <payment-details
                        @prevStep="prevStep"
                        :date="selectedDate"
                        :timeslot="selectedSlot"
                        :customer="customer"
                        :boatCategory="selectedBoatCategory"
                        :arrangements="selectedArrangements"
                        :drinks="selectedDrinks"
                        :extraDishes="extraDishes"
                        :extraDrinks="extraDrinks"
                        :amountOfDrinks="amountOfDrinks"
                        :guests="guests"
                        :require-details="requireDetails"
                    />
                </div>
            </div>
            <div class="background-overlay">
                <span class="material-symbols-outlined grill-icon">outdoor_grill</span>
                <span class="material-symbols-outlined kebab-icon">kebab_dining</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

/** Components */
import { defineComponent } from "vue";
import FrontendDatePicker from "../partials/custom-fields/FrontendDatePicker.vue";
import ProgressBar from "../partials/custom-fields/ProgressBar.vue";
import BookingDetails from "../frontend/booking-process/BookingDetails.vue";
import ArrangementSelector from '@/components/backend/PartialViews/ArrangementSelector.vue';
import DrinkSelector from '@/components/backend/PartialViews/DrinkSelector.vue';

/** Packages */
/** Models */
import ITimeSlot from "../../interfaces/ITimeSlot";
import ICustomer from '@/interfaces/ICustomer';
import IArrangement from '@/interfaces/IArrangement';
import IDrink from '@/interfaces/IDrink';
import IDish from '@/interfaces/IDish';
import ExtraSelector from '@/components/backend/PartialViews/ExtraSelector.vue';
import PaymentDetails from '@/components/frontend/booking-process/PaymentDetails.vue';
import LanguageDropdown from '@/components/partials/custom-fields/LanguageDropdown.vue';
import http from '@/utils/http-common';


export default defineComponent({
    name: "BookingView",
    // eslint-disable-next-line vue/no-unused-components
    components: {LanguageDropdown, PaymentDetails, ExtraSelector, DrinkSelector, ArrangementSelector, FrontendDatePicker, ProgressBar, BookingDetails},
    data() {
        return {
            selectedDate: '' as string,
            selectedSlot: {} as ITimeSlot,
            customer: {} as ICustomer,
            selectedBoatCategory: '' as string,
            guests: 0 as number,
            currentStep: 0 as number,
            selectedArrangements: [] as IArrangement[],
            selectedDrinks: [] as IDrink[],
            extraDrinks: [] as IDrink[],
            extraDishes: [] as IDish[],
            amountOfDrinks: null as unknown as number,
            region: null as null | string,
            redirectUrl: null as null | string,
            requireDetails: null as null | string,
        }
    },
    methods: {
        startReservation(slot: ITimeSlot, date: string, customer: ICustomer, selectedBoatCategory: string, guests: number): void {
            this.selectedDate = date;
            this.selectedSlot = slot;
            this.customer             = customer;
            this.selectedBoatCategory = selectedBoatCategory;
            this.guests               = guests;
            this.currentStep++;
        },
        setDetails(customer: ICustomer, selectedBoatCategory: string, guests: number): void {
            this.customer             = customer;
            this.selectedBoatCategory = selectedBoatCategory;
            this.guests               = guests;
            this.currentStep++;
        },
        setArrangements(arrangements: IArrangement[]): void {
            this.selectedArrangements = arrangements;
            this.calculateAmountOfDrinks();
            this.currentStep++;
        },
        setDrinks(drinks: IDrink[]): void {
            this.selectedDrinks = drinks;
            this.currentStep++;
        },
        calculateAmountOfDrinks(): void {
            const amount: number = this.selectedArrangements.reduce(
                (sum: number, arrangement: IArrangement) => {
                    return sum + (arrangement.drink_amount || 0);
                },
                0
            );

            if (this.selectedArrangements.length === this.guests) {
                this.amountOfDrinks = amount;
            }
        },
        setStep(index: number): void {
            this.currentStep = index;
        },
        setExtras(drinks: IDrink[], dishes: IDish[]): void {
            this.extraDrinks = drinks;
            this.extraDishes = dishes;
            this.currentStep++;

        },
        nextStep(): void {
            this.currentStep++;
        },
        prevStep(): void {
            this.currentStep--;
        },
        setCategory(category: string): void {
            this.selectedBoatCategory = category;
        },
        setCapacity(guests: number ): void {
            this.guests = guests;
        },
        arrangementChange(arrangements: IArrangement[]) {
            this.selectedArrangements = arrangements;
            this.calculateAmountOfDrinks();
        }
    },
    mounted() {
        http.get('settings/key/REGION_SETTINGS').then((res) => {
            this.region = res.data.value;
        })

        http.get('settings/key/CURRENCY_SYMBOL_SETTINGS').then((res) => {
            localStorage.setItem('currency_symbol', res.data.value);
        })

        http.get('settings/key/REDIRECT_URL').then((res) => {
            this.redirectUrl = res.data.value;
        })

        http.get('settings/key/APP_REQUIRE_INFORMATION_DETAILS').then((res) => {
            this.requireDetails = res.data.value;
        })
    }
});
</script>

<style scoped>
.booking-view {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    -webkit-tap-highlight-color: transparent;
}

.header {
    width: 100%;
    border-bottom: 1px solid lightgray;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    min-height: 70px;
}

.col-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2; /* Give more space to the center column */
}

.bbq-image img {
    max-width: 80%;
    height: auto;
    object-fit: contain; /* Ensures the image scales properly */
}

.bbq-image {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 7.5em;
    width: auto;
    border: none;
}

.background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
}

.col {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.progress-bar-wrapper {
    padding: 20px 20px 0 20px;
}

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.content-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 10px;
    height: 100%;
    overflow: hidden;
    z-index: 2;
}

.background-overlay {
    z-index: 0;
}

.grill-icon {
    position: absolute;
    color: rgba(242, 104, 41, 0.1);
    font-size: 150px;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    transform: rotate(-20deg);
}

.kebab-icon {
    position: absolute;
    color: rgba(242, 104, 41, 0.1);
    font-size: 120px;
    top: 20%;
    left: 20px;
    z-index: 1;
    transform: rotate(20deg);
}

@media (max-width: 768px) {
    .bbq-image img {
        width: 70%; /* Scale down the image on smaller screens */
    }
}

@media (max-width: 480px) {
    .bbq-image img {
        width: 60%; /* Further scale down the image on very small screens */
        min-width: 150px
    }
}

</style>
