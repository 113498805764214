import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "extra-selector" }
const _hoisted_2 = { class: "extra-selector-header" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "candal-regular text" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  id: "extra-indicator",
  class: "cart-indicator"
}
const _hoisted_7 = { class: "sub-text" }
const _hoisted_8 = {
  key: 0,
  class: "shopping-cart-content"
}
const _hoisted_9 = { class: "shopping-cart-header" }
const _hoisted_10 = { class: "candal-regular text" }
const _hoisted_11 = { class: "shopping-cart-body" }
const _hoisted_12 = { class: "cart-category-separator" }
const _hoisted_13 = { class: "text f-bold" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "text" }
const _hoisted_16 = {
  key: 1,
  style: { width: '100%' }
}
const _hoisted_17 = { style: { width: '100%' } }
const _hoisted_18 = { style: { width: '60%' } }
const _hoisted_19 = { style: { width: '15%' } }
const _hoisted_20 = { style: { width: '25%', textAlign: 'right' } }
const _hoisted_21 = { class: "cart-category-separator" }
const _hoisted_22 = { class: "text f-bold" }
const _hoisted_23 = { key: 2 }
const _hoisted_24 = { class: "text" }
const _hoisted_25 = {
  key: 3,
  style: { width: '100%' }
}
const _hoisted_26 = { style: { width: '100%' } }
const _hoisted_27 = { style: { width: '60%' } }
const _hoisted_28 = { style: { width: '15%' } }
const _hoisted_29 = { style: { width: '25%', textAlign: 'right' } }
const _hoisted_30 = { class: "shopping-cart-footer" }
const _hoisted_31 = { class: "candal-regular text" }
const _hoisted_32 = { class: "extra-list-wrapper" }
const _hoisted_33 = { class: "extra-list fade-in" }
const _hoisted_34 = {
  key: 0,
  class: "category-separator"
}
const _hoisted_35 = { class: "category-separator-title" }
const _hoisted_36 = { class: "candal-regular text" }
const _hoisted_37 = ["id"]
const _hoisted_38 = {
  key: 0,
  class: "ribbon ribbon-top-left"
}
const _hoisted_39 = { class: "object-image-wrapper" }
const _hoisted_40 = { class: "object-image" }
const _hoisted_41 = ["src"]
const _hoisted_42 = { class: "object-header" }
const _hoisted_43 = { style: {"text-align":"center"} }
const _hoisted_44 = { style: {"color":"#f2682c","font-weight":"bold"} }
const _hoisted_45 = { class: "object-footer" }
const _hoisted_46 = { class: "object-buttons" }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = { class: "object-counter" }
const _hoisted_49 = { class: "text" }
const _hoisted_50 = ["onClick"]
const _hoisted_51 = ["id"]
const _hoisted_52 = {
  key: 0,
  class: "ribbon ribbon-top-left"
}
const _hoisted_53 = { class: "object-image-wrapper" }
const _hoisted_54 = { class: "object-image" }
const _hoisted_55 = ["src"]
const _hoisted_56 = { class: "object-header" }
const _hoisted_57 = { class: "bold orange" }
const _hoisted_58 = { class: "object-footer" }
const _hoisted_59 = { class: "object-buttons" }
const _hoisted_60 = ["onClick"]
const _hoisted_61 = { class: "object-counter" }
const _hoisted_62 = { class: "text" }
const _hoisted_63 = ["onClick"]
const _hoisted_64 = {
  key: 1,
  class: "overlay-wrapper"
}
const _hoisted_65 = { class: "overlay" }
const _hoisted_66 = { class: "category-separator" }
const _hoisted_67 = { class: "category-separator-title" }
const _hoisted_68 = { class: "candal-regular text" }
const _hoisted_69 = ["id"]
const _hoisted_70 = {
  key: 0,
  class: "ribbon ribbon-top-left"
}
const _hoisted_71 = { class: "object-image-wrapper" }
const _hoisted_72 = { class: "object-image" }
const _hoisted_73 = ["src"]
const _hoisted_74 = { class: "object-header" }
const _hoisted_75 = { style: {"text-align":"center"} }
const _hoisted_76 = { style: {"color":"#f2682c","font-weight":"bold"} }
const _hoisted_77 = { class: "object-footer" }
const _hoisted_78 = { class: "object-buttons" }
const _hoisted_79 = ["onClick"]
const _hoisted_80 = { class: "object-counter" }
const _hoisted_81 = { class: "text" }
const _hoisted_82 = ["onClick"]
const _hoisted_83 = { class: "category-separator" }
const _hoisted_84 = { class: "category-separator-title" }
const _hoisted_85 = { class: "candal-regular text" }
const _hoisted_86 = ["id"]
const _hoisted_87 = {
  key: 0,
  class: "ribbon ribbon-top-left"
}
const _hoisted_88 = { class: "object-image-wrapper" }
const _hoisted_89 = { class: "object-image" }
const _hoisted_90 = ["src"]
const _hoisted_91 = { class: "object-header" }
const _hoisted_92 = { class: "bold orange" }
const _hoisted_93 = { class: "object-footer" }
const _hoisted_94 = { class: "object-buttons" }
const _hoisted_95 = ["onClick"]
const _hoisted_96 = { class: "object-counter" }
const _hoisted_97 = { class: "text" }
const _hoisted_98 = ["onClick"]
const _hoisted_99 = {
  key: 1,
  class: "overlay-wrapper"
}
const _hoisted_100 = { class: "overlay" }
const _hoisted_101 = { class: "category-separator" }
const _hoisted_102 = { class: "category-separator-title" }
const _hoisted_103 = { class: "candal-regular text" }
const _hoisted_104 = ["id"]
const _hoisted_105 = {
  key: 0,
  class: "ribbon ribbon-top-left"
}
const _hoisted_106 = { class: "object-image-wrapper" }
const _hoisted_107 = { class: "object-image" }
const _hoisted_108 = ["src"]
const _hoisted_109 = { class: "object-header" }
const _hoisted_110 = { class: "bold orange" }
const _hoisted_111 = { class: "object-footer" }
const _hoisted_112 = { class: "object-buttons" }
const _hoisted_113 = ["onClick"]
const _hoisted_114 = { class: "object-counter" }
const _hoisted_115 = { class: "text" }
const _hoisted_116 = ["onClick"]
const _hoisted_117 = {
  key: 1,
  class: "overlay-wrapper"
}
const _hoisted_118 = { class: "overlay" }
const _hoisted_119 = { class: "extra-footer-wrapper" }
const _hoisted_120 = { class: "extra-selector-footer" }
const _hoisted_121 = { class: "candal-regular" }
const _hoisted_122 = { class: "candal-regular" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('EXTRA_SELECTOR_SELECT_DISHES_DRINKS')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "shopping-cart-wrapper",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCart && _ctx.toggleCart(...args)))
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "material-symbols-outlined pointer" }, " shopping_cart ", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(this.selectedExtraDrinks.length + this.selectedExtraDishes.length), 1)
          ]),
          _createVNode(_Transition, {
            "enter-active-class": "fade-in",
            "leave-active-class": "fade-out",
            name: "custom-classes"
          }, {
            default: _withCtx(() => [
              (_ctx.openExtraCart)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('SHOPPING_CART_EXTRAS')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('SHOPPING_CART_DRINKS')), 1)
                      ]),
                      (_ctx.selectedExtraDrinks.length === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('SHOPPING_CART_NO_DRINKS_SELECTED')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createElementVNode("table", _hoisted_17, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentDrinkOverview(), (obj, index) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: index,
                                  class: "shopping-cart-item"
                                }, [
                                  _createElementVNode("td", _hoisted_18, _toDisplayString(obj.drink), 1),
                                  _createElementVNode("td", _hoisted_19, _toDisplayString(`${obj.amount}x`), 1),
                                  _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.formattedPrice(obj.price)), 1)
                                ]))
                              }), 128))
                            ])
                          ])),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('SHOPPING_CART_DISHES')), 1)
                      ]),
                      (_ctx.selectedExtraDishes.length === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('SHOPPING_CART_NO_DISHES_SELECTED')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createElementVNode("table", _hoisted_26, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentDishesOverview(), (obj, index) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: index,
                                  class: "shopping-cart-item"
                                }, [
                                  _createElementVNode("td", _hoisted_27, _toDisplayString(obj.dish), 1),
                                  _createElementVNode("td", _hoisted_28, _toDisplayString(`${obj.amount}x`), 1),
                                  _createElementVNode("td", _hoisted_29, _toDisplayString(_ctx.formattedPrice(obj.price)), 1)
                                ]))
                              }), 128))
                            ])
                          ]))
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("span", _hoisted_31, _toDisplayString(`${_ctx.$t('SHOPPING_CART_TOTAL')} ${_ctx.formattedPrice(_ctx.totalCosts)}`), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_32, [
      _createElementVNode("div", _hoisted_33, [
        (_ctx.getPopularDishes().length !== 0 || _ctx.getPopularDrinks().length !== 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t('POPULAR')), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPopularDrinks(), (drink) => {
          return (_openBlock(), _createElementBlock("div", {
            id: 'extra-drink-' + drink.id,
            key: 'extra-drink-' + drink.id,
            class: "object-card shadow grow"
          }, [
            (drink.is_popular)
              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('POPULAR')), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("img", {
                  src: drink.media_library.original_url,
                  alt: "Image"
                }, null, 8, _hoisted_41)
              ])
            ]),
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("span", _hoisted_43, _toDisplayString(`${drink.name}`), 1),
              _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.formattedPrice(drink.price)), 1),
              _createElementVNode("span", null, _toDisplayString(`${drink.alcohol_percentage ? drink.alcohol_percentage + '%' : ''}`), 1)
            ]),
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", {
                  class: "count-button",
                  onClick: 
                                    (event) => {
                                        _ctx.removeDrink(drink)
                                        _ctx.animateButton(event)
                                    }
                                
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " remove ", -1)
                ]), 8, _hoisted_47),
                _createElementVNode("div", _hoisted_48, [
                  _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.countDrink(drink.id)), 1)
                ]),
                _createElementVNode("div", {
                  class: "count-button",
                  onClick: 
                                    (event) => {
                                        _ctx.addDrink(drink)
                                        _ctx.animateButton(event)
                                    }
                                
                }, _cache[6] || (_cache[6] = [
                  _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " add ", -1)
                ]), 8, _hoisted_50)
              ])
            ])
          ], 8, _hoisted_37))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPopularDishes(), (dish) => {
          return (_openBlock(), _createElementBlock("div", {
            id: 'extra-dish-' + dish.id,
            key: 'extra-dish-' + dish.id,
            class: _normalizeClass([[dish.is_order_before ? 'order-before' : ''], "object-card shadow grow"])
          }, [
            (dish.is_popular)
              ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('POPULAR')), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass([[_ctx.checkOrderBefore(dish) ? 'card-disabled' : ''], "object-wrapper"])
            }, [
              _createElementVNode("div", _hoisted_53, [
                _createElementVNode("div", _hoisted_54, [
                  _createElementVNode("img", {
                    src: dish.media_library.original_url,
                    alt: "Image"
                  }, null, 8, _hoisted_55)
                ])
              ]),
              _createElementVNode("div", _hoisted_56, [
                _createElementVNode("span", null, _toDisplayString(`${dish.name}`), 1),
                _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.formattedPrice(dish.price)), 1)
              ]),
              _createElementVNode("div", _hoisted_58, [
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("div", {
                    class: "count-button",
                    onClick: 
                                        (event) => {
                                            _ctx.removeDish(dish)
                                            _ctx.animateButton(event)
                                        }
                                    
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " remove ", -1)
                  ]), 8, _hoisted_60),
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.countDish(dish.id)), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "count-button",
                    onClick: 
                                        (event) => {
                                            _ctx.addDish(dish)
                                            _ctx.animateButton(event)
                                        }
                                    
                  }, _cache[8] || (_cache[8] = [
                    _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, "add", -1)
                  ]), 8, _hoisted_63)
                ])
              ])
            ], 2),
            (_ctx.checkOrderBefore(dish))
              ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                  _createElementVNode("div", _hoisted_65, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('EXTRA_SELECTOR_ORDER_BEFORE', { time: dish.order_before_time })), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 10, _hoisted_51))
        }), 128)),
        _createElementVNode("div", _hoisted_66, [
          _createElementVNode("div", _hoisted_67, [
            _createElementVNode("span", _hoisted_68, _toDisplayString(_ctx.$t('EXTRA_SELECTOR_DRINKS')), 1)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraDrinksList, (drink) => {
          return (_openBlock(), _createElementBlock("div", {
            id: 'extra-drink-' + drink.id,
            key: 'extra-drink-' + drink.id,
            class: "object-card shadow grow"
          }, [
            (drink.is_popular)
              ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('POPULAR')), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_71, [
              _createElementVNode("div", _hoisted_72, [
                _createElementVNode("img", {
                  src: drink.media_library.original_url,
                  alt: "Image"
                }, null, 8, _hoisted_73)
              ])
            ]),
            _createElementVNode("div", _hoisted_74, [
              _createElementVNode("span", _hoisted_75, _toDisplayString(`${drink.name}`), 1),
              _createElementVNode("span", _hoisted_76, _toDisplayString(_ctx.formattedPrice(drink.price)), 1),
              _createElementVNode("span", null, _toDisplayString(`${drink.alcohol_percentage ? drink.alcohol_percentage + '%' : ''}`), 1)
            ]),
            _createElementVNode("div", _hoisted_77, [
              _createElementVNode("div", _hoisted_78, [
                _createElementVNode("div", {
                  class: "count-button",
                  onClick: 
                                    (event) => {
                                        _ctx.removeDrink(drink)
                                        _ctx.animateButton(event)
                                    }
                                
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " remove ", -1)
                ]), 8, _hoisted_79),
                _createElementVNode("div", _hoisted_80, [
                  _createElementVNode("span", _hoisted_81, _toDisplayString(_ctx.countDrink(drink.id)), 1)
                ]),
                _createElementVNode("div", {
                  class: "count-button",
                  onClick: 
                                    (event) => {
                                        _ctx.addDrink(drink)
                                        _ctx.animateButton(event)
                                    }
                                
                }, _cache[10] || (_cache[10] = [
                  _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " add ", -1)
                ]), 8, _hoisted_82)
              ])
            ])
          ], 8, _hoisted_69))
        }), 128)),
        _createElementVNode("div", _hoisted_83, [
          _createElementVNode("div", _hoisted_84, [
            _createElementVNode("span", _hoisted_85, _toDisplayString(_ctx.$t('EXTRA_SELECTOR_DISHES')), 1)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterStandardDishes(false), (dish) => {
          return (_openBlock(), _createElementBlock("div", {
            id: 'extra-dish-' + dish.id,
            key: 'extra-dish-' + dish.id,
            class: _normalizeClass([[dish.is_order_before ? 'order-before' : ''], "object-card shadow grow"])
          }, [
            (dish.is_popular)
              ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('POPULAR')), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass([[_ctx.checkOrderBefore(dish) ? 'card-disabled' : ''], "object-wrapper"])
            }, [
              _createElementVNode("div", _hoisted_88, [
                _createElementVNode("div", _hoisted_89, [
                  _createElementVNode("img", {
                    src: dish.media_library.original_url,
                    alt: "Image"
                  }, null, 8, _hoisted_90)
                ])
              ]),
              _createElementVNode("div", _hoisted_91, [
                _createElementVNode("span", null, _toDisplayString(`${dish.name}`), 1),
                _createElementVNode("span", _hoisted_92, _toDisplayString(_ctx.formattedPrice(dish.price)), 1)
              ]),
              _createElementVNode("div", _hoisted_93, [
                _createElementVNode("div", _hoisted_94, [
                  _createElementVNode("div", {
                    class: "count-button",
                    onClick: 
                                        (event) => {
                                            _ctx.removeDish(dish)
                                            _ctx.animateButton(event)
                                        }
                                    
                  }, _cache[11] || (_cache[11] = [
                    _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " remove ", -1)
                  ]), 8, _hoisted_95),
                  _createElementVNode("div", _hoisted_96, [
                    _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.countDish(dish.id)), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "count-button",
                    onClick: 
                                        (event) => {
                                            _ctx.addDish(dish)
                                            _ctx.animateButton(event)
                                        }
                                    
                  }, _cache[12] || (_cache[12] = [
                    _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, "add", -1)
                  ]), 8, _hoisted_98)
                ])
              ])
            ], 2),
            (_ctx.checkOrderBefore(dish))
              ? (_openBlock(), _createElementBlock("div", _hoisted_99, [
                  _createElementVNode("div", _hoisted_100, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('EXTRA_SELECTOR_ORDER_BEFORE', { time: dish.order_before_time })), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 10, _hoisted_86))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedSpecialDishes(), (group) => {
          return (_openBlock(), _createElementBlock("div", {
            key: group.category,
            class: "extra-list fade",
            style: {"height":"unset"}
          }, [
            _createElementVNode("div", _hoisted_101, [
              _createElementVNode("div", _hoisted_102, [
                _createElementVNode("span", _hoisted_103, _toDisplayString(group.category), 1)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.dishes, (dish) => {
              return (_openBlock(), _createElementBlock("div", {
                id: 'extra-dish-' + dish.id,
                key: 'extra-dish-' + dish.id,
                class: _normalizeClass([[dish.is_order_before ? 'order-before' : ''], "object-card shadow grow"])
              }, [
                (dish.is_popular)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_105, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('POPULAR')), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.checkOrderBefore(dish) ? 'card-disabled' : ''], "object-wrapper"])
                }, [
                  _createElementVNode("div", _hoisted_106, [
                    _createElementVNode("div", _hoisted_107, [
                      _createElementVNode("img", {
                        src: dish.media_library.original_url,
                        alt: "Image"
                      }, null, 8, _hoisted_108)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_109, [
                    _createElementVNode("span", null, _toDisplayString(`${dish.name}`), 1),
                    _createElementVNode("span", _hoisted_110, _toDisplayString(_ctx.formattedPrice(dish.price)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_111, [
                    _createElementVNode("div", _hoisted_112, [
                      _createElementVNode("div", {
                        class: "count-button",
                        onClick: 
                                            (event) => {
                                                _ctx.removeDish(dish)
                                                _ctx.animateButton(event)
                                            }
                                        
                      }, _cache[13] || (_cache[13] = [
                        _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " remove ", -1)
                      ]), 8, _hoisted_113),
                      _createElementVNode("div", _hoisted_114, [
                        _createElementVNode("span", _hoisted_115, _toDisplayString(_ctx.countDish(dish.id)), 1)
                      ]),
                      _createElementVNode("div", {
                        class: "count-button",
                        onClick: 
                                            (event) => {
                                                _ctx.addDish(dish)
                                                _ctx.animateButton(event)
                                            }
                                        
                      }, _cache[14] || (_cache[14] = [
                        _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, "add", -1)
                      ]), 8, _hoisted_116)
                    ])
                  ])
                ], 2),
                (_ctx.checkOrderBefore(dish))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_117, [
                      _createElementVNode("div", _hoisted_118, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('EXTRA_SELECTOR_ORDER_BEFORE', { time: dish.order_before_time })), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_104))
            }), 128))
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_119, [
          _createElementVNode("div", _hoisted_120, [
            _createElementVNode("div", {
              id: "prev_extra_button",
              class: "nav-button-control pointer text",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevStep && _ctx.prevStep(...args)))
            }, [
              _createElementVNode("span", _hoisted_121, _toDisplayString(_ctx.$t('BUTTON_BACK')), 1)
            ]),
            _createElementVNode("div", {
              id: "next_extra_button",
              class: "nav-button-control pointer text",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setExtras && _ctx.setExtras(...args)))
            }, [
              _createElementVNode("span", _hoisted_122, _toDisplayString(_ctx.$t('BUTTON_NEXT')), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}