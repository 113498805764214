import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/bbq-betuwe.png'


const _hoisted_1 = { class: "booking-view" }
const _hoisted_2 = { class: "header shadow" }
const _hoisted_3 = { class: "header-wrapper" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "col-center" }
const _hoisted_7 = { class: "bbq-image" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  src: _imports_0,
  alt: "bbq-betuwe"
}
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "content" }
const _hoisted_12 = {
  class: "progress-bar-wrapper",
  style: {"z-index":"2"}
}
const _hoisted_13 = { class: "content-wrapper fade-in" }
const _hoisted_14 = { style: {"width":"100%","overflow-y":"auto","scrollbar-gutter":"stable both-edges"} }
const _hoisted_15 = { style: {"width":"100%"} }
const _hoisted_16 = { style: {"width":"100%"} }
const _hoisted_17 = { style: {"width":"100%"} }
const _hoisted_18 = {
  style: {display: 'flex', justifyContent: 'center', width: '100%'},
  class: "fade-in"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_language_dropdown = _resolveComponent("language-dropdown")!
  const _component_progress_bar = _resolveComponent("progress-bar")!
  const _component_frontend_date_picker = _resolveComponent("frontend-date-picker")!
  const _component_arrangement_selector = _resolveComponent("arrangement-selector")!
  const _component_drink_selector = _resolveComponent("drink-selector")!
  const _component_extra_selector = _resolveComponent("extra-selector")!
  const _component_payment_details = _resolveComponent("payment-details")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("a", { href: _ctx.redirectUrl }, _cache[0] || (_cache[0] = [
              _createElementVNode("span", {
                style: {"color":"var(--primary-admin-text)"},
                class: "material-symbols-outlined pointer"
              }, "keyboard_backspace", -1)
            ]), 8, _hoisted_5)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.region)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: require(`@/assets/images/bbq-${_ctx.region?.toLowerCase()}.png`),
                  alt: "bbq-betuwe"
                }, null, 8, _hoisted_8))
              : (_openBlock(), _createElementBlock("img", _hoisted_9))
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_language_dropdown)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_progress_bar, {
          stepIndex: _ctx.currentStep,
          onSetStep: _ctx.setStep
        }, null, 8, ["stepIndex", "onSetStep"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _withDirectives(_createElementVNode("div", _hoisted_14, [
          _createVNode(_component_frontend_date_picker, {
            onStartReservation: _ctx.startReservation,
            onNextStep: _ctx.nextStep,
            onPrevStep: _ctx.prevStep,
            category: _ctx.selectedBoatCategory,
            "additional-fields": true
          }, null, 8, ["onStartReservation", "onNextStep", "onPrevStep", "category"])
        ], 512), [
          [_vShow, _ctx.currentStep === 0]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_15, [
          _createVNode(_component_arrangement_selector, {
            onSetArrangements: _ctx.setArrangements,
            onPrevStep: _ctx.prevStep,
            onArrangementChange: _ctx.arrangementChange,
            "guest-amount": _ctx.guests,
            date: _ctx.selectedDate
          }, null, 8, ["onSetArrangements", "onPrevStep", "onArrangementChange", "guest-amount", "date"])
        ], 512), [
          [_vShow, _ctx.currentStep === 1]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_16, [
          _createVNode(_component_drink_selector, {
            onSetDrinks: _ctx.setDrinks,
            onPrevStep: _ctx.prevStep,
            "amount-of-drinks": _ctx.amountOfDrinks
          }, null, 8, ["onSetDrinks", "onPrevStep", "amount-of-drinks"])
        ], 512), [
          [_vShow, _ctx.currentStep === 2]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_17, [
          _createVNode(_component_extra_selector, {
            date: _ctx.selectedDate,
            onSetExtras: _ctx.setExtras,
            onPrevStep: _ctx.prevStep
          }, null, 8, ["date", "onSetExtras", "onPrevStep"])
        ], 512), [
          [_vShow, _ctx.currentStep === 3]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_18, [
          _createVNode(_component_payment_details, {
            onPrevStep: _ctx.prevStep,
            date: _ctx.selectedDate,
            timeslot: _ctx.selectedSlot,
            customer: _ctx.customer,
            boatCategory: _ctx.selectedBoatCategory,
            arrangements: _ctx.selectedArrangements,
            drinks: _ctx.selectedDrinks,
            extraDishes: _ctx.extraDishes,
            extraDrinks: _ctx.extraDrinks,
            amountOfDrinks: _ctx.amountOfDrinks,
            guests: _ctx.guests,
            "require-details": _ctx.requireDetails
          }, null, 8, ["onPrevStep", "date", "timeslot", "customer", "boatCategory", "arrangements", "drinks", "extraDishes", "extraDrinks", "amountOfDrinks", "guests", "require-details"])
        ], 512), [
          [_vShow, _ctx.currentStep === 4]
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "background-overlay" }, [
        _createElementVNode("span", { class: "material-symbols-outlined grill-icon" }, "outdoor_grill"),
        _createElementVNode("span", { class: "material-symbols-outlined kebab-icon" }, "kebab_dining")
      ], -1))
    ])
  ]))
}