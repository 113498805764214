<template>
    <div class="extra-selector">
        <div class="extra-selector-header">
            <div class="col"></div>
            <div class="col">
                <div>
                    <span class="candal-regular text"> {{ $t('EXTRA_SELECTOR_SELECT_DISHES_DRINKS') }} </span>
                </div>
            </div>
            <div class="col">
                <div class="shopping-cart-wrapper" @click="toggleCart">
                    <span class="material-symbols-outlined pointer"> shopping_cart </span>
                    <div id="extra-indicator" class="cart-indicator">
                        <span class="sub-text">
                            {{ this.selectedExtraDrinks.length + this.selectedExtraDishes.length }}
                        </span>
                    </div>
                    <Transition enter-active-class="fade-in" leave-active-class="fade-out" name="custom-classes">
                        <div v-if="openExtraCart" class="shopping-cart-content">
                            <div class="shopping-cart-header">
                                <span class="candal-regular text"> {{ $t('SHOPPING_CART_EXTRAS') }}</span>
                            </div>
                            <div class="shopping-cart-body">
                                <div class="cart-category-separator">
                                    <span class="text f-bold"> {{ $t('SHOPPING_CART_DRINKS') }} </span>
                                </div>
                                <div v-if="selectedExtraDrinks.length === 0">
                                    <span class="text"> {{ $t('SHOPPING_CART_NO_DRINKS_SELECTED') }} </span>
                                </div>
                                <div v-else :style="{ width: '100%' }">
                                    <table :style="{ width: '100%' }">
                                        <tr
                                            v-for="(obj, index) in currentDrinkOverview()"
                                            :key="index"
                                            class="shopping-cart-item"
                                        >
                                            <td :style="{ width: '60%' }">{{ obj.drink }}</td>
                                            <td :style="{ width: '15%' }">{{ `${obj.amount}x` }}</td>
                                            <td :style="{ width: '25%', textAlign: 'right' }">
                                                {{ formattedPrice(obj.price) }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="cart-category-separator">
                                    <span class="text f-bold"> {{ $t('SHOPPING_CART_DISHES') }} </span>
                                </div>
                                <div v-if="selectedExtraDishes.length === 0">
                                    <span class="text"> {{ $t('SHOPPING_CART_NO_DISHES_SELECTED') }} </span>
                                </div>
                                <div v-else :style="{ width: '100%' }">
                                    <table :style="{ width: '100%' }">
                                        <tr
                                            v-for="(obj, index) in currentDishesOverview()"
                                            :key="index"
                                            class="shopping-cart-item"
                                        >
                                            <td :style="{ width: '60%' }">{{ obj.dish }}</td>
                                            <td :style="{ width: '15%' }">{{ `${obj.amount}x` }}</td>
                                            <td :style="{ width: '25%', textAlign: 'right' }">
                                                {{ formattedPrice(obj.price) }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="shopping-cart-footer">
                                <span class="candal-regular text">
                                    {{ `${$t('SHOPPING_CART_TOTAL')} ${formattedPrice(totalCosts)}` }}</span
                                >
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
        <div class="extra-list-wrapper">
            <div class="extra-list fade-in">
                <div
                    v-if="getPopularDishes().length !== 0 || getPopularDrinks().length !== 0"
                    class="category-separator"
                >
                    <div class="category-separator-title">
                        <span class="candal-regular text"> {{ $t('POPULAR') }} </span>
                    </div>
                </div>
                <div
                    v-for="drink in getPopularDrinks()"
                    :id="'extra-drink-' + drink.id"
                    :key="'extra-drink-' + drink.id"
                    class="object-card shadow grow"
                >
                    <div v-if="drink.is_popular" class="ribbon ribbon-top-left">
                        <span> {{ $t('POPULAR') }} </span>
                    </div>
                    <div class="object-image-wrapper">
                        <div class="object-image">
                            <img :src="drink.media_library.original_url" alt="Image" />
                        </div>
                    </div>
                    <div class="object-header">
                        <span style="text-align: center"> {{ `${drink.name}` }}</span>
                        <span style="color: #f2682c; font-weight: bold"> {{ formattedPrice(drink.price) }}</span>
                        <span> {{ `${drink.alcohol_percentage ? drink.alcohol_percentage + '%' : ''}` }}</span>
                    </div>
                    <div class="object-footer">
                        <div class="object-buttons">
                            <div
                                class="count-button"
                                @click="
                                    (event) => {
                                        removeDrink(drink)
                                        animateButton(event)
                                    }
                                "
                            >
                                <span class="material-symbols-outlined symbol-size pointer"> remove </span>
                            </div>
                            <div class="object-counter">
                                <span class="text"> {{ countDrink(drink.id) }}</span>
                            </div>
                            <div
                                class="count-button"
                                @click="
                                    (event) => {
                                        addDrink(drink)
                                        animateButton(event)
                                    }
                                "
                            >
                                <span class="material-symbols-outlined symbol-size pointer"> add </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-for="dish in getPopularDishes()"
                    :id="'extra-dish-' + dish.id"
                    :key="'extra-dish-' + dish.id"
                    :class="[dish.is_order_before ? 'order-before' : '']"
                    class="object-card shadow grow"
                >
                    <div v-if="dish.is_popular" class="ribbon ribbon-top-left">
                        <span> {{ $t('POPULAR') }} </span>
                    </div>
                    <div :class="[checkOrderBefore(dish) ? 'card-disabled' : '']" class="object-wrapper">
                        <div class="object-image-wrapper">
                            <div class="object-image">
                                <img :src="dish.media_library.original_url" alt="Image" />
                            </div>
                        </div>
                        <div class="object-header">
                            <span> {{ `${dish.name}` }}</span>
                            <span class="bold orange"> {{ formattedPrice(dish.price) }} </span>
                        </div>
                        <div class="object-footer">
                            <div class="object-buttons">
                                <div
                                    class="count-button"
                                    @click="
                                        (event) => {
                                            removeDish(dish)
                                            animateButton(event)
                                        }
                                    "
                                >
                                    <span class="material-symbols-outlined symbol-size pointer"> remove </span>
                                </div>
                                <div class="object-counter">
                                    <span class="text"> {{ countDish(dish.id) }}</span>
                                </div>
                                <div
                                    class="count-button"
                                    @click="
                                        (event) => {
                                            addDish(dish)
                                            animateButton(event)
                                        }
                                    "
                                >
                                    <span class="material-symbols-outlined symbol-size pointer">add</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkOrderBefore(dish)" class="overlay-wrapper">
                        <div class="overlay">
                            <span> {{ $t('EXTRA_SELECTOR_ORDER_BEFORE', { time: dish.order_before_time }) }} </span>
                        </div>
                    </div>
                </div>

                <div class="category-separator">
                    <div class="category-separator-title">
                        <span class="candal-regular text"> {{ $t('EXTRA_SELECTOR_DRINKS') }} </span>
                    </div>
                </div>
                <div
                    v-for="drink in extraDrinksList"
                    :id="'extra-drink-' + drink.id"
                    :key="'extra-drink-' + drink.id"
                    class="object-card shadow grow"
                >
                    <div v-if="drink.is_popular" class="ribbon ribbon-top-left">
                        <span> {{ $t('POPULAR') }} </span>
                    </div>
                    <div class="object-image-wrapper">
                        <div class="object-image">
                            <img :src="drink.media_library.original_url" alt="Image" />
                        </div>
                    </div>
                    <div class="object-header">
                        <span style="text-align: center"> {{ `${drink.name}` }}</span>
                        <span style="color: #f2682c; font-weight: bold"> {{ formattedPrice(drink.price) }}</span>
                        <span> {{ `${drink.alcohol_percentage ? drink.alcohol_percentage + '%' : ''}` }}</span>
                    </div>
                    <div class="object-footer">
                        <div class="object-buttons">
                            <div
                                class="count-button"
                                @click="
                                    (event) => {
                                        removeDrink(drink)
                                        animateButton(event)
                                    }
                                "
                            >
                                <span class="material-symbols-outlined symbol-size pointer"> remove </span>
                            </div>
                            <div class="object-counter">
                                <span class="text"> {{ countDrink(drink.id) }}</span>
                            </div>
                            <div
                                class="count-button"
                                @click="
                                    (event) => {
                                        addDrink(drink)
                                        animateButton(event)
                                    }
                                "
                            >
                                <span class="material-symbols-outlined symbol-size pointer"> add </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="category-separator">
                    <div class="category-separator-title">
                        <span class="candal-regular text"> {{ $t('EXTRA_SELECTOR_DISHES') }} </span>
                    </div>
                </div>
                <div
                    v-for="dish in filterStandardDishes(false)"
                    :id="'extra-dish-' + dish.id"
                    :key="'extra-dish-' + dish.id"
                    :class="[dish.is_order_before ? 'order-before' : '']"
                    class="object-card shadow grow"
                >
                    <div v-if="dish.is_popular" class="ribbon ribbon-top-left">
                        <span> {{ $t('POPULAR') }} </span>
                    </div>
                    <div :class="[checkOrderBefore(dish) ? 'card-disabled' : '']" class="object-wrapper">
                        <div class="object-image-wrapper">
                            <div class="object-image">
                                <img :src="dish.media_library.original_url" alt="Image" />
                            </div>
                        </div>
                        <div class="object-header">
                            <span> {{ `${dish.name}` }}</span>
                            <span class="bold orange"> {{ formattedPrice(dish.price) }} </span>
                        </div>
                        <div class="object-footer">
                            <div class="object-buttons">
                                <div
                                    class="count-button"
                                    @click="
                                        (event) => {
                                            removeDish(dish)
                                            animateButton(event)
                                        }
                                    "
                                >
                                    <span class="material-symbols-outlined symbol-size pointer"> remove </span>
                                </div>
                                <div class="object-counter">
                                    <span class="text"> {{ countDish(dish.id) }}</span>
                                </div>
                                <div
                                    class="count-button"
                                    @click="
                                        (event) => {
                                            addDish(dish)
                                            animateButton(event)
                                        }
                                    "
                                >
                                    <span class="material-symbols-outlined symbol-size pointer">add</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkOrderBefore(dish)" class="overlay-wrapper">
                        <div class="overlay">
                            <span> {{ $t('EXTRA_SELECTOR_ORDER_BEFORE', { time: dish.order_before_time }) }} </span>
                        </div>
                    </div>
                </div>
                <div
                    v-for="group in groupedSpecialDishes()"
                    :key="group.category"
                    class="extra-list fade"
                    style="height: unset"
                >
                    <div class="category-separator">
                        <div class="category-separator-title">
                            <span class="candal-regular text"> {{ group.category }} </span>
                        </div>
                    </div>

                    <div
                        v-for="dish in group.dishes"
                        :id="'extra-dish-' + dish.id"
                        :key="'extra-dish-' + dish.id"
                        :class="[dish.is_order_before ? 'order-before' : '']"
                        class="object-card shadow grow"
                    >
                        <div v-if="dish.is_popular" class="ribbon ribbon-top-left">
                            <span> {{ $t('POPULAR') }} </span>
                        </div>
                        <div :class="[checkOrderBefore(dish) ? 'card-disabled' : '']" class="object-wrapper">
                            <div class="object-image-wrapper">
                                <div class="object-image">
                                    <img :src="dish.media_library.original_url" alt="Image" />
                                </div>
                            </div>
                            <div class="object-header">
                                <span> {{ `${dish.name}` }}</span>
                                <span class="bold orange"> {{ formattedPrice(dish.price) }} </span>
                            </div>
                            <div class="object-footer">
                                <div class="object-buttons">
                                    <div
                                        class="count-button"
                                        @click="
                                            (event) => {
                                                removeDish(dish)
                                                animateButton(event)
                                            }
                                        "
                                    >
                                        <span class="material-symbols-outlined symbol-size pointer"> remove </span>
                                    </div>
                                    <div class="object-counter">
                                        <span class="text"> {{ countDish(dish.id) }}</span>
                                    </div>
                                    <div
                                        class="count-button"
                                        @click="
                                            (event) => {
                                                addDish(dish)
                                                animateButton(event)
                                            }
                                        "
                                    >
                                        <span class="material-symbols-outlined symbol-size pointer">add</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="checkOrderBefore(dish)" class="overlay-wrapper">
                            <div class="overlay">
                                <span> {{ $t('EXTRA_SELECTOR_ORDER_BEFORE', { time: dish.order_before_time }) }} </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="extra-footer-wrapper">
                    <div class="extra-selector-footer">
                        <div id="prev_extra_button" class="nav-button-control pointer text" @click="prevStep">
                            <span class="candal-regular"> {{ $t('BUTTON_BACK') }}</span>
                        </div>
                        <div id="next_extra_button" class="nav-button-control pointer text" @click="setExtras">
                            <span class="candal-regular">
                                {{ $t('BUTTON_NEXT') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import IDrink from '@/interfaces/IDrink'
import IDish from '@/interfaces/IDish'
import http from '@/utils/http-common'
import { toast } from 'vue3-toastify'
import dayjs, { Dayjs } from 'dayjs'

export default defineComponent({
    name: 'ExtraSelector',
    data() {
        return {
            extraDrinksList: [] as IDrink[],
            extraDishesList: [] as IDish[],
            selectedExtraDrinks: [] as IDrink[],
            selectedExtraDishes: [] as IDish[],
            openExtraCart: false as boolean,
            totalCosts: 0 as number,
            selectedDate: null as string | null,
            additionalCategories: [] as string[],
        }
    },
    methods: {
        getDrinks() {
            http.get(`drinks`, { params: { pagination: false } })
                .then((res) => {
                    this.extraDrinksList = res.data
                })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                })
        },
        getDishes() {
            http.get(`dishes`, { params: { pagination: false, onlyExtra: true } })
                .then((res) => {
                    const standardCategories = ['Starters', 'Salads', 'Sauces', 'Meats']
                    this.extraDishesList = res.data

                    this.additionalCategories = []
                    res.data.forEach((dish: IDish) => {
                        if (
                            !standardCategories.includes(dish.category) &&
                            !this.additionalCategories.includes(dish.category)
                        ) {
                            this.additionalCategories.push(dish.category)
                        }
                    })
                })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                })
        },
        getPopularDrinks(): IDrink[] {
            return this.extraDrinksList.filter((drink) => drink.is_popular)
        },
        getPopularDishes(): IDish[] {
            const list = this.extraDishesList.filter((dish) => dish.is_popular)
            return this.extraDishesList.filter((dish) => dish.is_popular)
        },
        countDrink(id: number): number {
            return this.selectedExtraDrinks.filter((drink) => drink.id === id).length
        },
        countDish(id: number): number {
            return this.selectedExtraDishes.filter((dish) => dish.id === id).length
        },
        addDrink(drink: IDrink) {
            this.selectedExtraDrinks.push(drink)
            this.animateIndicator()
            this.calculateTotalCost(drink.price, 'increment')
        },
        removeDrink(drink: IDrink) {
            const indexToRemove = this.selectedExtraDrinks.findIndex((item) => item.id === drink.id)

            if (indexToRemove !== -1) {
                this.selectedExtraDrinks.splice(indexToRemove, 1)
                this.animateIndicator()
                this.calculateTotalCost(drink.price, 'subtract')
            }
        },
        addDish(dish: IDish) {
            if (this.checkOrderBefore(dish)) {
                return
            }
            this.selectedExtraDishes.push(dish)
            this.animateIndicator()
            this.calculateTotalCost(dish.price, 'increment')
        },
        removeDish(dish: IDish) {
            const indexToRemove = this.selectedExtraDishes.findIndex((item) => item.id === dish.id)

            if (indexToRemove !== -1) {
                this.selectedExtraDishes.splice(indexToRemove, 1)
                this.animateIndicator()
                this.calculateTotalCost(dish.price, 'subtract')
            }
        },
        filterStandardDishes(onlyAdditional = false): IDish[] {
            const standardCategories = ['Starters', 'Salads', 'Sauces', 'Meats']

            return this.extraDishesList.filter((dish) =>
                onlyAdditional
                    ? !standardCategories.includes(dish.category)
                    : standardCategories.includes(dish.category),
            )
        },
        groupedSpecialDishes(): { category: string; dishes: IDish[] }[] {
            const grouped: { category: string; dishes: IDish[] }[] = []
            const standardCategories = ['Starters', 'Salads', 'Sauces', 'Meat']

            this.extraDishesList.forEach((dish: IDish) => {
                if (!standardCategories.includes(dish.category)) {
                    const existingCategory = grouped.find((group) => group.category === dish.category)
                    if (existingCategory) {
                        existingCategory.dishes.push(dish)
                    } else {
                        grouped.push({
                            category: dish.category,
                            dishes: [dish],
                        })
                    }
                }
            })

            return grouped
        },
        animateButton(event: Event): void {
            const button = event.currentTarget as HTMLElement
            button.classList.remove('grow-shrink')
            void button.offsetWidth
            button.classList.add('grow-shrink')
        },
        animateIndicator(): void {
            const indicator = document.querySelector('#extra-indicator') as HTMLElement

            if (indicator) {
                indicator.classList.remove('grow-shrink')
                void indicator.offsetWidth
                indicator.classList.add('grow-shrink')
            }
        },
        currentDrinkOverview(): { drink: string; amount: number; price: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedExtraDrinks.forEach((drink: { id: number }) => {
                const drinkId = drink.id

                if (ids[drinkId]) {
                    ids[drinkId]++
                } else {
                    ids[drinkId] = 1
                }
            })

            const result = Object.keys(ids).map((drinkId) => {
                const count = ids[drinkId]
                const drink = this.selectedExtraDrinks.find((arr) => arr.id === parseInt(drinkId))
                const drinkName = drink?.name || 'Unknown'
                const drinkPrice = drink?.price || 0
                return { drink: drinkName, amount: count, price: drinkPrice }
            })

            return result
        },
        currentDishesOverview(): { dish: string; amount: number; price: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedExtraDishes.forEach((dish: { id: number }) => {
                const dishId = dish.id

                if (ids[dishId]) {
                    ids[dishId]++
                } else {
                    ids[dishId] = 1
                }
            })

            const result = Object.keys(ids).map((dishId) => {
                const count = ids[dishId]
                const dish = this.selectedExtraDishes.find((arr) => arr.id === parseInt(dishId))
                const dishName = dish?.name || 'Unknown'
                const dishPrice = dish?.price || 0
                return { dish: dishName, amount: count, price: dishPrice }
            })

            return result
        },
        calculateTotalCost(price: number, option: string): void {
            if (option === 'increment') {
                this.totalCosts += Number(price)
            } else {
                this.totalCosts -= Number(price)
            }
        },
        setExtras() {
            const nextButton = document.querySelector('#next_extra_button')
            if (nextButton && nextButton.classList.contains('disabled')) {
                return
            }
            this.openExtraCart = false
            this.$emit('setExtras', this.selectedExtraDrinks, this.selectedExtraDishes)
        },
        prevStep(): void {
            this.openExtraCart = false
            this.$emit('prevStep')
        },
        toggleCart(): void {
            this.openExtraCart = !this.openExtraCart
        },
        formattedPrice(price: number): string {
            let symbols = ['€', '$']

            const item = localStorage.getItem('currency_symbol')
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price}`
                } else {
                    return `${price} ${item}`
                }
            }
            return `${price}`
        },
        checkOrderBefore(dish: IDish): boolean {
            if (!this.selectedDate || !dish.order_before_time) {
                return false
            }

            const currentDate: Dayjs = dayjs()
            const currentDateString: string = currentDate.format('YYYY-MM-DD')
            const reservationDate: Dayjs = dayjs(this.selectedDate)
            const reservationDateString: string = reservationDate.format('YYYY-MM-DD')

            if (reservationDateString === currentDateString) {
                const orderBeforeTime: Dayjs = this.parseTime(dish.order_before_time)
                return currentDate.isAfter(orderBeforeTime)
            }

            return false
        },

        parseTime(time: string): Dayjs {
            const [hours, minutes] = time.split(':').map(Number)
            return dayjs().set('hour', hours).set('minute', minutes).set('second', 0).set('millisecond', 0)
        },
    },
    props: {
        date: {
            type: String,
        },
    },
    watch: {
        date: {
            handler(date: string) {
                if (date) {
                    this.selectedDate = date
                }
            },
        },
    },
    mounted() {
        this.getDrinks()
        this.getDishes()
    },
})
</script>

<style scoped>
.category-separator {
    display: flex;
    width: 100%;
    height: 30px;
    background-color: #f2682c;
    border-radius: 10px;
    color: white;
    align-items: center;
    margin: 0 10px;
}

.category-separator-title {
    display: flex;
    margin: 0 10px;
}

.extra-selector {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.extra-list-wrapper {
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
}

.extra-list {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.object-card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    background-color: white;
    flex-basis: calc(15%);
    min-width: 200px;
    max-width: 200px;
}

.extra-selector-header {
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin: 10px;
    border-bottom: 1px solid #ccc;
}

.extra-selector-footer {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    max-width: 600px;
    width: 100%;
    padding: 0 10px;
}

.col {
    display: flex;
    height: 100%;
}

.extra-footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.card-disabled {
    opacity: 0.4;
    pointer-events: none; /* Prevents interaction */
    position: relative;
}
</style>
