import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "boat-booking-card" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-wrapper"
}
const _hoisted_3 = {
  key: 1,
  class: "boat-booking-card-wrapper"
}
const _hoisted_4 = { class: "booking-card-header" }
const _hoisted_5 = { class: "booking-card-title" }
const _hoisted_6 = { class: "booking-card-subtitle" }
const _hoisted_7 = {
  key: 0,
  class: "booking-card-subtitle"
}
const _hoisted_8 = {
  key: 1,
  class: "booking-card-subtitle"
}
const _hoisted_9 = { class: "booking-card-body" }
const _hoisted_10 = { class: "slot-col" }
const _hoisted_11 = { class: "align-center" }
const _hoisted_12 = { class: "align-center" }
const _hoisted_13 = { class: "bold time" }
const _hoisted_14 = { class: "bold time" }
const _hoisted_15 = { class: "flex-col" }
const _hoisted_16 = { class: "slot-col" }
const _hoisted_17 = { class: "booking-button" }
const _hoisted_18 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_loading_spinner)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('FRONTEND_DATE_PICKER_TITLE', { date: _ctx.convertDate(_ctx.selectedCalendarDay.date) })), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('FRONTEND_DATE_PICKER_SUBTITLE', { category: _ctx.selectedCategory })), 1),
            (_ctx.selectedTimeSlot && _ctx.selectedTimeSlot.start_time && _ctx.selectedTimeSlot.end_time)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('FRONTEND_DATE_PICKER_TIMESLOT', {
                            start: _ctx.timeSlotParser(_ctx.selectedTimeSlot.start_time),
                            end: _ctx.timeSlotParser(_ctx.selectedTimeSlot.end_time),
                        })), 1))
              : _createCommentVNode("", true),
            (_ctx.currentSelectedDate)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('FRONTEND_DATE_PICKER_SELECTED_DATE', { date: _ctx.convertDate(_ctx.currentSelectedDate) })), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeSlots, (slot) => {
              return (_openBlock(), _createElementBlock("div", {
                key: slot.id,
                class: "booking-slot"
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "material-symbols-outlined symbol-size" }, "schedule", -1)),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(`${_ctx.timeSlotParser(slot.start_time)}`), 1),
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "material-symbols-outlined symbol-size" }, "horizontal_rule", -1)),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(`${_ctx.timeSlotParser(slot.end_time)}`), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.distinctCategories(), (boatCategory) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: boatCategory,
                        style: _normalizeStyle({
                                    color:
                                        _ctx.getAmountAvailableBoats(_ctx.parseCategory(boatCategory), slot.id) === 0
                                            ? 'red'
                                            : '',
                                }),
                        class: "sub-text"
                      }, _toDisplayString(`${_ctx.getAmountAvailableBoats(_ctx.parseCategory(boatCategory), slot.id)} ${_ctx.parseCategory(boatCategory)} ${_ctx.$t('FRONTEND_DATE_PICKER_BOATS_AVAILABLE')}`), 5))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("button", {
                      disabled: slot.disabled,
                      class: "booking-button-wrapper text pointer",
                      onClick: ($event: any) => (_ctx.handleReservation(slot))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('FRONTEND_DATE_PICKER_BOOK')), 1)
                    ], 8, _hoisted_18)
                  ])
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}