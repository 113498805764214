import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "object-selector",
  style: {"position":"relative"}
}
const _hoisted_2 = { class: "object-selector-header" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "candal-regular" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "candal-regular" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "candal-regular" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = {
  id: "arrangement-indicator",
  class: "cart-indicator"
}
const _hoisted_12 = { class: "sub-text" }
const _hoisted_13 = {
  key: 0,
  class: "shopping-cart-content"
}
const _hoisted_14 = { class: "shopping-cart-header" }
const _hoisted_15 = { class: "candal-regular text" }
const _hoisted_16 = { class: "shopping-cart-body" }
const _hoisted_17 = { class: "cart-category-separator" }
const _hoisted_18 = { class: "text f-bold" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "text" }
const _hoisted_21 = {
  key: 1,
  style: { width: '100%' }
}
const _hoisted_22 = { style: { width: '100%' } }
const _hoisted_23 = { style: { width: '60%' } }
const _hoisted_24 = { style: { width: '15%' } }
const _hoisted_25 = { style: { width: '25%', textAlign: 'right' } }
const _hoisted_26 = { class: "shopping-cart-footer" }
const _hoisted_27 = { class: "candal-regular text" }
const _hoisted_28 = { class: "object-list-wrapper" }
const _hoisted_29 = { class: "object-list fade-in" }
const _hoisted_30 = {
  key: 0,
  class: "ribbon ribbon-top-left"
}
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "object-image-wrapper" }
const _hoisted_33 = { class: "object-image" }
const _hoisted_34 = ["src"]
const _hoisted_35 = { class: "object-header" }
const _hoisted_36 = { style: {"color":"#f2682c","font-weight":"bold"} }
const _hoisted_37 = { class: "object-dishes" }
const _hoisted_38 = { class: "dish-header candal-regular" }
const _hoisted_39 = { style: {"display":"flex","flex-direction":"column","margin-top":"auto"} }
const _hoisted_40 = { class: "object-drinks" }
const _hoisted_41 = { class: "object-drink-size bold" }
const _hoisted_42 = { class: "object-footer" }
const _hoisted_43 = { class: "object-buttons" }
const _hoisted_44 = ["onClick"]
const _hoisted_45 = { class: "object-counter" }
const _hoisted_46 = { class: "text" }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = {
  key: 1,
  class: "overlay-wrapper"
}
const _hoisted_49 = { class: "overlay" }
const _hoisted_50 = { class: "object-footer-wrapper" }
const _hoisted_51 = { class: "object-selector-footer" }
const _hoisted_52 = { class: "candal-regular" }
const _hoisted_53 = { class: "candal-regular" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrangement_card_zoom_modal = _resolveComponent("arrangement-card-zoom-modal")!
  const _component_opacity_fade_in_component = _resolveComponent("opacity-fade-in-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_opacity_fade_in_component, null, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_arrangement_card_zoom_modal, {
          arrangement: _ctx.selectedArrangement,
          onClose: _ctx.toggleDetails
        }, null, 8, ["arrangement", "onClose"]), [
          [_vShow, _ctx.showDetails]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.selectedArrangements.length != _ctx.size && _ctx.size - _ctx.selectedArrangements.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(
                                this.size - this.selectedArrangements.length != 1
                                    ? 'ARRANGEMENT_SELECTOR_AMOUNT_OF_ARRANGEMENTS_LEFT'
                                    : 'ARRANGEMENT_SELECTOR_AMOUNT_OF_ARRANGEMENT_LEFT',
                                { amount: this.size - this.selectedArrangements.length },
                            )), 1)
            ]))
          : (_ctx.selectedArrangements.length != _ctx.size && _ctx.size - _ctx.selectedArrangements.length < 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_TO_MUCH_ARRANGEMENTS')), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('ARRANGEMENT_SELECTOR_NO_MORE_ARRANGEMENT')), 1)
              ]))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          class: "shopping-cart-wrapper",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCart && _ctx.toggleCart(...args)))
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "material-symbols-outlined pointer" }, " shopping_cart ", -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(this.selectedArrangements.length), 1)
          ]),
          _createVNode(_Transition, {
            "enter-active-class": "fade-in",
            "leave-active-class": "fade-out"
          }, {
            default: _withCtx(() => [
              (_ctx.openArrangementCart)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('SHOPPING_CART_ARRANGEMENTS')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('SHOPPING_CART_ARRANGEMENTS')), 1)
                      ]),
                      (_ctx.selectedArrangements.length === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('SHOPPING_CART_NO_ARRANGEMENTS_SELECTED')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createElementVNode("table", _hoisted_22, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentArrangementOverview(), (obj, index) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: index,
                                  class: "shopping-cart-item"
                                }, [
                                  _createElementVNode("td", _hoisted_23, _toDisplayString(obj.arrangement), 1),
                                  _createElementVNode("td", _hoisted_24, _toDisplayString(`${obj.amount}x`), 1),
                                  _createElementVNode("td", _hoisted_25, _toDisplayString(`${_ctx.formattedPrice(obj.price)}`), 1)
                                ]))
                              }), 128))
                            ])
                          ]))
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("span", _hoisted_27, _toDisplayString(`${_ctx.$t('SHOPPING_CART_TOTAL')} ${_ctx.formattedPrice(_ctx.totalCosts)}`), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrangementList, (arrangement) => {
          return (_openBlock(), _createElementBlock("div", {
            key: arrangement.id,
            class: _normalizeClass([[_ctx.checkOrderBefore(arrangement) ? 'order-before' : ''], "object-card grow shadow"])
          }, [
            (arrangement.is_popular)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('POPULAR')), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass([[_ctx.checkOrderBefore(arrangement) ? 'card-disabled' : ''], "object-wrapper"]),
              style: {"display":"flex","flex-direction":"column","height":"100%","position":"relative"}
            }, [
              _createElementVNode("div", {
                class: "object-info pointer",
                style: {"position":"absolute","right":"0"},
                onClick: ($event: any) => (_ctx.setArrangement(arrangement))
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, "info", -1)
              ]), 8, _hoisted_31),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("img", {
                    src: arrangement.media_library.original_url,
                    alt: "Image"
                  }, null, 8, _hoisted_34)
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("span", null, _toDisplayString(`${arrangement.title}`), 1),
                _createElementVNode("span", _hoisted_36, _toDisplayString(`${_ctx.formattedPrice(arrangement.price)} p.p.`), 1)
              ]),
              _createElementVNode("div", _hoisted_37, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedCategories(_ctx.groupedDishes(arrangement)), (category) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: category,
                    class: "dish-item"
                  }, [
                    _createElementVNode("span", _hoisted_38, _toDisplayString(category ? _ctx.$t(`ARRANGEMENT_SELECTOR_${category.toUpperCase()}`) : ''), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedDishes(arrangement)[category], (dish) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: dish.id,
                        class: "dish-name dish-name-size"
                      }, _toDisplayString(`${dish.name}`), 1))
                    }), 128))
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("span", _hoisted_41, _toDisplayString(arrangement.drink_amount !== 1
                                            ? _ctx.$t('ARRANGEMENT_SELECTOR_DRINKS', { amount: arrangement.drink_amount })
                                            : _ctx.$t('ARRANGEMENT_SELECTOR_DRINK')), 1)
                ]),
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("div", {
                      class: "count-button",
                      onClick: 
                                            (event) => {
                                                _ctx.removeArrangement(arrangement)
                                                _ctx.animateButton(event)
                                            }
                                        
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " remove ", -1)
                    ]), 8, _hoisted_44),
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.countArrangement(arrangement.id)), 1)
                    ]),
                    _createElementVNode("div", {
                      class: "count-button",
                      onClick: 
                                            (event) => {
                                                _ctx.addArrangement(arrangement)
                                                _ctx.animateButton(event)
                                            }
                                        
                    }, _cache[7] || (_cache[7] = [
                      _createElementVNode("span", { class: "material-symbols-outlined symbol-size pointer" }, " add ", -1)
                    ]), 8, _hoisted_47)
                  ])
                ])
              ])
            ], 2),
            (_ctx.checkOrderBefore(arrangement))
              ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                  _createElementVNode("div", _hoisted_49, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('ARRANGEMENT_SELECTOR_ORDER_BEFORE', { time: arrangement.order_before_time })), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128)),
        _createElementVNode("div", _hoisted_50, [
          _createElementVNode("div", _hoisted_51, [
            _createElementVNode("div", {
              id: "prev_arrangement_button",
              class: "nav-button-control pointer text",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevStep && _ctx.prevStep(...args)))
            }, [
              _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.$t('BUTTON_BACK')), 1)
            ]),
            _createElementVNode("div", {
              id: "next_arrangement_button",
              class: "nav-button-control pointer text",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextStep && _ctx.nextStep(...args)))
            }, [
              _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t('BUTTON_NEXT')), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}