import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "payment-view" }
const _hoisted_2 = {
  style: { height: '100%', width: '100%' },
  class: "payment-view-wrapper"
}
const _hoisted_3 = {
  key: 0,
  class: "payment-details fade-in"
}
const _hoisted_4 = { class: "form-wrapper" }
const _hoisted_5 = { class: "form-title" }
const _hoisted_6 = { class: "form-item-group" }
const _hoisted_7 = { class: "form-item" }
const _hoisted_8 = {
  class: "form-item-title",
  for: "name"
}
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 0,
  class: "form-error"
}
const _hoisted_11 = { class: "required" }
const _hoisted_12 = {
  key: 1,
  class: "form-error"
}
const _hoisted_13 = { class: "form-item" }
const _hoisted_14 = {
  class: "form-item-title",
  for: "sur_name"
}
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = {
  key: 0,
  class: "form-error"
}
const _hoisted_17 = { class: "required" }
const _hoisted_18 = {
  key: 1,
  class: "form-error"
}
const _hoisted_19 = { class: "form-item" }
const _hoisted_20 = {
  class: "form-item-title",
  for: "email"
}
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = {
  key: 0,
  class: "form-error"
}
const _hoisted_23 = { class: "required" }
const _hoisted_24 = {
  key: 1,
  class: "form-error"
}
const _hoisted_25 = { class: "form-item" }
const _hoisted_26 = {
  class: "form-item-title",
  for: "phone"
}
const _hoisted_27 = ["placeholder"]
const _hoisted_28 = {
  key: 0,
  class: "form-error"
}
const _hoisted_29 = { class: "required" }
const _hoisted_30 = {
  key: 1,
  class: "form-error"
}
const _hoisted_31 = {
  key: 0,
  class: "form-item-group"
}
const _hoisted_32 = { class: "form-item" }
const _hoisted_33 = {
  class: "form-item-title",
  for: "city"
}
const _hoisted_34 = {
  key: 0,
  class: "required"
}
const _hoisted_35 = ["placeholder"]
const _hoisted_36 = {
  key: 0,
  class: "form-error"
}
const _hoisted_37 = { class: "required" }
const _hoisted_38 = {
  key: 1,
  class: "form-error"
}
const _hoisted_39 = {
  class: "form-item",
  style: {"width":"50%"}
}
const _hoisted_40 = {
  class: "form-item-title",
  for: "zipcode"
}
const _hoisted_41 = {
  key: 0,
  class: "required"
}
const _hoisted_42 = ["placeholder"]
const _hoisted_43 = {
  key: 0,
  class: "form-error"
}
const _hoisted_44 = { class: "required" }
const _hoisted_45 = {
  key: 1,
  class: "form-error"
}
const _hoisted_46 = {
  key: 1,
  class: "form-item-group"
}
const _hoisted_47 = { class: "form-item" }
const _hoisted_48 = {
  class: "form-item-title",
  for: "street-name"
}
const _hoisted_49 = {
  key: 0,
  class: "required"
}
const _hoisted_50 = ["placeholder"]
const _hoisted_51 = {
  key: 0,
  class: "form-error"
}
const _hoisted_52 = { class: "required" }
const _hoisted_53 = {
  key: 1,
  class: "form-error"
}
const _hoisted_54 = {
  class: "form-item",
  style: {"width":"50%"}
}
const _hoisted_55 = {
  class: "form-item-title",
  for: "street-number"
}
const _hoisted_56 = {
  key: 0,
  class: "required"
}
const _hoisted_57 = ["placeholder"]
const _hoisted_58 = {
  key: 0,
  class: "form-error"
}
const _hoisted_59 = { class: "required" }
const _hoisted_60 = {
  key: 1,
  class: "form-error"
}
const _hoisted_61 = {
  key: 2,
  class: "form-item"
}
const _hoisted_62 = {
  class: "form-item-title",
  for: "apartment"
}
const _hoisted_63 = ["placeholder"]
const _hoisted_64 = {
  key: 3,
  class: "form-item"
}
const _hoisted_65 = {
  class: "form-item-title",
  for: "company-name"
}
const _hoisted_66 = ["placeholder"]
const _hoisted_67 = { class: "button-control" }
const _hoisted_68 = { class: "candal-regular" }
const _hoisted_69 = { class: "candal-regular" }
const _hoisted_70 = {
  key: 1,
  class: "payment-overview"
}
const _hoisted_71 = { class: "payment-overview-wrapper fade-in" }
const _hoisted_72 = { class: "payment-overview-body-wrapper" }
const _hoisted_73 = { class: "payment-overview-body" }
const _hoisted_74 = { class: "item-list" }
const _hoisted_75 = { class: "item-list-wrapper" }
const _hoisted_76 = { class: "item" }
const _hoisted_77 = { class: "item-wrapper" }
const _hoisted_78 = { class: "item-details" }
const _hoisted_79 = {
  class: "detail-col",
  style: {"max-width":"100%"}
}
const _hoisted_80 = { style: {"font-size":"16px","font-weight":"bold"} }
const _hoisted_81 = { style: {"height":"100%"} }
const _hoisted_82 = { style: {"font-size":"12px","color":"var(--primary-admin-text)"} }
const _hoisted_83 = { style: {"font-size":"16px"} }
const _hoisted_84 = { class: "item-wrapper" }
const _hoisted_85 = { class: "item-image-wrapper" }
const _hoisted_86 = { class: "item-image" }
const _hoisted_87 = ["alt", "src"]
const _hoisted_88 = { class: "item-details" }
const _hoisted_89 = {
  class: "detail-col",
  style: {"max-width":"100%"}
}
const _hoisted_90 = { style: {"font-size":"16px","font-weight":"bold"} }
const _hoisted_91 = { style: {"height":"100%"} }
const _hoisted_92 = { style: {"font-size":"12px","color":"var(--primary-admin-text)"} }
const _hoisted_93 = { style: {"font-size":"16px"} }
const _hoisted_94 = {
  class: "detail-col",
  style: {}
}
const _hoisted_95 = { class: "arrangement-amount" }
const _hoisted_96 = { class: "item-wrapper" }
const _hoisted_97 = { class: "item-image-wrapper" }
const _hoisted_98 = { class: "item-image" }
const _hoisted_99 = ["alt", "src"]
const _hoisted_100 = { class: "item-details" }
const _hoisted_101 = {
  class: "detail-col",
  style: {"max-width":"100%"}
}
const _hoisted_102 = { style: {"font-size":"16px","font-weight":"bold"} }
const _hoisted_103 = { style: {"font-size":"16px"} }
const _hoisted_104 = {
  class: "detail-col",
  style: {}
}
const _hoisted_105 = { class: "arrangement-amount" }
const _hoisted_106 = { class: "item-wrapper" }
const _hoisted_107 = { class: "item-image-wrapper" }
const _hoisted_108 = { class: "item-image" }
const _hoisted_109 = ["alt", "src"]
const _hoisted_110 = { class: "item-details" }
const _hoisted_111 = {
  class: "detail-col",
  style: {"max-width":"100%"}
}
const _hoisted_112 = { style: {"font-size":"16px","font-weight":"bold"} }
const _hoisted_113 = { style: {"font-size":"16px"} }
const _hoisted_114 = {
  class: "detail-col",
  style: {}
}
const _hoisted_115 = { class: "arrangement-amount" }
const _hoisted_116 = { class: "item-wrapper" }
const _hoisted_117 = { class: "item-image-wrapper" }
const _hoisted_118 = { class: "item-image" }
const _hoisted_119 = ["alt", "src"]
const _hoisted_120 = { class: "item-details" }
const _hoisted_121 = {
  class: "detail-col",
  style: {"max-width":"100%"}
}
const _hoisted_122 = { style: {"font-size":"16px","font-weight":"bold"} }
const _hoisted_123 = { style: {"font-size":"16px"} }
const _hoisted_124 = {
  class: "detail-col",
  style: {}
}
const _hoisted_125 = { class: "arrangement-amount" }
const _hoisted_126 = { class: "order-summary" }
const _hoisted_127 = {
  class: "order-summary-wrapper",
  style: {"margin-bottom":"10px"}
}
const _hoisted_128 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_129 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_130 = { style: {"font-size":"14px"} }
const _hoisted_131 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_132 = { style: {"font-size":"14px"} }
const _hoisted_133 = {
  class: "order-summary-wrapper",
  style: {"margin-bottom":"10px"}
}
const _hoisted_134 = { class: "order-summary-discount" }
const _hoisted_135 = {
  class: "discount-input-wrapper",
  style: {"width":"65%"}
}
const _hoisted_136 = ["placeholder"]
const _hoisted_137 = {
  key: 0,
  style: {"display":"flex","justify-content":"space-between","align-items":"center"}
}
const _hoisted_138 = { style: {"font-size":"12px","font-weight":"bold"} }
const _hoisted_139 = { class: "order-summary-wrapper" }
const _hoisted_140 = { class: "order-summary-header" }
const _hoisted_141 = { class: "order-summary-body" }
const _hoisted_142 = { class: "line" }
const _hoisted_143 = { style: {"color":"var(--primary-admin-text)"} }
const _hoisted_144 = { class: "line" }
const _hoisted_145 = { style: {"color":"var(--primary-admin-text)"} }
const _hoisted_146 = {
  key: 0,
  class: "total-line"
}
const _hoisted_147 = { key: 0 }
const _hoisted_148 = { class: "total-line" }
const _hoisted_149 = { key: 0 }
const _hoisted_150 = { key: 1 }
const _hoisted_151 = {
  class: "accept-policy",
  style: {"display":"flex"}
}
const _hoisted_152 = ["innerHTML"]
const _hoisted_153 = {
  class: "accept-policy",
  style: {"display":"flex"}
}
const _hoisted_154 = { style: {"margin-left":"10px","font-size":"14px"} }
const _hoisted_155 = { class: "checkout-button" }
const _hoisted_156 = { class: "button-control" }
const _hoisted_157 = { class: "candal-regular" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.paymentStep === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_TITLE')), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", null, [
                    _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_NAME_TITLE')), 1),
                    _cache[27] || (_cache[27] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    id: "name",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCustomer.name) = $event)),
                    placeholder: _ctx.$t('BOOKING_DETAILS_FORM_NAME_PLACEHOLDER'),
                    required: "",
                    type: "text",
                    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateCustomerFields('name')))
                  }, null, 40, _hoisted_9), [
                    [_vModelText, _ctx.selectedCustomer.name]
                  ]),
                  (_ctx.errors.name)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.errors.name), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_12))
                ]),
                _cache[29] || (_cache[29] = _createElementVNode("div", {
                  class: "empty-block",
                  style: {"padding":"0 10px"}
                }, null, -1)),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", null, [
                    _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_FORM_SURNAME_TITLE')), 1),
                    _cache[28] || (_cache[28] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    id: "sur_name",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCustomer.surname) = $event)),
                    placeholder: _ctx.$t('PAYMENT_DETAILS_FORM_SURNAME_PLACEHOLDER'),
                    required: "",
                    type: "text",
                    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateCustomerFields('surname')))
                  }, null, 40, _hoisted_15), [
                    [_vModelText, _ctx.selectedCustomer.surname]
                  ]),
                  (_ctx.errors.surname)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.errors.surname), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_18))
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_FORM_EMAIL_TITLE')), 1),
                  _cache[30] || (_cache[30] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
                ]),
                _withDirectives(_createElementVNode("input", {
                  id: "email",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedCustomer.email) = $event)),
                  placeholder: _ctx.$t('PAYMENT_DETAILS_FORM_EMAIL_PLACEHOLDER'),
                  required: "",
                  type: "text",
                  onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validateCustomerFields('email')))
                }, null, 40, _hoisted_21), [
                  [_vModelText, _ctx.selectedCustomer.email]
                ]),
                (_ctx.errors.email)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.errors.email), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_24))
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t('BOOKING_DETAILS_FORM_PHONE_TITLE')), 1),
                  _cache[31] || (_cache[31] = _createElementVNode("label", { class: "required" }, _toDisplayString('*'), -1))
                ]),
                _withDirectives(_createElementVNode("input", {
                  id: "phone",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedCustomer.phone_number) = $event)),
                  placeholder: _ctx.$t('BOOKING_DETAILS_FORM_PHONE_PLACEHOLDER'),
                  required: "",
                  type: "text",
                  onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateCustomerFields('phone_number')))
                }, null, 40, _hoisted_27), [
                  [_vModelText, _ctx.selectedCustomer.phone_number]
                ]),
                (_ctx.errors.phone_number)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                      _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.errors.phone_number), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_30))
              ]),
              (_ctx.details)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", null, [
                        _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_FORM_CITY_TITLE')), 1),
                        (_ctx.details)
                          ? (_openBlock(), _createElementBlock("label", _hoisted_34, _toDisplayString('*')))
                          : _createCommentVNode("", true)
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "city",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedCustomer.city) = $event)),
                        placeholder: _ctx.$t('PAYMENT_DETAILS_FORM_CITY_PLACEHOLDER'),
                        required: "",
                        type: "text",
                        onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.validateCustomerFields('city')))
                      }, null, 40, _hoisted_35), [
                        [_vModelText, _ctx.selectedCustomer.city]
                      ]),
                      (_ctx.errors.city)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                            _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.errors.city), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_38))
                    ]),
                    _cache[32] || (_cache[32] = _createElementVNode("div", {
                      class: "empty-block",
                      style: {"padding":"0 10px"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", null, [
                        _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_FORM_ZIPCODE_TITLE')), 1),
                        (_ctx.details)
                          ? (_openBlock(), _createElementBlock("label", _hoisted_41, _toDisplayString('*')))
                          : _createCommentVNode("", true)
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "zipcode",
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedCustomer.zipcode) = $event)),
                        placeholder: _ctx.$t('PAYMENT_DETAILS_FORM_ZIPCODE_PLACEHOLDER'),
                        required: "",
                        type: "text",
                        onBlur: _cache[11] || (_cache[11] = ($event: any) => (_ctx.validateCustomerFields('zipcode')))
                      }, null, 40, _hoisted_42), [
                        [_vModelText, _ctx.selectedCustomer.zipcode]
                      ]),
                      (_ctx.errors.zipcode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                            _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.errors.zipcode), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_45))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.details)
                ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("div", null, [
                        _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_FORM_STREET_NAME_TITLE')), 1),
                        (_ctx.details)
                          ? (_openBlock(), _createElementBlock("label", _hoisted_49, _toDisplayString('*')))
                          : _createCommentVNode("", true)
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "street-name",
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedCustomer.street_name) = $event)),
                        placeholder: _ctx.$t('PAYMENT_DETAILS_FORM_STREET_NAME_PLACEHOLDER'),
                        required: "",
                        type: "text",
                        onBlur: _cache[13] || (_cache[13] = ($event: any) => (_ctx.validateCustomerFields('street_name')))
                      }, null, 40, _hoisted_50), [
                        [_vModelText, _ctx.selectedCustomer.street_name]
                      ]),
                      (_ctx.errors.street_name)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                            _createElementVNode("label", _hoisted_52, _toDisplayString(_ctx.errors.street_name), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_53))
                    ]),
                    _cache[33] || (_cache[33] = _createElementVNode("div", {
                      class: "empty-block",
                      style: {"padding":"0 10px"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_54, [
                      _createElementVNode("div", null, [
                        _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_FORM_STREET_NUMBER_TITLE')), 1),
                        (_ctx.details)
                          ? (_openBlock(), _createElementBlock("label", _hoisted_56, _toDisplayString('*')))
                          : _createCommentVNode("", true)
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        id: "street-number",
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.selectedCustomer.house_number) = $event)),
                        placeholder: _ctx.$t('PAYMENT_DETAILS_FORM_STREET_NUMBER_PLACEHOLDER'),
                        required: "",
                        type: "text",
                        onBlur: _cache[15] || (_cache[15] = ($event: any) => (_ctx.validateCustomerFields('house_number')))
                      }, null, 40, _hoisted_57), [
                        [_vModelText, _ctx.selectedCustomer.house_number]
                      ]),
                      (_ctx.errors.house_number)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                            _createElementVNode("label", _hoisted_59, _toDisplayString(_ctx.errors.house_number), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_60))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.details)
                ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                    _createElementVNode("div", null, [
                      _createElementVNode("label", _hoisted_62, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_FORM_APARTMENT_TITLE')), 1)
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      id: "apartment",
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.selectedCustomer.apartment) = $event)),
                      placeholder: _ctx.$t('PAYMENT_DETAILS_FORM_APARTMENT_PLACEHOLDER'),
                      type: "text"
                    }, null, 8, _hoisted_63), [
                      [_vModelText, _ctx.selectedCustomer.apartment]
                    ]),
                    _cache[34] || (_cache[34] = _createElementVNode("div", { class: "form-error" }, null, -1))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.details)
                ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                    _createElementVNode("div", null, [
                      _createElementVNode("label", _hoisted_65, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_FORM_COMPANY_NAME_TITLE')), 1)
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      id: "company-name",
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.selectedCustomer.company) = $event)),
                      placeholder: _ctx.$t('PAYMENT_DETAILS_FORM_COMPANY_NAME_PLACEHOLDER'),
                      type: "text"
                    }, null, 8, _hoisted_66), [
                      [_vModelText, _ctx.selectedCustomer.company]
                    ]),
                    _cache[35] || (_cache[35] = _createElementVNode("div", { class: "form-error" }, null, -1))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_67, [
                _createElementVNode("div", {
                  class: "nav-button-control pointer text",
                  onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.prevStep && _ctx.prevStep(...args)))
                }, [
                  _createElementVNode("span", _hoisted_68, _toDisplayString(_ctx.$t('BUTTON_BACK')), 1)
                ]),
                _createElementVNode("div", {
                  class: "nav-button-control pointer text",
                  onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.nextStep && _ctx.nextStep(...args)))
                }, [
                  _createElementVNode("span", _hoisted_69, _toDisplayString(_ctx.$t('BUTTON_NEXT')), 1)
                ])
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_70, [
            _createElementVNode("div", _hoisted_71, [
              _cache[44] || (_cache[44] = _createElementVNode("div", { class: "payment-overview-header" }, [
                _createElementVNode("span", null, _toDisplayString('Shopping Cart'))
              ], -1)),
              _createElementVNode("div", _hoisted_72, [
                _createElementVNode("div", _hoisted_73, [
                  _createElementVNode("div", _hoisted_74, [
                    _createElementVNode("div", _hoisted_75, [
                      _createElementVNode("div", _hoisted_76, [
                        _createElementVNode("div", _hoisted_77, [
                          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "item-image-wrapper" }, [
                            _createElementVNode("div", { class: "item-image" }, [
                              _createElementVNode("img", {
                                alt: "bbq-betuwe",
                                src: "/bbq-betuwe-120x120.png"
                              })
                            ])
                          ], -1)),
                          _createElementVNode("div", _hoisted_78, [
                            _createElementVNode("div", _hoisted_79, [
                              _createElementVNode("span", _hoisted_80, _toDisplayString(_ctx.$t('ORDER_CONFIRMATION_AMOUNT_OF_GUESTS', {
                                                                amount: _ctx.guests,
                                                            })), 1),
                              _createElementVNode("div", _hoisted_81, [
                                _createElementVNode("span", _hoisted_82, _toDisplayString(_ctx.$t('ORDER_CONFIRMATION_GUESTS_DESCRIPTION', {
                                                                    guests: _ctx.guests,
                                                                    price: _ctx.formattedPrice(Number(_ctx.priceSetting.value)),
                                                                })), 1)
                              ]),
                              _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.formattedPrice(Number(_ctx.priceSetting.value))), 1)
                            ]),
                            _cache[36] || (_cache[36] = _createElementVNode("div", {
                              class: "detail-col",
                              style: {}
                            }, [
                              _createElementVNode("div", { class: "arrangement-amount" }, [
                                _createElementVNode("span", null, _toDisplayString('1'))
                              ])
                            ], -1))
                          ])
                        ])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentArrangementOverview(), (obj) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: obj.arrangement.id,
                          class: "item"
                        }, [
                          _createElementVNode("div", _hoisted_84, [
                            _createElementVNode("div", _hoisted_85, [
                              _createElementVNode("div", _hoisted_86, [
                                _createElementVNode("img", {
                                  alt: obj.arrangement.title,
                                  src: obj.arrangement.media_library.original_url
                                }, null, 8, _hoisted_87)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_88, [
                              _createElementVNode("div", _hoisted_89, [
                                _createElementVNode("span", _hoisted_90, _toDisplayString(obj.arrangement.title), 1),
                                _createElementVNode("div", _hoisted_91, [
                                  _createElementVNode("span", _hoisted_92, _toDisplayString(obj.arrangement.description), 1)
                                ]),
                                _createElementVNode("span", _hoisted_93, _toDisplayString(_ctx.formattedPrice(obj.arrangement.price * obj.amount)), 1)
                              ]),
                              _createElementVNode("div", _hoisted_94, [
                                _createElementVNode("div", _hoisted_95, [
                                  _createElementVNode("span", null, _toDisplayString(obj.amount), 1)
                                ])
                              ])
                            ])
                          ])
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentDrinkOverview(), (obj) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: obj.drink.id,
                          class: "item"
                        }, [
                          _createElementVNode("div", _hoisted_96, [
                            _createElementVNode("div", _hoisted_97, [
                              _createElementVNode("div", _hoisted_98, [
                                _createElementVNode("img", {
                                  alt: obj.drink.name,
                                  src: obj.drink.media_library.original_url
                                }, null, 8, _hoisted_99)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_100, [
                              _createElementVNode("div", _hoisted_101, [
                                _createElementVNode("span", _hoisted_102, _toDisplayString(obj.drink.name), 1),
                                _cache[38] || (_cache[38] = _createElementVNode("div", { style: {"height":"100%"} }, [
                                  _createElementVNode("span", { style: {"font-size":"12px","color":"var(--primary-admin-text)"} }, _toDisplayString('Drink included in the arrangement'))
                                ], -1)),
                                _createElementVNode("span", _hoisted_103, _toDisplayString(_ctx.formattedPrice(0)), 1)
                              ]),
                              _createElementVNode("div", _hoisted_104, [
                                _createElementVNode("div", _hoisted_105, [
                                  _createElementVNode("span", null, _toDisplayString(obj.amount), 1)
                                ])
                              ])
                            ])
                          ])
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentDishesOverview(), (obj) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: obj.dish.id,
                          class: "item"
                        }, [
                          _createElementVNode("div", _hoisted_106, [
                            _createElementVNode("div", _hoisted_107, [
                              _createElementVNode("div", _hoisted_108, [
                                _createElementVNode("img", {
                                  alt: obj.dish.name,
                                  src: obj.dish.media_library.original_url
                                }, null, 8, _hoisted_109)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_110, [
                              _createElementVNode("div", _hoisted_111, [
                                _createElementVNode("span", _hoisted_112, _toDisplayString(obj.dish.name), 1),
                                _cache[39] || (_cache[39] = _createElementVNode("div", { style: {"height":"100%"} }, [
                                  _createElementVNode("span", { style: {"font-size":"12px","color":"var(--primary-admin-text)"} }, _toDisplayString('Additional dish(es)'))
                                ], -1)),
                                _createElementVNode("span", _hoisted_113, _toDisplayString(_ctx.formattedPrice(obj.dish.price * obj.amount)), 1)
                              ]),
                              _createElementVNode("div", _hoisted_114, [
                                _createElementVNode("div", _hoisted_115, [
                                  _createElementVNode("span", null, _toDisplayString(obj.amount), 1)
                                ])
                              ])
                            ])
                          ])
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentExtraDrinkOverview(), (obj) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: obj.drink.id,
                          class: "item"
                        }, [
                          _createElementVNode("div", _hoisted_116, [
                            _createElementVNode("div", _hoisted_117, [
                              _createElementVNode("div", _hoisted_118, [
                                _createElementVNode("img", {
                                  alt: obj.drink.name,
                                  src: obj.drink.media_library.original_url
                                }, null, 8, _hoisted_119)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_120, [
                              _createElementVNode("div", _hoisted_121, [
                                _createElementVNode("span", _hoisted_122, _toDisplayString(obj.drink.name), 1),
                                _cache[40] || (_cache[40] = _createElementVNode("div", { style: {"height":"100%"} }, [
                                  _createElementVNode("span", { style: {"font-size":"12px","color":"var(--primary-admin-text)"} }, _toDisplayString('Additional drink(s)'))
                                ], -1)),
                                _createElementVNode("span", _hoisted_123, _toDisplayString(_ctx.formattedPrice(obj.drink.price * obj.amount)), 1)
                              ]),
                              _createElementVNode("div", _hoisted_124, [
                                _createElementVNode("div", _hoisted_125, [
                                  _createElementVNode("span", null, _toDisplayString(obj.amount), 1)
                                ])
                              ])
                            ])
                          ])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_126, [
                    _createElementVNode("div", _hoisted_127, [
                      _createElementVNode("div", _hoisted_128, [
                        _createElementVNode("div", _hoisted_129, [
                          _cache[41] || (_cache[41] = _createElementVNode("span", { class: "material-symbols-outlined symbol-size" }, "calendar_month", -1)),
                          _createElementVNode("span", _hoisted_130, _toDisplayString(_ctx.selectedDate), 1)
                        ]),
                        _createElementVNode("div", _hoisted_131, [
                          _cache[42] || (_cache[42] = _createElementVNode("span", { class: "material-symbols-outlined symbol-size" }, "schedule", -1)),
                          _createElementVNode("span", _hoisted_132, _toDisplayString(`${_ctx.parseTime(_ctx.selectedSlot.start_time)} - ${_ctx.parseTime(_ctx.selectedSlot.end_time)}`), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_133, [
                      _createElementVNode("div", null, [
                        _createElementVNode("label", null, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.$t('APPLY_COUPON_DESCRIPTION')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_134, [
                        _createElementVNode("div", _hoisted_135, [
                          _withDirectives(_createElementVNode("input", {
                            id: "discount",
                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.discount) = $event)),
                            placeholder: _ctx.$t('PAYMENT_DISCOUNT_CODE_PLACEHOLDER'),
                            required: "",
                            style: {"border-radius":"4px","padding":"8px 8px"},
                            type: "text"
                          }, null, 8, _hoisted_136), [
                            [_vModelText, _ctx.discount]
                          ])
                        ]),
                        _createElementVNode("button", {
                          class: "coupon-button pointer",
                          style: {"width":"30%"},
                          onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.validateDiscountCode()))
                        }, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('APPLY_COUPON_BUTTON_TEXT')), 1)
                        ])
                      ]),
                      (_ctx.selectedCoupon.id)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_137, [
                            _createElementVNode("span", _hoisted_138, _toDisplayString(_ctx.selectedCoupon.code), 1),
                            _createElementVNode("button", {
                              class: "pointer",
                              style: {"display":"flex","justify-content":"center","align-items":"center","background-color":"#f2682c","border-radius":"5px","border":"1px solid #ccc"},
                              onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.removeCurrentSelectedCoupon()))
                            }, _cache[43] || (_cache[43] = [
                              _createElementVNode("span", {
                                class: "material-symbols-outlined symbol-size",
                                style: {"color":"white"}
                              }, "delete", -1)
                            ]))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_139, [
                      _createElementVNode("div", _hoisted_140, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('PAYMENT_ORDER_SUMMARY')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_141, [
                        _createElementVNode("div", _hoisted_142, [
                          _createElementVNode("span", _hoisted_143, _toDisplayString(_ctx.$t('PAYMENT_ORDER_SUBTOTAL')), 1),
                          _createElementVNode("span", null, _toDisplayString(_ctx.calculateTotalCost - _ctx.calculateDiscount < 0
                                                        ? _ctx.formattedPrice(0)
                                                        : _ctx.formattedPrice(
                                                              _ctx.calculateTotalCost() -
                                                                  _ctx.calculateDiscount() -
                                                                  _ctx.calculateTax(),
                                                          )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_144, [
                          _createElementVNode("span", _hoisted_145, _toDisplayString(_ctx.$t('PAYMENT_TAX')), 1),
                          _createElementVNode("span", null, _toDisplayString(_ctx.calculateTotalCost - _ctx.calculateDiscount < 0
                                                        ? _ctx.formattedPrice(0)
                                                        : _ctx.formattedPrice(_ctx.calculateTax())), 1)
                        ]),
                        (_ctx.selectedCoupon.id)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_146, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('DISCOUNT')), 1),
                              (!_ctx.selectedCoupon.is_coupon)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_147, _toDisplayString(_ctx.formattedPrice(_ctx.calculateDiscount())), 1))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_148, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('PAYMENT_TOTAL')), 1),
                          (!_ctx.selectedCoupon.id)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_149, _toDisplayString(_ctx.formattedPrice(_ctx.calculateTotalCost())), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_150, _toDisplayString(_ctx.calculateTotalCost() - _ctx.calculateDiscount() < 0
                                                        ? _ctx.formattedPrice(0)
                                                        : _ctx.formattedPrice(_ctx.calculateTotalCost() - _ctx.calculateDiscount())), 1))
                        ]),
                        _createElementVNode("div", _hoisted_151, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.acceptedPolicies) = $event)),
                            type: "checkbox"
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.acceptedPolicies]
                          ]),
                          _createElementVNode("span", {
                            style: {"margin-left":"10px","font-size":"14px"},
                            innerHTML: 
                                                    _ctx.$t('TERMS_AND_CONDITIONS', {
                                                        terms: `<a href='${_ctx.termsAndConditions}' target='_blank' rel='noopener noreferrer' class='link-class'>${_ctx.$t('TERMS')}</a>`,
                                                    })
                                                
                          }, null, 8, _hoisted_152)
                        ]),
                        _createElementVNode("div", _hoisted_153, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.acceptedMinTime) = $event)),
                            type: "checkbox"
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.acceptedMinTime]
                          ]),
                          _createElementVNode("span", _hoisted_154, _toDisplayString(_ctx.$t('MIN_TIME_BEFORE_ACTIVITY')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_155, [
                          _createElementVNode("div", {
                            class: _normalizeClass([{ disabled: !_ctx.acceptedPolicies || !_ctx.acceptedMinTime }, "checkout-button-wrapper pointer"]),
                            onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.createPayment()))
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('PAYMENT_CHECKOUT')), 1)
                          ], 2)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_156, [
              _createElementVNode("div", {
                class: "nav-button-control pointer text",
                style: {"align-self":"flex-end"},
                onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.prevPaymentStep && _ctx.prevPaymentStep(...args)))
              }, [
                _createElementVNode("span", _hoisted_157, _toDisplayString(_ctx.$t('BUTTON_BACK')), 1)
              ])
            ])
          ]))
    ])
  ]))
}