<template>
    <div class="boat-booking-card">
        <div v-if="loading" class="spinner-wrapper">
            <loading-spinner />
        </div>
        <div v-if="!loading" class="boat-booking-card-wrapper">
            <div class="booking-card-header">
                <span class="booking-card-title">
                    {{ $t('FRONTEND_DATE_PICKER_TITLE', { date: convertDate(selectedCalendarDay.date) }) }}
                </span>
                <span class="booking-card-subtitle">
                    {{ $t('FRONTEND_DATE_PICKER_SUBTITLE', { category: selectedCategory }) }}
                </span>
                <span
                    v-if="selectedTimeSlot && selectedTimeSlot.start_time && selectedTimeSlot.end_time"
                    class="booking-card-subtitle"
                >
                    {{
                        $t('FRONTEND_DATE_PICKER_TIMESLOT', {
                            start: timeSlotParser(selectedTimeSlot.start_time),
                            end: timeSlotParser(selectedTimeSlot.end_time),
                        })
                    }}
                </span>
                <span v-if="currentSelectedDate" class="booking-card-subtitle">
                    {{ $t('FRONTEND_DATE_PICKER_SELECTED_DATE', { date: convertDate(currentSelectedDate) }) }}
                </span>
            </div>
            <div class="booking-card-body">
                <div v-for="slot in timeSlots" :key="slot.id" class="booking-slot">
                    <div class="slot-col">
                        <div class="align-center">
                            <span class="material-symbols-outlined symbol-size">schedule</span>
                            <div class="align-center">
                                <span class="bold time"> {{ `${timeSlotParser(slot.start_time)}` }}</span>
                                <span class="material-symbols-outlined symbol-size">horizontal_rule</span>
                                <span class="bold time"> {{ `${timeSlotParser(slot.end_time)}` }}</span>
                            </div>
                        </div>
                        <div class="flex-col">
                            <span
                                v-for="boatCategory in distinctCategories()"
                                :key="boatCategory"
                                :style="{
                                    color:
                                        getAmountAvailableBoats(parseCategory(boatCategory), slot.id) === 0
                                            ? 'red'
                                            : '',
                                }"
                                class="sub-text"
                            >
                                {{
                                    `${getAmountAvailableBoats(parseCategory(boatCategory), slot.id)} ${parseCategory(boatCategory)} ${$t('FRONTEND_DATE_PICKER_BOATS_AVAILABLE')}`
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="slot-col">
                        <div class="booking-button">
                            <button
                                :disabled="slot.disabled"
                                class="booking-button-wrapper text pointer"
                                @click="handleReservation(slot)"
                            >
                                <span> {{ $t('FRONTEND_DATE_PICKER_BOOK') }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
//* Components */
import LoadingSpinner from './LoadingSpinner.vue'
//* Packages */
import { defineComponent, PropType } from 'vue'
import dayjs from 'dayjs'
import http from '../../../utils/http-common'

//* Models */
import IGeneralTimeSlot from '../../../interfaces/IGeneralTimeSlot'
import ITimeSlot from '../../../interfaces/ITimeSlot'
import IAvailableBoats from '../../../interfaces/IAvailableBoats'
import IBoat from '../../../interfaces/IBoat'
import ICalendarDay from '@/interfaces/ICalendarDay'
import ICustomTimeSlot from '@/interfaces/ICustomTimeSlot'
import { toast } from 'vue3-toastify'

export default defineComponent({
    name: 'BookingCard',
    components: { LoadingSpinner },
    data() {
        return {
            loading: true as boolean,
            generalTimeSlot: {} as IGeneralTimeSlot,
            availableBoats: [] as IAvailableBoats[],
            selectedCalendarDay: {} as ICalendarDay,
            selectedCategory: '' as string,
            timeSlots: [] as ITimeSlot[],
            selectedTimeSlot: null as unknown as ITimeSlot,
            minTime: null as string | null,
            available: [] as boolean[],
            boats: [] as IBoat[],
            isBookable: false as boolean,
            currentSelectedDate: null as unknown as string,
        }
    },
    props: {
        currentDate: {
            type: String,
        },
        currentSlot: {
            type: Object as PropType<ITimeSlot>,
        },
        calendarDay: {
            type: Object as PropType<ICalendarDay>,
        },
        category: {
            type: String,
        },
        bookable: {
            type: Boolean,
        },
    },
    methods: {
        convertDate(date: string | undefined): string {
            if (date) {
                return dayjs(date).format('DD/MM/YYYY')
            }
            return dayjs().format('DD/MM/YYYY')
        },
        checkIfAvailable(id: number): boolean {
            let isAvailable = this.getAmountAvailableBoats(this.selectedCategory, id)
            return isAvailable !== 0
        },
        async getTimeSlots(): Promise<void> {
            this.availableBoats = []

            if (this.selectedCalendarDay.customTimeSlot) {
                let customTimeSlots = await this.checkCustomTimeSlots()
                let slots = this.dayFoundInCustomTimeSlot(dayjs(this.selectedCalendarDay.date), customTimeSlots)
                this.timeSlots = this.sortTimeSlots(slots)

                // Use a loop to handle asynchronous calls
                for (const timeslot of this.timeSlots) {
                    timeslot.disabled = !(await this.checkAvailability(timeslot))
                    await this.getAvailableBoats(timeslot.id)
                }

                this.loading = false
            } else {
                try {
                    const res = await http.get(`general_time_slots/day/${this.selectedCalendarDay.dayOfWeek}`)
                    this.timeSlots = this.sortTimeSlots(res.data.time_slots)

                    for (const timeslot of this.timeSlots) {
                        timeslot.disabled = !(await this.checkAvailability(timeslot))
                        await this.getAvailableBoats(timeslot.id)
                    }

                    this.loading = false
                } catch (error) {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                }
            }
        },

        getAmountAvailableBoats(category: string, id: number): number {
            if (!this.availableBoats) {
                return 0
            }

            const availableBoatsForTimeslot: IAvailableBoats | undefined = this.availableBoats.find(
                (availableBoat: IAvailableBoats) => availableBoat.timeslotId === id,
            )

            if (!availableBoatsForTimeslot || !availableBoatsForTimeslot.boats) {
                return 0
            }

            const filteredBoats: IBoat[] = availableBoatsForTimeslot.boats.filter(
                (boat: IBoat) => boat.category === category,
            )

            return filteredBoats.length
        },
        getBoats(): void {
            http.get(`boats`, { params: { active: true, pagination: false } }).then((res) => {
                this.boats = res.data
            })
        },
        parseCategory(option: string): string {
            const result = option.match(/(.*?),/)
            result ? (option = result[1].replace(/\s*boat\s*/, '')) : (option = '')
            return result ? result[1].replace(/\s*boat\s*/, '') : ''
        },
        distinctCategories() {
            const distinctBoats: { [key: string]: string } = {}

            this.boats.forEach((boat) => {
                if (!distinctBoats[boat.category]) {
                    distinctBoats[boat.category] = `${boat.category} boat, max ${boat.capacity} guests`
                }
            })

            return Object.values(distinctBoats)
        },
        getAvailableBoats(timeslotId: number): void {
            http.get(`available_boats/${this.selectedCalendarDay.date}/${timeslotId}`)
                .then((res) => {
                    const availability: IAvailableBoats = {
                        timeslotId: timeslotId,
                        boats: res.data,
                    }
                    this.availableBoats.push(availability)
                })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                })
        },
        timeSlotParser(time: string): string {
            if (time.endsWith(':00')) {
                return time.slice(0, -3)
            }
            return time
        },
        sortTimeSlots(timeslots: ITimeSlot[]): ITimeSlot[] {
            if (!timeslots) {
                return [] as ITimeSlot[]
            }

            return timeslots.sort((x, y) => {
                const timeA = new Date(`1970-01-01T${x.start_time}`)
                const timeB = new Date(`1970-01-01T${y.start_time}`)
                return timeA.getTime() - timeB.getTime()
            })
        },
        handleReservation(slot: ITimeSlot): void {
            if (!this.isBookable) {
                toast(this.$t('ERROR_MISSING_REQUIRED_FIELDS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000,
                })

                return
            }
            http.post('timeslots/check-availability', { slot: slot, date: this.selectedCalendarDay.date })
                .then(() => {
                    if (this.checkIfAvailable(slot.id)) {
                        this.selectedTimeSlot = slot
                        this.$emit('handleReservation', slot)
                    } else {
                        toast(this.$t('ERROR_NO_AVAILABLE_BOATS'), {
                            type: 'error',
                            position: 'top-right',
                            dangerouslyHTMLString: true,
                            autoClose: 3000,
                        })
                    }
                })
                .catch(() => {
                    toast(this.$t('ERROR_NO_AVAILABLE_BOATS'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                })
        },
        async checkAvailability(slot: ITimeSlot): Promise<boolean> {
            try {
                const response = await http.post('timeslots/check-availability', {
                    slot: slot,
                    date: this.selectedCalendarDay.date,
                })
                return response.data.available
            } catch (err) {
                return false
            }
        },
        async checkCustomTimeSlots(): Promise<ICustomTimeSlot[]> {
            const startDate = dayjs(this.selectedCalendarDay.date).startOf('month').format('YYYY-MM-DD')
            const endDate = dayjs(this.selectedCalendarDay.date).endOf('month').format('YYYY-MM-DD')

            try {
                const res = await http.get(`custom_time_slot/dates`, {
                    params: {
                        start_date: startDate,
                        end_date: endDate,
                    },
                })
                return res.data.customTimeSlots
            } catch (error) {
                throw new Error(`Error fetching custom time slots: ${error}`)
            }
        },
        dayFoundInCustomTimeSlot(dayIndex: dayjs.Dayjs, customTimeSlots: ICustomTimeSlot[]): ITimeSlot[] {
            for (const customTimeSlot of customTimeSlots) {
                const start = dayjs(customTimeSlot.start_date)
                const end = dayjs(customTimeSlot.end_date)
                if (dayIndex.date() >= start.date() && dayIndex.date() <= end.date()) {
                    return customTimeSlot.time_slots
                }
            }
            return []
        },
        getMinimalTimeBeforeBookingSlot(): void {
            http.get('settings/key/MINIMUM_TIME_BEFORE_BOOK').then((res) => {
                this.minTime = res.data.value
            })
        },
    },
    watch: {
        calendarDay: {
            handler(calendarDay: ICalendarDay) {
                if (calendarDay) {
                    this.selectedCalendarDay = calendarDay
                    this.getTimeSlots()
                }
            },
            immediate: true,
        },
        currentSlot: {
            handler(currentSlot: ITimeSlot) {
                if (currentSlot) {
                    this.selectedTimeSlot = currentSlot
                }
            },
            immediate: true,
        },
        currentDate: {
            handler(currentDate: string) {
                if (currentDate) {
                    this.currentSelectedDate = currentDate
                }
            },
            immediate: true,
        },
        category: {
            handler(category: string) {
                if (category) {
                    this.selectedTimeSlot = {} as ITimeSlot
                    this.selectedCategory = category
                }
            },
            immediate: true,
        },
        bookable: {
            handler(bookable: boolean) {
                if (bookable) {
                    this.isBookable = true
                }
            },
        },
    },
    mounted() {
        this.getMinimalTimeBeforeBookingSlot()
        this.getBoats()
    },
})
</script>

<style scoped>
.boat-booking-card {
    color: black;
    background: #dddddd;
    padding: 10px;
}

.booking-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

.booking-card-subtitle {
    font-size: 14px;
    color: var(--primary-admin-text);
}

.boat-booking-card-wrapper {
    border-radius: 5px;
    background-color: white;
    height: 100%;
    width: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    animation: grow 1s;
}

.booking-card-body {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.booking-slot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    border-top: 1px solid #dddddd;
}

.slot-col {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
}

.time {
    font-size: 14px;
    margin: 0 5px;
}

.booking-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5b9a42;
    border-radius: 40px;
    padding: 6px 14px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s ease;
    border-style: unset;
}

.booking-button-wrapper:hover {
    background-color: #f2682a;
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

button:disabled {
    background-color: rgba(63, 63, 63, 0.3);
    cursor: not-allowed;
}

button:disabled:hover {
    background-color: rgba(63, 63, 63, 0.3);
}
</style>
