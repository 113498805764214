<template>
    <Transition
        appear
        enter-from-class="opacity-enter-from"
        enter-active-class="opacity-enter-active"
        enter-to-class="opacity-enter-to"
        leave-from-class="opacity-leave-from"
        leave-active-class="opacity-leave-active"
        leave-to-class="opacity-leave-to"
    >
        <slot></slot>
    </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "OpacityFadeInComponent"
})
</script>