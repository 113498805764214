<template>
    <div class="progress-bar">
        <div v-for="(step, index) in steps" :key="index" class="progress-item">
            <div @click="(event) => { jumpTo(index); animateButton(event) }" class="item-wrapper pointer" :style="{ backgroundColor: steps[index].unlocked ? '#b22b00' : '' }">
                <span class="material-symbols-outlined symbol-size progress-item-symbol">{{step.symbol}}</span>
                <span class="text progress-item-text"> {{$t(step.name)}} </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ProgressBar",
    data() {
        return {
            currentStep: 0 as number,
            steps: [
                {
                    name: 'PROGRESS_BAR_DATE',
                    symbol: 'schedule',
                    unlocked: true
                },
                {
                    name: 'PROGRESS_BAR_ARRANGEMENTS',
                    symbol: 'menu_book',
                    unlocked: false
                },
                {
                    name: 'PROGRESS_BAR_DRINKS',
                    symbol: 'water_full',
                    unlocked: false
                },
                {
                    name: 'PROGRESS_BAR_EXTRAS',
                    symbol: 'fastfood',
                    unlocked: false
                },
                {
                    name: 'PROGRESS_BAR_PAYMENT',
                    symbol: 'credit_card',
                    unlocked: false
                }
            ],
        }
    },
    methods: {
        jumpTo(index: number): void {
            if(this.steps[index].unlocked) {
                this.$emit('setStep', index);
            }
        },
        animateButton(event: Event): void {
            const button = event.currentTarget as HTMLElement;
            button.classList.remove("grow-shrink");
            void button.offsetWidth;
            button.classList.add("grow-shrink");
        },
    },
    props: {
        stepIndex: Number
    },
    watch: {
        stepIndex: {
            handler(index) {
                if (index) {
                    this.currentStep = index;
                    this.steps[index].unlocked = true;
                }
            },
            immediate: true,
        },
    },
    mounted(): void {
        this.stepIndex ? this.currentStep = this.stepIndex : this.currentStep = 0;
    }
});
</script>

<style scoped>
.progress-bar {
    display: flex;
    border-radius: 30px;
    height: 40px;
}
.progress-item {
    width: 100%;
    padding: 0 10px;
}
.item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2682C;
    height: 100%;
    color: white;
    border-radius: 10px;
    border: 1px solid #ccc;
    transition: background-color ease 0.1s;
    user-select: none;
}

.progress-item-symbol {
    margin-right: 10px;
}

.item-wrapper:hover {
    background-color: #b22b00;
}

</style>
