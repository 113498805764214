<template>
    <div class="payment-view">
        <div :style="{ height: '100%', width: '100%' }" class="payment-view-wrapper">
            <div v-if="paymentStep === 0" class="payment-details fade-in">
                <div class="form-wrapper">
                    <div class="form-title">
                        {{ $t('BOOKING_DETAILS_FORM_TITLE') }}
                    </div>
                    <div class="form-item-group">
                        <div class="form-item">
                            <div>
                                <label class="form-item-title" for="name">
                                    {{ $t('BOOKING_DETAILS_FORM_NAME_TITLE') }}</label
                                >
                                <label class="required"> {{ '*' }} </label>
                            </div>
                            <input
                                id="name"
                                v-model="selectedCustomer.name"
                                :placeholder="$t('BOOKING_DETAILS_FORM_NAME_PLACEHOLDER')"
                                required
                                type="text"
                                @blur="validateCustomerFields('name')"
                            />
                            <div v-if="errors.name" class="form-error">
                                <label class="required"> {{ errors.name }} </label>
                            </div>
                            <div v-else class="form-error"></div>
                        </div>
                        <div class="empty-block" style="padding: 0 10px"></div>
                        <div class="form-item">
                            <div>
                                <label class="form-item-title" for="sur_name">
                                    {{ $t('PAYMENT_DETAILS_FORM_SURNAME_TITLE') }}</label
                                >
                                <label class="required"> {{ '*' }} </label>
                            </div>
                            <input
                                id="sur_name"
                                v-model="selectedCustomer.surname"
                                :placeholder="$t('PAYMENT_DETAILS_FORM_SURNAME_PLACEHOLDER')"
                                required
                                type="text"
                                @blur="validateCustomerFields('surname')"
                            />
                            <div v-if="errors.surname" class="form-error">
                                <label class="required"> {{ errors.surname }} </label>
                            </div>
                            <div v-else class="form-error"></div>
                        </div>
                    </div>

                    <div class="form-item">
                        <div>
                            <label class="form-item-title" for="email">
                                {{ $t('PAYMENT_DETAILS_FORM_EMAIL_TITLE') }}</label
                            >
                            <label class="required"> {{ '*' }} </label>
                        </div>
                        <input
                            id="email"
                            v-model="selectedCustomer.email"
                            :placeholder="$t('PAYMENT_DETAILS_FORM_EMAIL_PLACEHOLDER')"
                            required
                            type="text"
                            @blur="validateCustomerFields('email')"
                        />
                        <div v-if="errors.email" class="form-error">
                            <label class="required"> {{ errors.email }} </label>
                        </div>
                        <div v-else class="form-error"></div>
                    </div>
                    <div class="form-item">
                        <div>
                            <label class="form-item-title" for="phone">
                                {{ $t('BOOKING_DETAILS_FORM_PHONE_TITLE') }}</label
                            >
                            <label class="required"> {{ '*' }} </label>
                        </div>
                        <input
                            id="phone"
                            v-model="selectedCustomer.phone_number"
                            :placeholder="$t('BOOKING_DETAILS_FORM_PHONE_PLACEHOLDER')"
                            required
                            type="text"
                            @blur="validateCustomerFields('phone_number')"
                        />
                        <div v-if="errors.phone_number" class="form-error">
                            <label class="required"> {{ errors.phone_number }} </label>
                        </div>
                        <div v-else class="form-error"></div>
                    </div>
                    <div v-if="details" class="form-item-group">
                        <div class="form-item">
                            <div>
                                <label class="form-item-title" for="city">
                                    {{ $t('PAYMENT_DETAILS_FORM_CITY_TITLE') }}</label
                                >
                                <label v-if="details" class="required"> {{ '*' }} </label>
                            </div>
                            <input
                                id="city"
                                v-model="selectedCustomer.city"
                                :placeholder="$t('PAYMENT_DETAILS_FORM_CITY_PLACEHOLDER')"
                                required
                                type="text"
                                @blur="validateCustomerFields('city')"
                            />
                            <div v-if="errors.city" class="form-error">
                                <label class="required"> {{ errors.city }} </label>
                            </div>
                            <div v-else class="form-error"></div>
                        </div>

                        <div class="empty-block" style="padding: 0 10px"></div>

                        <div class="form-item" style="width: 50%">
                            <div>
                                <label class="form-item-title" for="zipcode">
                                    {{ $t('PAYMENT_DETAILS_FORM_ZIPCODE_TITLE') }}</label
                                >
                                <label v-if="details" class="required"> {{ '*' }} </label>
                            </div>
                            <input
                                id="zipcode"
                                v-model="selectedCustomer.zipcode"
                                :placeholder="$t('PAYMENT_DETAILS_FORM_ZIPCODE_PLACEHOLDER')"
                                required
                                type="text"
                                @blur="validateCustomerFields('zipcode')"
                            />
                            <div v-if="errors.zipcode" class="form-error">
                                <label class="required"> {{ errors.zipcode }} </label>
                            </div>
                            <div v-else class="form-error"></div>
                        </div>
                    </div>
                    <div v-if="details" class="form-item-group">
                        <div class="form-item">
                            <div>
                                <label class="form-item-title" for="street-name">
                                    {{ $t('PAYMENT_DETAILS_FORM_STREET_NAME_TITLE') }}</label
                                >
                                <label v-if="details" class="required"> {{ '*' }} </label>
                            </div>
                            <input
                                id="street-name"
                                v-model="selectedCustomer.street_name"
                                :placeholder="$t('PAYMENT_DETAILS_FORM_STREET_NAME_PLACEHOLDER')"
                                required
                                type="text"
                                @blur="validateCustomerFields('street_name')"
                            />
                            <div v-if="errors.street_name" class="form-error">
                                <label class="required"> {{ errors.street_name }} </label>
                            </div>
                            <div v-else class="form-error"></div>
                        </div>
                        <div class="empty-block" style="padding: 0 10px"></div>
                        <div class="form-item" style="width: 50%">
                            <div>
                                <label class="form-item-title" for="street-number">
                                    {{ $t('PAYMENT_DETAILS_FORM_STREET_NUMBER_TITLE') }}</label
                                >
                                <label v-if="details" class="required"> {{ '*' }} </label>
                            </div>
                            <input
                                id="street-number"
                                v-model="selectedCustomer.house_number"
                                :placeholder="$t('PAYMENT_DETAILS_FORM_STREET_NUMBER_PLACEHOLDER')"
                                required
                                type="text"
                                @blur="validateCustomerFields('house_number')"
                            />
                            <div v-if="errors.house_number" class="form-error">
                                <label class="required"> {{ errors.house_number }} </label>
                            </div>
                            <div v-else class="form-error"></div>
                        </div>
                    </div>
                    <div v-if="details" class="form-item">
                        <div>
                            <label class="form-item-title" for="apartment">
                                {{ $t('PAYMENT_DETAILS_FORM_APARTMENT_TITLE') }}</label
                            >
                        </div>
                        <input
                            id="apartment"
                            v-model="selectedCustomer.apartment"
                            :placeholder="$t('PAYMENT_DETAILS_FORM_APARTMENT_PLACEHOLDER')"
                            type="text"
                        />
                        <div class="form-error"></div>
                    </div>
                    <div v-if="details" class="form-item">
                        <div>
                            <label class="form-item-title" for="company-name">
                                {{ $t('PAYMENT_DETAILS_FORM_COMPANY_NAME_TITLE') }}</label
                            >
                        </div>
                        <input
                            id="company-name"
                            v-model="selectedCustomer.company"
                            :placeholder="$t('PAYMENT_DETAILS_FORM_COMPANY_NAME_PLACEHOLDER')"
                            type="text"
                        />
                        <div class="form-error"></div>
                    </div>
                    <div class="button-control">
                        <div class="nav-button-control pointer text" @click="prevStep">
                            <span class="candal-regular"> {{ $t('BUTTON_BACK') }}</span>
                        </div>
                        <div class="nav-button-control pointer text" @click="nextStep">
                            <span class="candal-regular"> {{ $t('BUTTON_NEXT') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="payment-overview">
                <div class="payment-overview-wrapper fade-in">
                    <div class="payment-overview-header">
                        <span> {{ 'Shopping Cart' }}</span>
                    </div>
                    <div class="payment-overview-body-wrapper">
                        <div class="payment-overview-body">
                            <div class="item-list">
                                <div class="item-list-wrapper">
                                    <div class="item">
                                        <div class="item-wrapper">
                                            <div class="item-image-wrapper">
                                                <div class="item-image">
                                                    <img alt="bbq-betuwe" src="/bbq-betuwe-120x120.png" />
                                                </div>
                                            </div>
                                            <div class="item-details">
                                                <div class="detail-col" style="max-width: 100%">
                                                    <span style="font-size: 16px; font-weight: bold">
                                                        {{
                                                            $t('ORDER_CONFIRMATION_AMOUNT_OF_GUESTS', {
                                                                amount: guests,
                                                            })
                                                        }}
                                                    </span>
                                                    <div style="height: 100%">
                                                        <span style="font-size: 12px; color: var(--primary-admin-text)">
                                                            {{
                                                                $t('ORDER_CONFIRMATION_GUESTS_DESCRIPTION', {
                                                                    guests: guests,
                                                                    price: formattedPrice(Number(priceSetting.value)),
                                                                })
                                                            }}
                                                        </span>
                                                    </div>
                                                    <span style="font-size: 16px">
                                                        {{ formattedPrice(Number(priceSetting.value)) }}
                                                    </span>
                                                </div>
                                                <div class="detail-col" style="">
                                                    <div class="arrangement-amount">
                                                        <span> {{ '1' }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-for="obj in currentArrangementOverview()"
                                        :key="obj.arrangement.id"
                                        class="item"
                                    >
                                        <div class="item-wrapper">
                                            <div class="item-image-wrapper">
                                                <div class="item-image">
                                                    <img
                                                        :alt="obj.arrangement.title"
                                                        :src="obj.arrangement.media_library.original_url"
                                                    />
                                                </div>
                                            </div>
                                            <div class="item-details">
                                                <div class="detail-col" style="max-width: 100%">
                                                    <span style="font-size: 16px; font-weight: bold">
                                                        {{ obj.arrangement.title }}
                                                    </span>
                                                    <div style="height: 100%">
                                                        <span style="font-size: 12px; color: var(--primary-admin-text)">
                                                            {{ obj.arrangement.description }}
                                                        </span>
                                                    </div>
                                                    <span style="font-size: 16px">
                                                        {{ formattedPrice(obj.arrangement.price * obj.amount) }}
                                                    </span>
                                                </div>
                                                <div class="detail-col" style="">
                                                    <div class="arrangement-amount">
                                                        <span> {{ obj.amount }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="obj in currentDrinkOverview()" :key="obj.drink.id" class="item">
                                        <div class="item-wrapper">
                                            <div class="item-image-wrapper">
                                                <div class="item-image">
                                                    <img
                                                        :alt="obj.drink.name"
                                                        :src="obj.drink.media_library.original_url"
                                                    />
                                                </div>
                                            </div>
                                            <div class="item-details">
                                                <div class="detail-col" style="max-width: 100%">
                                                    <span style="font-size: 16px; font-weight: bold">
                                                        {{ obj.drink.name }}
                                                    </span>
                                                    <div style="height: 100%">
                                                        <span style="font-size: 12px; color: var(--primary-admin-text)">
                                                            {{ 'Drink included in the arrangement' }}
                                                        </span>
                                                    </div>
                                                    <span style="font-size: 16px"> {{ formattedPrice(0) }} </span>
                                                </div>
                                                <div class="detail-col" style="">
                                                    <div class="arrangement-amount">
                                                        <span> {{ obj.amount }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="obj in currentDishesOverview()" :key="obj.dish.id" class="item">
                                        <div class="item-wrapper">
                                            <div class="item-image-wrapper">
                                                <div class="item-image">
                                                    <img
                                                        :alt="obj.dish.name"
                                                        :src="obj.dish.media_library.original_url"
                                                    />
                                                </div>
                                            </div>
                                            <div class="item-details">
                                                <div class="detail-col" style="max-width: 100%">
                                                    <span style="font-size: 16px; font-weight: bold">
                                                        {{ obj.dish.name }}
                                                    </span>
                                                    <div style="height: 100%">
                                                        <span style="font-size: 12px; color: var(--primary-admin-text)">
                                                            {{ 'Additional dish(es)' }}
                                                        </span>
                                                    </div>
                                                    <span style="font-size: 16px">
                                                        {{ formattedPrice(obj.dish.price * obj.amount) }}
                                                    </span>
                                                </div>
                                                <div class="detail-col" style="">
                                                    <div class="arrangement-amount">
                                                        <span> {{ obj.amount }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="obj in currentExtraDrinkOverview()" :key="obj.drink.id" class="item">
                                        <div class="item-wrapper">
                                            <div class="item-image-wrapper">
                                                <div class="item-image">
                                                    <img
                                                        :alt="obj.drink.name"
                                                        :src="obj.drink.media_library.original_url"
                                                    />
                                                </div>
                                            </div>
                                            <div class="item-details">
                                                <div class="detail-col" style="max-width: 100%">
                                                    <span style="font-size: 16px; font-weight: bold">
                                                        {{ obj.drink.name }}
                                                    </span>
                                                    <div style="height: 100%">
                                                        <span style="font-size: 12px; color: var(--primary-admin-text)">
                                                            {{ 'Additional drink(s)' }}
                                                        </span>
                                                    </div>
                                                    <span style="font-size: 16px">
                                                        {{ formattedPrice(obj.drink.price * obj.amount) }}
                                                    </span>
                                                </div>
                                                <div class="detail-col" style="">
                                                    <div class="arrangement-amount">
                                                        <span> {{ obj.amount }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="order-summary">
                                <div class="order-summary-wrapper" style="margin-bottom: 10px">
                                    <div style="display: flex; flex-direction: column">
                                        <div style="display: flex; align-items: center">
                                            <span class="material-symbols-outlined symbol-size">calendar_month</span>
                                            <span style="font-size: 14px"> {{ selectedDate }} </span>
                                        </div>
                                        <div style="display: flex; align-items: center">
                                            <span class="material-symbols-outlined symbol-size">schedule</span>
                                            <span style="font-size: 14px">
                                                {{
                                                    `${parseTime(selectedSlot.start_time)} - ${parseTime(selectedSlot.end_time)}`
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="order-summary-wrapper" style="margin-bottom: 10px">
                                    <div>
                                        <label
                                            ><strong> {{ $t('APPLY_COUPON_DESCRIPTION') }}</strong></label
                                        >
                                    </div>

                                    <div class="order-summary-discount">
                                        <div class="discount-input-wrapper" style="width: 65%">
                                            <input
                                                id="discount"
                                                v-model="discount"
                                                :placeholder="$t('PAYMENT_DISCOUNT_CODE_PLACEHOLDER')"
                                                required
                                                style="border-radius: 4px; padding: 8px 8px"
                                                type="text"
                                            />
                                        </div>
                                        <button
                                            class="coupon-button pointer"
                                            style="width: 30%"
                                            @click="validateDiscountCode()"
                                        >
                                            <label> {{ $t('APPLY_COUPON_BUTTON_TEXT') }} </label>
                                        </button>
                                    </div>
                                    <div
                                        v-if="selectedCoupon.id"
                                        style="display: flex; justify-content: space-between; align-items: center"
                                    >
                                        <span style="font-size: 12px; font-weight: bold">
                                            {{ selectedCoupon.code }}
                                        </span>
                                        <button
                                            class="pointer"
                                            style="
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                background-color: #f2682c;
                                                border-radius: 5px;
                                                border: 1px solid #ccc;
                                            "
                                            @click="removeCurrentSelectedCoupon()"
                                        >
                                            <span class="material-symbols-outlined symbol-size" style="color: white"
                                                >delete</span
                                            >
                                        </button>
                                    </div>
                                </div>
                                <div class="order-summary-wrapper">
                                    <div class="order-summary-header">
                                        <span> {{ $t('PAYMENT_ORDER_SUMMARY') }} </span>
                                    </div>
                                    <div class="order-summary-body">
                                        <div class="line">
                                            <span style="color: var(--primary-admin-text)">
                                                {{ $t('PAYMENT_ORDER_SUBTOTAL') }}
                                            </span>
                                            <span>
                                                {{
                                                    calculateTotalCost - calculateDiscount < 0
                                                        ? formattedPrice(0)
                                                        : formattedPrice(
                                                              calculateTotalCost() -
                                                                  calculateDiscount() -
                                                                  calculateTax(),
                                                          )
                                                }}
                                            </span>
                                        </div>
                                        <div class="line">
                                            <span style="color: var(--primary-admin-text)">
                                                {{ $t('PAYMENT_TAX') }}
                                            </span>
                                            <span>
                                                {{
                                                    calculateTotalCost - calculateDiscount < 0
                                                        ? formattedPrice(0)
                                                        : formattedPrice(calculateTax())
                                                }}
                                            </span>
                                        </div>
                                        <div v-if="selectedCoupon.id" class="total-line">
                                            <span> {{ $t('DISCOUNT') }} </span>
                                            <span v-if="!selectedCoupon.is_coupon">
                                                {{ formattedPrice(calculateDiscount()) }}
                                            </span>
                                        </div>
                                        <div class="total-line">
                                            <span> {{ $t('PAYMENT_TOTAL') }} </span>
                                            <span v-if="!selectedCoupon.id">
                                                {{ formattedPrice(calculateTotalCost()) }}
                                            </span>
                                            <span v-else>
                                                {{
                                                    calculateTotalCost() - calculateDiscount() < 0
                                                        ? formattedPrice(0)
                                                        : formattedPrice(calculateTotalCost() - calculateDiscount())
                                                }}
                                            </span>
                                        </div>
                                        <div class="accept-policy" style="display: flex">
                                            <input v-model="acceptedPolicies" type="checkbox" />
                                            <span
                                                style="margin-left: 10px; font-size: 14px"
                                                v-html="
                                                    $t('TERMS_AND_CONDITIONS', {
                                                        terms: `<a href='${termsAndConditions}' target='_blank' rel='noopener noreferrer' class='link-class'>${$t('TERMS')}</a>`,
                                                    })
                                                "
                                            >
                                            </span>
                                        </div>
                                        <div class="accept-policy" style="display: flex">
                                            <input v-model="acceptedMinTime" type="checkbox" />
                                            <span style="margin-left: 10px; font-size: 14px">
                                                {{ $t('MIN_TIME_BEFORE_ACTIVITY') }}
                                            </span>
                                        </div>
                                        <div class="checkout-button">
                                            <div
                                                :class="{ disabled: !acceptedPolicies || !acceptedMinTime }"
                                                class="checkout-button-wrapper pointer"
                                                @click="createPayment()"
                                            >
                                                <span> {{ $t('PAYMENT_CHECKOUT') }} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button-control">
                    <div class="nav-button-control pointer text" style="align-self: flex-end" @click="prevPaymentStep">
                        <span class="candal-regular"> {{ $t('BUTTON_BACK') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ITimeSlot from '@/interfaces/ITimeSlot'
import ICustomer from '@/interfaces/ICustomer'
import IArrangement from '@/interfaces/IArrangement'
import IDrink from '@/interfaces/IDrink'
import IDish from '@/interfaces/IDish'
import LoadingSpinner from '@/components/partials/custom-fields/LoadingSpinner.vue'
import dayjs from 'dayjs'
import http from '@/utils/http-common'
import ICoupon from '@/interfaces/ICoupon'
import ISetting from '@/interfaces/ISetting'
import { toast } from 'vue3-toastify'
import { AxiosError } from 'axios'

export default defineComponent({
    name: 'PaymentDetails',

    // eslint-disable-next-line vue/no-unused-components
    components: { LoadingSpinner },
    data() {
        return {
            discount: '' as string,
            selectedCoupon: {} as ICoupon,
            selectedDate: '' as string,
            selectedSlot: {} as ITimeSlot,
            selectedCustomer: {} as ICustomer,
            selectedBoatCategory: '' as string,
            selectedArrangements: [] as IArrangement[],
            selectedDrinks: [] as IDrink[],
            selectedExtraDrinks: [] as IDrink[],
            selectedExtraDishes: [] as IDish[],
            drinksLoaded: false as boolean,
            dishesLoaded: false as boolean,
            paymentStep: 0 as number,
            drinksAmount: null as null | number,
            selectedGuests: 0 as number,
            priceSetting: {} as ISetting,
            errors: {} as Record<string, string>,
            details: null as null | boolean,
            acceptedPolicies: false as boolean,
            acceptedMinTime: false as boolean,
            termsAndConditions: '' as string,
        }
    },
    methods: {
        calculateTotalArrangementCost(): number {
            let cost = 0
            this.selectedArrangements.forEach((arrangement) => {
                cost += Number(arrangement.price)
            })
            return cost
        },
        calculateTotalExtraDrinkCost(): number {
            let cost = 0
            this.selectedExtraDrinks.forEach((drink) => {
                cost += Number(drink.price)
            })
            return cost
        },
        calculateTotalExtraDishesCost(): number {
            let cost = 0
            this.selectedExtraDishes.forEach((dish) => {
                cost += Number(dish.price)
            })
            return cost
        },
        removeCurrentSelectedCoupon(): void {
            this.selectedCoupon = {} as ICoupon
            this.discount = ''
        },
        calculateTotalCost(): number {
            let cost = 0
            cost += this.calculateTotalArrangementCost()
            cost += this.calculateTotalExtraDrinkCost()
            cost += this.calculateTotalExtraDishesCost()
            cost += parseFloat(String(this.priceSetting.value))

            return cost
        },
        calculateDiscount(): number {
            if (!this.selectedCoupon.id) {
                return 0
            }

            if (this.selectedCoupon.is_coupon) {
                return this.selectedCoupon.amount
            } else {
                return this.calculateTotalCost() * (this.selectedCoupon.discount_percentage / 100)
            }
        },
        currentArrangementOverview(): { arrangement: IArrangement; amount: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedArrangements.forEach((arrangement: { id: number }) => {
                const arrangementId = arrangement.id

                if (ids[arrangementId]) {
                    ids[arrangementId]++
                } else {
                    ids[arrangementId] = 1
                }
            })

            return Object.keys(ids).map((arrangementId) => {
                const count = ids[arrangementId]
                const arrangement = this.selectedArrangements.find(
                    (arr) => arr.id === parseInt(arrangementId),
                ) as IArrangement

                return { arrangement: arrangement, amount: count }
            })
        },
        currentDrinkOverview(): { drink: IDrink; amount: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedDrinks.forEach((drink: { id: number }) => {
                const drinkId = drink.id

                if (ids[drinkId]) {
                    ids[drinkId]++
                } else {
                    ids[drinkId] = 1
                }
            })

            return Object.keys(ids).map((drinkId) => {
                const count = ids[drinkId]
                const drink = this.selectedDrinks.find((arr) => arr.id === parseInt(drinkId)) as IDrink
                return { drink: drink, amount: count }
            })
        },
        currentExtraDrinkOverview(): { drink: IDrink; amount: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedExtraDrinks.forEach((drink: { id: number }) => {
                const drinkId = drink.id

                if (ids[drinkId]) {
                    ids[drinkId]++
                } else {
                    ids[drinkId] = 1
                }
            })

            return Object.keys(ids).map((drinkId) => {
                const count = ids[drinkId]
                const drink = this.selectedExtraDrinks.find((arr) => arr.id === parseInt(drinkId)) as IDrink
                return { drink: drink, amount: count }
            })
        },
        currentDishesOverview(): { dish: IDish; amount: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedExtraDishes.forEach((dish: { id: number }) => {
                const dishId = dish.id

                if (ids[dishId]) {
                    ids[dishId]++
                } else {
                    ids[dishId] = 1
                }
            })

            return Object.keys(ids).map((dishId) => {
                const count = ids[dishId]
                const dish = this.selectedExtraDishes.find((arr) => arr.id === parseInt(dishId)) as IDish
                return { dish: dish, amount: count }
            })
        },
        timeSlotParser(time: string): string {
            if (!time) {
                return ''
            }
            if (time.endsWith(':00')) {
                return time.slice(0, -3)
            }
            return time
        },
        async createPayment() {
            if (!this.acceptedPolicies || !this.acceptedMinTime) {
                return
            }
            if (!this.validateArrangements()) {
                return
            }

            if (!this.validateDrinks()) {
                return
            }

            const reservationData = {
                boat_category: this.selectedBoatCategory,
                timeslot_id: this.timeslot?.id,
                customer_id: this.customer?.id,
                reservation_date: this.selectedDate,
                arrangements: this.selectedArrangements.map((arr) => arr.id),
                drinks: this.selectedDrinks.map((drink) => drink.id),
                extra_drinks: this.selectedExtraDrinks.map((drink) => drink.id),
                dishes: this.selectedExtraDishes.map((dish) => dish.id),
                coupon_id: this.selectedCoupon.id,
                guests: this.selectedGuests,
            }
            const totalCost = this.calculateTotalCost().toFixed(2).toString()
            const vatAmount = this.calculateTotalCost() * (9 / 109)
            const payment = {
                amount: {
                    value: totalCost,
                    currency: 'EUR',
                },
                billingAddress: {
                    streetAndNumber: `${this.customer?.street_name} ${this.customer?.house_number}`,
                    postalCode: this.customer?.zipcode,
                    city: this.customer?.city,
                    country: 'nl',
                    givenName: this.customer?.name,
                    familyName: this.customer?.surname,
                    email: this.customer?.email,
                },
                shippingAddress: {
                    streetAndNumber: `${this.customer?.street_name} ${this.customer?.house_number}`,
                    postalCode: this.customer?.zipcode,
                    city: this.customer?.city,
                    country: 'nl',
                    givenName: this.customer?.name,
                    familyName: this.customer?.surname,
                    email: this.customer?.email,
                },
                locale: 'en_US',
                lines: [
                    {
                        sku: '1000000',
                        description: '#1000000',
                        quantity: 1,
                        vatRate: '9.00',
                        unitPrice: {
                            currency: 'EUR',
                            value: totalCost,
                        },
                        totalAmount: {
                            currency: 'EUR',
                            value: totalCost,
                        },
                        vatAmount: {
                            currency: 'EUR',
                            value: vatAmount.toFixed(2).toString(),
                        },
                    },
                ],
            }
            try {
                const reservation = await http.post('reservations', reservationData)

                const metaData = { metadata: { order_id: reservation.data.id } }
                const paymentData = {
                    ...payment,
                    ...metaData,
                    description: reservation.data.description,
                    couponId: this.selectedCoupon.id,
                }

                const paymentResponse = await http.post(`payment`, paymentData)
                window.location.href = paymentResponse.data.checkoutUrl
            } catch (error: unknown) {
                console.log(error)
                if (error instanceof AxiosError && error.response) {
                    toast(this.$t(String(error.response.data.error)), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                } else {
                    console.error('An unknown error occurred:', error)
                }
            }
        },
        getDay(): string {
            return dayjs(this.selectedDate).format('dddd')
        },
        getFormattedDate(): string {
            let date = dayjs(this.selectedDate)

            const day = date.format('D')
            const month = date.format('MMMM')

            return `${day} ${month}`
        },
        calculateTax(): number {
            return (this.calculateTotalCost() - this.calculateDiscount()) * 0.09
        },
        prevPaymentStep(): void {
            this.paymentStep -= 1
        },
        prevStep(): void {
            this.$emit('prevStep')
        },
        nextStep(): void {
            this.validateAll()
            if (!this.formValid()) {
                return
            }
            if (this.selectedCustomer) {
                if (this.selectedCustomer.id) {
                    const token = localStorage.getItem('token')
                    const customer = { ...this.customer, token }
                    http.put(`customers/${this.selectedCustomer.id}`, customer)
                    this.paymentStep++
                } else {
                    http.post(`customers`, this.customer)
                        .then((res) => {
                            if (res.data.id) {
                                localStorage.setItem('token', res.data.token)
                                this.selectedCustomer = res.data
                                this.paymentStep++
                            }
                        })
                        .catch(() => {
                            toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                                type: 'error',
                                position: 'top-right',
                                dangerouslyHTMLString: true,
                                autoClose: 3000,
                            })
                        })
                }
            }
        },
        formattedPrice(price: number): string {
            let symbols = ['€', '$']

            const item = localStorage.getItem('currency_symbol')
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price.toFixed(2)}`
                } else {
                    return `${price.toFixed(2)} ${item}`
                }
            }
            return `${price}`
        },
        validateCustomerFields(field: keyof ICustomer): void {
            const validators: Partial<Record<keyof ICustomer, () => string | null>> = {
                name: this.validateName,
                surname: this.validateSurname,
                email: this.validateEmail,
                phone_number: this.validatePhone,
            }

            if (this.details) {
                Object.assign(validators, {
                    city: this.validateCity,
                    zipcode: this.validateZipcode,
                    street_name: this.validateStreetName,
                    house_number: this.validateHouseNumber,
                })
            }

            if (validators[field]) {
                const errorMessage = validators[field]?.()

                if (errorMessage) {
                    this.errors[field] = errorMessage
                } else {
                    delete this.errors[field]
                }
            }
        },
        formValid(): boolean {
            return !Object.keys(this.errors).length
        },
        validateAll(): void {
            this.validateCustomerFields('name')
            this.validateCustomerFields('surname')
            this.validateCustomerFields('email')
            this.validateCustomerFields('phone_number')
            this.validateCustomerFields('city')
            this.validateCustomerFields('zipcode')
            this.validateCustomerFields('street_name')
            this.validateCustomerFields('house_number')
        },
        validateName(): string | null {
            if (!this.selectedCustomer.name) {
                return this.$t('VALIDATION_NAME_REQUIRED')
            }
            return null
        },
        validateSurname(): string | null {
            if (!this.selectedCustomer.surname) {
                return this.$t('VALIDATION_SURNAME_REQUIRED')
            }
            return null
        },
        validateEmail(): string | null {
            if (!this.selectedCustomer.email) {
                return this.$t('VALIDATION_EMAIL_REQUIRED')
            }
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!emailPattern.test(this.selectedCustomer.email)) {
                return this.$t('VALIDATION_EMAIL_INVALID')
            }
            return null
        },
        validateCity(): string | null {
            if (!this.selectedCustomer.city) {
                return this.$t('VALIDATION_CITY_REQUIRED')
            }
            return null
        },
        validateZipcode(): string | null {
            if (!this.selectedCustomer.zipcode) {
                return this.$t('VALIDATION_ZIPCODE_REQUIRED')
            }
            return null
        },
        validateStreetName(): string | null {
            if (!this.selectedCustomer.street_name) {
                return this.$t('VALIDATION_STREET_NAME_REQUIRED')
            }
            return null
        },
        validateHouseNumber(): string | null {
            if (!this.selectedCustomer.house_number) {
                return this.$t('VALIDATION_HOUSE_NUMBER_REQUIRED')
            }
            return null
        },
        validatePhone(): string | null {
            if (!this.selectedCustomer.phone_number) {
                return this.$t('VALIDATION_PHONE_REQUIRED')
            }
            const phonePattern = /^[+]?[0-9\s-]{7,15}$/
            if (!phonePattern.test(this.selectedCustomer.phone_number)) {
                return this.$t('VALIDATION_PHONE_INVALID')
            }
            return null
        },
        validateArrangements(): boolean {
            if (this.selectedGuests && this.selectedArrangements.length !== this.selectedGuests) {
                if (this.selectedGuests - this.selectedArrangements.length < 0) {
                    toast(this.$t('PAYMENT_DETAILS_TO_MUCH_ARRANGEMENTS'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                } else {
                    toast(
                        this.$t(
                            this.selectedGuests - this.selectedArrangements.length != 1
                                ? 'PAYMENT_DETAILS_MISSING_ARRANGEMENTS'
                                : 'PAYMENT_DETAILS_MISSING_ARRANGEMENT',
                            { amount: this.selectedGuests - this.selectedArrangements.length },
                        ),
                        {
                            type: 'error',
                            position: 'top-right',
                            dangerouslyHTMLString: true,
                            autoClose: 3000,
                        },
                    )
                }

                return false
            }

            return true
        },
        validateDrinks(): boolean {
            if (!this.drinksAmount) {
                return false
            }
            if (this.drinksAmount && this.selectedDrinks.length !== this.drinksAmount) {
                if (this.drinksAmount - this.selectedDrinks.length < 0) {
                    toast(this.$t('PAYMENT_DETAILS_TO_MUCH_DRINKS'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                } else {
                    toast(
                        this.$t(
                            this.drinksAmount - this.selectedDrinks.length != 1
                                ? 'PAYMENT_DETAILS_MISSING_DRINKS'
                                : 'PAYMENT_DETAILS_MISSING_DRINK',
                            { amount: this.drinksAmount - this.selectedDrinks.length },
                        ),
                        {
                            type: 'error',
                            position: 'top-right',
                            dangerouslyHTMLString: true,
                            autoClose: 3000,
                        },
                    )
                }
                return false
            }

            return true
        },
        getTermsAndConditionsSetting(): void {
            http.get('settings/key/APP_TERMS_AND_AGREEMENT_LINK').then((res) => {
                this.termsAndConditions = res.data.value
            })
        },
        getPriceSetting(index: number): void {
            http.get('settings/key/PERSON_PRICE_SETTINGS_' + index).then((res) => {
                this.priceSetting = res.data
            })
        },
        calculateAmountOfDrinks(): void {
            const amount: number = this.selectedArrangements.reduce((sum: number, arrangement: IArrangement) => {
                return sum + (arrangement.drink_amount || 0)
            }, 0)

            if (this.selectedArrangements.length === this.guests) {
                this.drinksAmount = amount
            }
        },
        parseTime(time: string): string {
            if (!time) {
                return ''
            }
            if (time.endsWith(':00')) {
                return time.slice(0, -3)
            }
            return time
        },
        validateDiscountCode() {
            if (this.discount.length === 0) {
                return
            }

            http.get(`coupons/validate/${this.discount}`)
                .then((res) => {
                    if (res.data.valid) {
                        this.selectedCoupon = res.data.coupon
                        this.errors['discount_code'] = ''
                    } else {
                        this.selectedCoupon = {} as ICoupon
                        toast(this.$t('ERROR_DISCOUNT_CODE_INVALID'), {
                            type: 'error',
                            position: 'top-right',
                            dangerouslyHTMLString: true,
                            autoClose: 3000,
                        })
                    }
                })
                .catch(() => {
                    toast(this.$t('ERROR_DISCOUNT_CODE_INVALID'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000,
                    })
                })
        },
    },
    props: {
        date: {
            type: String,
        },
        timeslot: {
            type: Object as PropType<ITimeSlot>,
        },
        customer: {
            type: Object as PropType<ICustomer>,
        },
        boatCategory: {
            type: String,
        },
        requireDetails: String,
        arrangements: {
            type: Object as PropType<IArrangement[]>,
        },
        guests: {
            type: Number,
        },
        drinks: {
            type: Object as PropType<IDrink[]>,
        },
        extraDishes: {
            type: Object as PropType<IDish[]>,
        },
        extraDrinks: {
            type: Object as PropType<IDrink[]>,
        },
    },
    watch: {
        date: {
            handler(date: string) {
                if (date) {
                    this.selectedDate = date
                }
            },
        },
        timeslot: {
            handler(timeslot: ITimeSlot) {
                if (timeslot) {
                    this.selectedSlot = timeslot
                }
            },
        },
        customer: {
            handler(customer: ICustomer) {
                if (customer) {
                    this.selectedCustomer = customer
                }
            },
        },
        guests: {
            handler(guests: number) {
                if (guests) {
                    this.selectedGuests = guests
                    this.getPriceSetting(guests)
                }
            },
        },
        boatCategory: {
            handler(category: string) {
                if (category) {
                    this.selectedBoatCategory = category
                }
            },
        },
        arrangements: {
            handler(arrangements: IArrangement[]) {
                if (arrangements) {
                    this.selectedArrangements = arrangements
                    this.calculateAmountOfDrinks()
                }
            },
            deep: true,
        },
        drinks: {
            handler(drinks: IDrink[]) {
                if (drinks) {
                    this.selectedDrinks = drinks
                }
            },
            deep: true,
        },
        extraDishes: {
            handler(dishes: IDish[]) {
                if (dishes) {
                    this.selectedExtraDishes = dishes
                    this.dishesLoaded = true
                }
            },
            deep: true,
        },
        extraDrinks: {
            handler(drinks: IDrink[]) {
                if (drinks) {
                    this.selectedExtraDrinks = drinks
                    this.drinksLoaded = true
                }
            },
            deep: true,
        },
        requireDetails: {
            handler(require: string) {
                if (require) {
                    this.details = require === '1'
                }
            },
            immediate: true,
        },
    },
    mounted(): void {
        this.getTermsAndConditionsSetting()
    },
})
</script>

<style scoped>
.payment-view {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.payment-details {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.payment-overview {
    display: flex;
    flex-direction: column;
    width: 1000px;
}

.payment-overview-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
}

.payment-overview-header {
    display: flex;
    font-size: 30px;
    font-weight: bold;
    color: var(--primary-admin-text);
    padding: 20px 0;
}

.payment-overview-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
}

.item-list {
    width: 60%;
}

.order-summary {
    width: 40%;
}

.item-list-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    width: 100%;
}

.order-summary-wrapper {
    background-color: #f6f6f6;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
}

.order-summary-header {
    padding: 20px 0;
    font-size: 16px;
}

.line {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
}

.total-line {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    font-size: 14px;
}

.order-summary-discount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.checkout-button {
    width: 100%;
    padding: 20px 0;
}

.checkout-button-wrapper {
    display: flex;
    justify-content: center;
    background-color: #5b9a42;
    border: 1px solid #457331;
    padding: 10px 0;
    border-radius: 5px;
    color: white;
    transition: background-color ease-in 0.2s;
}

.checkout-button-wrapper:hover,
.coupon-button:hover {
    background-color: #467733;
    border: 1px solid #345624;
}

.item {
    border-top: 1px solid #ccc;
    overflow: hidden;
    padding: 10px 0;
}

.item-wrapper {
    display: flex;
    flex-direction: row;
}

.item-image {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    max-height: 80px;
    aspect-ratio: 1 / 1;
    padding: 6px;
    background-color: #f6f6f6;
    border-radius: 50%;
}

.item-image-wrapper {
    display: flex;
    align-items: center;
    min-height: 100px;
}

.item-image img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.item-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
    padding-left: 10px;
    border-left: 5px solid #5b9a42;
}

.detail-col {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.arrangement-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    aspect-ratio: 1 / 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
}

input {
    border-radius: 25px;
    border: 1px solid #e4e4e4;
}

.form-item-group {
    display: flex;
    width: 100%;
}

.form-item-title {
    color: #9f9f9f;
}

.form-item {
    width: 100%;
}

.form-title {
    margin: 10px 0;
    font-size: 20px;
}

.payment-view-wrapper {
    display: flex;
    justify-content: center;
}

.button-control {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: auto;
}

.nav-button-control {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5b9a42;
    border: 1px solid #457331;
    border-radius: 8px;
    color: white;
    padding: 8px;
    width: 25%;
    transition: background-color ease-in 0.2s;
}

tr:hover {
    background-color: unset;
}

.disabled {
    color: #cccdd1 !important;
    cursor: not-allowed !important;
    background-color: #9f9f9f !important;
    border: 1px solid black;
}

.coupon-button {
    display: flex;
    height: 100%;
    justify-content: center;
    background-color: #5b9a42;
    border: 1px solid #457331;
    border-radius: 5px;
    color: white;
    padding: 8px;
    transition: background-color ease-in 0.2s;
}
</style>
