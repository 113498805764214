<template>
    <div class="arrangement-card-modal-wrapper">
        <div class="arrangement-card-modal shadow" style="margin-top: 10px">
            <div v-if="loading" class="spinner-wrapper" style="display: flex; justify-content: center; width: 100%">
                <loading-spinner />
            </div>
            <div v-if="selectedArrangement.is_popular" class="ribbon ribbon-top-left" style="z-index: 10">
                <span> {{ $t('POPULAR') }} </span>
            </div>
            <div
                v-if="!loading"
                :class="[checkOrderBefore(selectedArrangement) ? 'order-before' : '']"
                class="object-card"
            >
                <div
                    class="pointer"
                    style="position: absolute; right: -6px; top: -16px; color: var(--primary-admin-text)"
                    @click="$emit('close')"
                >
                    <span class="material-symbols-outlined">close</span>
                </div>

                <div
                    :class="[checkOrderBefore(selectedArrangement) ? 'card-disabled' : '']"
                    class="object-wrapper"
                    style="display: flex; flex-direction: column; height: 100%; position: relative"
                >
                    <div class="object-image-wrapper">
                        <div class="object-image">
                            <img :src="selectedArrangement.media_library.original_url" alt="Image" />
                        </div>
                    </div>

                    <div class="object-header">
                        <span> {{ `${selectedArrangement.title}` }}</span>
                        <span style="color: #f2682c; font-weight: bold">
                            {{ `${formattedPrice(selectedArrangement.price)} p.p.` }}
                        </span>
                    </div>
                    <div class="object-dishes" style="height: 100%; overflow-y: auto">
                        <div
                            v-for="category in orderedCategories(groupedDishes(selectedArrangement))"
                            :key="category"
                            class="dish-item"
                        >
                            <span class="dish-header candal-regular">
                                {{ category ? $t(`ARRANGEMENT_SELECTOR_${category.toUpperCase()}`) : '' }}
                            </span>
                            <div
                                v-for="dish in groupedDishes(selectedArrangement)[category]"
                                :key="dish.id"
                                class="dish-name dish-name-size"
                            >
                                <span>
                                    {{ `${dish.name}` }}
                                </span>
                                <span>
                                    {{ ` (${dish.quantity} ${dish.unit})` }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: column">
                        <div class="object-drinks">
                            <span class="object-drink-size bold"
                                >{{
                                    selectedArrangement.drink_amount !== 1
                                        ? $t('ARRANGEMENT_SELECTOR_DRINKS', {
                                              amount: selectedArrangement.drink_amount,
                                          })
                                        : $t('ARRANGEMENT_SELECTOR_DRINK')
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import IArrangement from '@/interfaces/IArrangement'
import IDish from '@/interfaces/IDish'
import dayjs, { Dayjs } from 'dayjs'
import LoadingSpinner from '@/components/partials/custom-fields/LoadingSpinner.vue'

export default defineComponent({
    name: 'ArrangementCardZoomModal',
    components: { LoadingSpinner },
    data() {
        return {
            size: null as unknown as number,
            arrangementList: [] as IArrangement[],
            selectedArrangements: [] as IArrangement[],
            openArrangementCart: false as boolean,
            totalCosts: 0 as number,
            isDirty: false as boolean,
            selectedDate: null as string | null,
            showDetails: false as boolean,
            selectedArrangement: {} as IArrangement,
            loading: true as boolean,
        }
    },
    methods: {
        countArrangement(id: number): number {
            return this.selectedArrangements.filter((arrangement) => arrangement.id === id).length
        },
        animateIndicator(): void {
            const indicator = document.querySelector('#arrangement-indicator') as HTMLElement

            if (indicator) {
                indicator.classList.remove('grow-shrink')
                void indicator.offsetWidth
                indicator.classList.add('grow-shrink')
            }
        },
        currentArrangementOverview(): { arrangement: string; amount: number; price: number }[] {
            const ids: { [key: string]: number } = {}

            this.selectedArrangements.forEach((arrangement: { id: number }) => {
                const arrangementId = arrangement.id

                if (ids[arrangementId]) {
                    ids[arrangementId]++
                } else {
                    ids[arrangementId] = 1
                }
            })

            return Object.keys(ids).map((arrangementId) => {
                const count = ids[arrangementId]
                const arrangement = this.selectedArrangements.find((arr) => arr.id === parseInt(arrangementId))
                const arrangementName = arrangement?.title || 'Unknown'
                const arrangementPrice = arrangement?.price || 0
                return { arrangement: arrangementName, amount: count, price: arrangementPrice }
            })
        },
        orderedCategories(groupedDishes: Record<string, IDish[]>): string[] {
            const predefinedOrder = ['starters', 'salads', 'meat', 'sauces']
            const groupedCategories = Object.keys(groupedDishes)

            return groupedCategories.sort((a, b) => {
                const indexA = predefinedOrder.indexOf(a)
                const indexB = predefinedOrder.indexOf(b)

                return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
            })
        },
        checkOrderBefore(arrangement: IArrangement): boolean {
            if (!this.selectedDate || !arrangement.order_before_time) {
                return false
            }

            const currentDate: Dayjs = dayjs() // Get the current date and time
            const currentDateString: string = currentDate.format('YYYY-MM-DD')
            const reservationDate: Dayjs = dayjs(this.selectedDate) // Convert selected date to dayjs object
            const reservationDateString: string = reservationDate.format('YYYY-MM-DD')

            if (reservationDateString === currentDateString) {
                const orderBeforeTime: Dayjs = this.parseTime(arrangement.order_before_time)
                return currentDate.isAfter(orderBeforeTime)
            }

            return false
        },
        animateButton(event: Event): void {
            const button = event.currentTarget as HTMLElement
            button.classList.remove('grow-shrink')
            void button.offsetWidth
            button.classList.add('grow-shrink')
        },
        parseTime(time: string): Dayjs {
            const [hours, minutes] = time.split(':').map(Number)
            return dayjs().set('hour', hours).set('minute', minutes).set('second', 0).set('millisecond', 0)
        },
        formattedPrice(price: number): string {
            let symbols = ['€', '$']

            const item = localStorage.getItem('currency_symbol')
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price}`
                } else {
                    return `${price} ${item}`
                }
            }
            return `${price}`
        },
    },
    computed: {
        groupedDishes(): (arrangement: IArrangement) => Record<string, IDish[]> {
            return (arrangement) => {
                const grouped: Record<string, IDish[]> = {}
                arrangement.dishes.forEach((dish) => {
                    if (!grouped[dish.category]) {
                        grouped[dish.category] = []
                    }
                    grouped[dish.category].push(dish)
                })
                return grouped
            }
        },
    },
    props: {
        arrangement: {
            type: Object as PropType<IArrangement>,
        },
    },
    watch: {
        arrangement: {
            handler(arrangement: IArrangement) {
                if (arrangement) {
                    this.selectedArrangement = arrangement
                    this.loading = false
                }
            },
        },
    },
})
</script>

<style scoped>
.arrangement-card-modal {
    position: absolute;
    background-color: white;
    height: 70%;
    width: 25%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding: 20px 10px;
    border: 1px solid lightgray;
    overflow: visible;
}

.arrangement-card-modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    backdrop-filter: blur(8px);
    background: #0000005e;
}

.object-card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 5px;
    background-color: white;
    height: 100%;
}

.dish-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.dish-header {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    font-size: 14px;
}

.dish-name {
    color: var(--primary-admin-text);
}

.dish-name-size {
    font-size: 12px;
}

.object-drink-size {
    font-size: 14px;
}

.col {
    display: flex;
    height: 100%;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .arrangement-card-modal {
        width: 80% !important;
        height: 90% !important;
    }
}
</style>
