<template>
    <div class="custom-dropdown modal-form-item">
        <div
            class="dropdown"
            @click="toggleDropdown"
            :class="showOptions ? 'active' : ''"
            :style="{ backgroundColor: backgroundColor, zIndex: zIndex, borderColor: showOptions ? 'var(--primary-admin-highlight)' : '', borderWidth: showOptions ? '2px' : '',}"
        >
            <div class="selected-option f-14">
                {{ !selectedOption ? $t('BOAT_DROPDOWN_SELECT_BOAT') : $t('BOAT_DROPDOWN_SELECTED', {category: selectedOption}) }}
            </div>
            <div class="center p-tb-12 admin-title">
        <span
            class="material-symbols-outlined f-26"
            :class="{ rotate: showOptions, 'reverse-rotate': !showOptions }"
        >
          expand_more</span
        >
            </div>
            <div class="options" v-if="showOptions">
                <div
                    class="option"
                    v-for="(option, index) in options"
                    :key="index"
                    @click="selectOption(option)"
                >
                    {{ `${option}` }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
//* Components */
import { defineComponent, PropType } from "vue";
//* Packages */
import IBoat from "../../../interfaces/IBoat";
//* Models */

export default defineComponent({
    name: "BoatDropdown",
    components: {},
    data() {
        return {
            selectedOption: "" as string,
            showOptions: false as boolean,
            options: [] as Array<string>,
        };
    },
    props: {
        boats: {
            type: Array as PropType<IBoat[]>,
        },
        onlyDropDown: Boolean,
        zIndex: Number,
        backgroundColor: {
            type: String,
            default: "",
        },
    },
    methods: {
        toggleDropdown() {
            this.showOptions = !this.showOptions;
        },
        selectOption(option: string) {
            let parsedOption    = this.parseCategory(option);
            this.selectedOption = parsedOption;
            this.$emit("selected", parsedOption);
        },
        handleClickOutside(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.showOptions = false;
            }
        },
        parseCategory(option: string): string {
            const result = option.match(/(.*?),/);
            result
                ? (option = result[1].replace(/\s*boat\s*/, ""))
                : (option = "");
            return result ? result[1].replace(/\s*boat\s*/, "") : "";
        },
        distinctCategories(boats: IBoat[]) {
            const distinctBoats: { [key: string]: string } = {};

            boats.forEach((boat) => {
                if (!distinctBoats[boat.category]) {
                    distinctBoats[boat.category] = `${boat.category} boat, max ${boat.capacity} guests`;
                }
            });

            return Object.values(distinctBoats);
        },
    },
    watch: {
        boats: {
            handler(boats) {
                this.options = this.distinctCategories(boats);
            },
            immediate: true,
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
});
</script>

<style scoped>
.modal-form-item {
    min-width: unset;
    margin: unset;
}

.dropdown {
    background-color: white;
    border-radius: 25px;
    border: 1px solid #e4e4e4;
}

.dropdown:hover {
    border: 1px solid var(--primary-admin-highlight);
}

.options {
    border-radius: 20px;
    border: 2px solid var(--primary-admin-highlight);
}

.options .option:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.options .option:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
</style>
